import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { feeSendCoin } from "./actions";
const feeSendCoinSlier = createSlice({
  name: "feeSendCoin",
  initialState: {
    feeSendCoin: null as FeeSendCoinModel[] | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [feeSendCoin.pending.type]: state => {
      state.isLoading = true;
    },
    [feeSendCoin.fulfilled.type]: (state, action: PayloadAction<FeeSendCoinModel[]>) => {
      state.isLoading = false;
      state.feeSendCoin = action.payload;
    },
    [feeSendCoin.rejected.type]: state => {
      state.isLoading = false;
      state.feeSendCoin = null;
    }
  }
});
export default feeSendCoinSlier;