import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface PNLCalendarArgs {
  period: string;
  type?: "us_stock" | "crypto";
}
export const fetchPNlCalendarAction = createAsyncThunk<PortfolioPNLCalendarAPI[], PNLCalendarArgs>("portfolio/fetchPNlCalendarAction", async ({
  period,
  type
}) => {
  const response = await baseFetchApiV3({
    url: "/wallet/portfolio/pnl/calendar",
    method: "GET",
    params: {
      period,
      type
    }
  });
  return response.data ?? [];
});