import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchBalancesStockAction } from "./actions";
const balancesStockSlicer = createSlice({
  name: "balancesStock",
  initialState: {
    isLoading: false,
    balancesStock: null as BalancesStockModel[] | null
  },
  reducers: {},
  extraReducers: {
    [fetchBalancesStockAction.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchBalancesStockAction.fulfilled.type]: (state, action: PayloadAction<BalancesStockAPI[]>) => {
      state.balancesStock = action.payload;
      state.isLoading = false;
    }
  }
});
export default balancesStockSlicer;