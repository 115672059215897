import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchEquitySummaryAction } from "./actions";
const portfolioEquitySummarySlice = createSlice({
  name: "equitySummary",
  initialState: {
    equitySummary: null as PortfolioEquitySummaryModel | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchEquitySummaryAction.pending.type]: state => {
      state.equitySummary = null;
      state.isLoading = true;
    },
    [fetchEquitySummaryAction.fulfilled.type]: (state, action: PayloadAction<PortfolioEquitySummaryAPI>) => {
      state.equitySummary = {
        crypto: {
          total: action.payload.crypto.amount + action.payload.crypto.pocket_amount,
          amount: action.payload.crypto.amount,
          pocketAmount: action.payload.crypto.pocket_amount
        },
        usStock: {
          total: action.payload.us_stock.amount + action.payload.us_stock.pocket_amount,
          amount: action.payload.us_stock.amount,
          pocketAmount: action.payload.us_stock.pocket_amount
        },
        cash: {
          total: action.payload.cash.idr_qty + action.payload.cash.usd_qty_idr,
          idrQty: action.payload.cash.idr_qty,
          usdQty: action.payload.cash.usd_qty,
          usdQtyIdr: action.payload.cash.usd_qty_idr,
          usdMarginIdr: action.payload.cash.usd_margin_idr
        }
      };
      state.isLoading = false;
    }
  }
});
export default portfolioEquitySummarySlice;