import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPortfolioPNLHistoryAction } from "./actions";
const portfolioPNLHistorySlice = createSlice({
  name: "pnlHistory",
  initialState: {
    pnlHistory: null as PortfolioPNLHistoryModel[] | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchPortfolioPNLHistoryAction.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchPortfolioPNLHistoryAction.fulfilled.type]: (state, action: PayloadAction<PortfolioPNLHistoryAPI[]>) => {
      state.pnlHistory = action.payload.map(item => ({
        timestamp: item.timestamp,
        pocketId: item.pocket_id,
        code: item.code,
        logo: item.logo,
        qty: item.qty,
        price: item.price,
        avgPrice: item.avg_price,
        realizedIdr: item.realized_idr,
        realizedUsd: item.realized_usd,
        realizedForex: item.realized_forex,
        type: item.type
      }));
      state.isLoading = false;
    }
  }
});
export default portfolioPNLHistorySlice;