import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { validationAddress } from "./actions";
const validationAddressSlicer = createSlice({
  name: "validationAddress",
  initialState: {
    validationAddress: null as ValidationAddressModel | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [validationAddress.pending.type]: state => {
      state.isLoading = true;
    },
    [validationAddress.fulfilled.type]: (state, action: PayloadAction<ValidationAddressApi>) => {
      state.validationAddress = {
        valid: Boolean(action.payload.valid),
        address: action.payload.address
      };
      state.isLoading = false;
    }
  }
});
export default validationAddressSlicer;