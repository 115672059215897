import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchChart } from "./actions";
const chartSlicer = createSlice({
  name: "chart",
  initialState: {
    chart: null as ChartModel[] | null,
    coinId: null as number | null,
    period: null as number | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchChart.pending.type]: (state, action) => {
      const matchCoinId = state.coinId === action.meta.arg.coinId;
      const matchPeriod = state.period === action.meta.arg.period;
      if (!matchPeriod || !matchCoinId) {
        state.isLoading = true;
        state.chart = null;
      }
      state.coinId = action.meta.arg.coinId;
      state.period = action.meta.arg.period;
    },
    [fetchChart.fulfilled.type]: (state, action: PayloadAction<ChartAPI, string, any>) => {
      const matchCoinId = state.coinId === action.meta.arg.coinId;
      const matchPeriod = state.period === action.meta.arg.period;
      if (!matchPeriod || !matchCoinId) {
        return state;
      }

      // @ts-ignore
      state.chart = action.payload.map(item => ({
        timestamp: (item?.[0] ?? 0) * 1000,
        open: item[1],
        close: item[2],
        low: item[3],
        high: item[4],
        volume: item[5]
      }));
      state.isLoading = false;
    }
  }
});
export default chartSlicer;