import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { generateRandomColor } from "@utils/colors";
import { fetchCoinPieChart } from "./actions";
const portfolioCoinPieChartSlice = createSlice({
  name: "coinPieChart",
  initialState: {
    coinPieChart: null as PortfolioCoinPieChartModel | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchCoinPieChart.pending.type]: state => {
      state.coinPieChart = null;
      state.isLoading = true;
    },
    [fetchCoinPieChart.fulfilled.type]: (state, action: PayloadAction<PortfolioCoinPieChartAPI>) => {
      state.coinPieChart = {
        data: action.payload.data.map(pie => ({
          coinCode: pie.coin_code,
          coinName: pie.coin_name,
          coinColor: pie.coin_color ? pie.coin_color : generateRandomColor(),
          amount: pie.amount,
          avgPrice: pie.avg_price,
          plAmount: pie.pl_amount,
          plPercentage: pie.pl_perc,
          allocation: pie.alloc,
          date: pie.date
        })),
        meta: {
          currentPage: action.payload.meta?.current_page,
          totalPage: action.payload.meta?.total_page,
          total: action.payload.meta?.total,
          size: action.payload.meta?.size,
          from: action.payload.meta?.from,
          to: action.payload.meta?.to
        }
      };
      state.isLoading = false;
    }
  }
});
export default portfolioCoinPieChartSlice;