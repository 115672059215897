import Switch, { Size } from "./Switch";
interface LanguageSwitchProps {
  active: boolean;
  onChange: (active: boolean) => void;
  size?: Size;
}
const LanguageSwitch: React.FC<LanguageSwitchProps> = ({
  active,
  onChange,
  size
}) => <Switch active={active} onChange={onChange} size={size} inactiveClassName='!bg-actions-primary-active dark:hover:!bg-dark-actions-primary-hover !text-white' activeChildren='EN' inActiveChildren='ID' dataTestId='changeLanguageToggle' />;
export default LanguageSwitch;