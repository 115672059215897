import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface FetchMarketStockResponse {
  stocks: MarketStocksAPI[];
}
const handleFetchStocks = async () => {
  const response = await baseFetchApiV3({
    url: "/us-stock/market",
    method: "GET",
    params: {
      new_schema: 1
    }
  });
  return {
    stocks: response
  };
};
export const fetchStocks = createAsyncThunk<FetchMarketStockResponse>("marketStocks/fetchStocks", handleFetchStocks);
export const fetchStocksAsync = createAsyncThunk<FetchMarketStockResponse>("marketStocks/fetchStocksAsync", handleFetchStocks);