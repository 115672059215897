import BigNumber from "bignumber.js";

/* eslint-disable import/prefer-default-export */
export const multipleDigits = (digits: number = 0) => {
  let multiple = 0;
  const isDigitsDouble = digits % 1 !== 0;
  if (isDigitsDouble) {
    const splitDigits = String(digits).split(".");
    const exponent = Number(splitDigits[0]);
    const decimal = Number(splitDigits[1]);
    if (digits < 0) {
      multiple = 10 ** (exponent - 1) * decimal;
    } else {
      multiple = 10 ** exponent * decimal;
    }
  } else {
    multiple = 10 ** digits;
  }
  return multiple;
};
export const getIDRDecimal = (digits: number = 0) => {
  const decimalPlaces = new BigNumber(digits).dp() ?? 0;
  return decimalPlaces;
};
export const getOldIDRDecimal = (digits: number = 0) => digits && digits < 0 ? Math.abs(Math.floor(digits)) : 0;