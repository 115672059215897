import Image from "next/image";
import classNames from "classnames";
import { Case, Default, Switch } from "@components/If";
import Skeleton from "@components/Skeleton";
import { Icons } from "@reku-web/ui";
import { getInitialName } from "./utils";
const sizes = {
  sm: `w-[48px] h-[48px] text-md`,
  md: `w-[72px] h-[72px] text-heading-sm`,
  lg: `w-[106px] h-[106px] text-heading-xl`
};
type Size = keyof typeof sizes;
const defaultSize = "md";
const getSize = (size: Size) => {
  switch (size) {
    case "sm":
      return 48;
    case "md":
      return 72;
    case "lg":
      return 106;
    default:
      return 72;
  }
};
interface AvatarProps {
  /**
   * Text avatars should be used when there is no image available for the user, or as a fallback when the user defined image fails to display.
   * The font size is set to allow a maximum of 2 characters of Latin script at the small size.
   */
  name: string;
  /**
   * Where possible, allow a user to include an image with their avatar to give the user the impression of personalized content even more.
   * Images passed to the component are cropped into a circle.
   */
  image?: string;
  /**
   * Avatar size
   */
  size?: Size;
  /**
   * Avatar loading
   */
  loading?: boolean;
  /**
   * Set focus attribute
   */
  focus?: boolean;
}
const Avatar: React.FC<AvatarProps> = ({
  name,
  image,
  size = defaultSize,
  loading,
  focus
}: AvatarProps) => {
  if (loading) {
    return <Skeleton width={getSize(size)} height={getSize(size)} className={size === "sm" ? "rounded-md" : "rounded-lg"} />;
  }
  return <div className={classNames("reku-new-theme flex items-center justify-center overflow-hidden", "bg-background-subtle-teal dark:bg-dark-background-subtle-teal", "border border-outline-neutral-subtle dark:border-dark-outline-neutral-subtle", `${sizes[size || defaultSize]}`, `${size === "sm" ? "rounded-md" : "rounded-lg"}`, {
    "ring-4 ring-teal-100 dark:ring-dark-teal-100": focus
  })}>
            <Switch>
                <Case condition={image}>
                    <Image src={image || ""} alt={`${name} image`} width={getSize(size)} height={getSize(size)} />
                </Case>
                <Case condition={name}>
                    <span className='text-text-color-teal dark:-text-dark-text-color-teal font-bold'>
                        {getInitialName(name)}
                    </span>
                </Case>
                <Default>
                    <Icons className='text-actions-primary-active' icon='User' width={getSize(size) / 2} height={getSize(size) / 2} />
                </Default>
            </Switch>
        </div>;
};
export default Avatar;