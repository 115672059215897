import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFaqTrade, fetchFaqTradeCount, fetchFaqStock, fetchFaqStockCount } from "./actions";
const faqTradeSlicer = createSlice({
  name: "faqTrade",
  initialState: {
    faqTrade: null as FaqTradeAPI[] | null,
    faqStock: null as FaqStockAPI[] | null,
    faqTradeCount: null as FaqTradeCountAPI[] | null,
    faqStockCount: null as FaqStockCountAPI[] | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    // Crypto
    [fetchFaqTrade.pending.type]: state => {
      state.isLoading = true;
      state.faqTrade = null;
    },
    [fetchFaqTrade.fulfilled.type]: (state, action: PayloadAction<FaqTradeAPI[]>) => {
      state.faqTrade = action.payload;
      state.isLoading = false;
    },
    [fetchFaqTrade.rejected.type]: state => {
      state.isLoading = false;
    },
    [fetchFaqTradeCount.pending.type]: state => {
      state.isLoading = true;
      state.faqTradeCount = null;
    },
    [fetchFaqTradeCount.fulfilled.type]: (state, action: PayloadAction<FaqTradeCountAPI[]>) => {
      state.faqTradeCount = action.payload;
      state.isLoading = false;
    },
    [fetchFaqTradeCount.rejected.type]: state => {
      state.isLoading = false;
    },
    // Stock
    [fetchFaqStock.pending.type]: state => {
      state.isLoading = true;
      state.faqStock = null;
    },
    [fetchFaqStock.fulfilled.type]: (state, action: PayloadAction<FaqStockAPI[]>) => {
      state.faqStock = action.payload;
      state.isLoading = false;
    },
    [fetchFaqStock.rejected.type]: state => {
      state.isLoading = false;
    },
    [fetchFaqStockCount.pending.type]: state => {
      state.isLoading = true;
      state.faqStockCount = null;
    },
    [fetchFaqStockCount.fulfilled.type]: (state, action: PayloadAction<FaqStockCountAPI[]>) => {
      state.faqStockCount = action.payload;
      state.isLoading = false;
    },
    [fetchFaqStockCount.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default faqTradeSlicer;