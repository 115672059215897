import { memo, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Container from "@components/v4/Container";
import { Icons } from "@reku-web/ui";
import useScroll from "@hooks/useScroll";
import CollapseMenu from "./CollapseMenu";
import MenuCard, { MenuCardProps } from "./MenuCard";
import { TRANSPARANSI_DOMAIN_HOST } from "@config/config";
interface RegulationSecurityMenuProps {
  fixedNavbar: boolean;
  active?: boolean;
  setExpanded: (expanded: boolean) => void;
}
const RegulationSecurityMenu: React.FC<RegulationSecurityMenuProps> = ({
  fixedNavbar,
  active,
  setExpanded
}) => {
  const {
    t
  } = useTranslation("components");
  const [open, setOpen] = useState(false);
  const {
    scrollVertical
  } = useScroll();
  useEffect(() => {
    if (fixedNavbar) {
      setOpen(false);
    }
  }, [scrollVertical, fixedNavbar]);
  useEffect(() => {
    setExpanded(open);
  }, [open]);
  const menu: MenuCardProps[] = [{
    variant: "teal",
    list: [{
      href: `https://${TRANSPARANSI_DOMAIN_HOST}/`,
      icon: "CoinsHand",
      label: "regulation_security.crypto.title",
      description: "regulation_security.crypto.description",
      dataTestId: "menuBar_TransparencyMenuBtn"
    }]
  }, {
    variant: "purple",
    list: [{
      href: `https://${TRANSPARANSI_DOMAIN_HOST}/saham-as`,
      icon: "Security",
      label: "regulation_security.us-stock.title",
      description: "regulation_security.us-stock.description",
      dataTestId: "menuBar_SecurityStockMenuBtn"
    }]
  }];
  return <CollapseMenu open={open} onChange={setOpen} dataTestId='menuBar_RegulationSecurityMenuBtn' overlay={<Container className='py-6'>
                    <div className='flex gap-8'>
                        {menu.map(({
        list,
        variant
      }) => <MenuCard className='!flex-1 !px-6 !py-4 !min-h-[88px]' navClassName='!flex-1' list={list} variant={variant} onClick={() => setOpen(false)} />)}
                    </div>
                </Container>}>
            <span className={classNames("reku-nav-item flex gap-[2px] items-center text-md font-bold text-text-neutral-main dark:text-dark-text-neutral-main hover:text-actions-primary-active dark:hover:text-dark-actions-primary-active", {
      "!text-actions-primary-active dark:!text-dark-actions-primary-active": active || open
    })}>
                {t("navbar.navs.regulation_security.title")}
                <Icons icon='ChevronDown' width={20} height={20} className={classNames("transition-transform duration-300 rotate-0", {
        "!rotate-180": open
      })} />
            </span>
        </CollapseMenu>;
};
export default memo(RegulationSecurityMenu);