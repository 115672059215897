/* eslint-disable simple-import-sort/imports */
/* eslint-disable no-useless-return */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import { Manrope } from "next/font/google";
import { useEffect, useMemo, useState } from "react";
import dynamic from "next/dynamic";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { Router, useRouter } from "next/router";
import { Context as ResponsiveContext } from "react-responsive";
import PageVisibility from "react-page-visibility";
import { HydrationProvider, useComponentHydrated } from "react-hydration-provider";
import { HydrationBoundary, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { getSelectorsByUserAgent } from "react-device-detect";
import ThemeProvider from "@styles/ThemeProvider";
import { wrapper } from "@redux/store";
import Layout from "layouts";
import { setLocale } from "@helpers/locale";
import App from "next/app";
import ErrorBoundary from "@modules/ErrorBoundary";
import { getAuth } from "@helpers/auth";
import moengageConfig from "@config/moengage";
import "@styles/themes/css-variables/light.css";
import "@styles/themes/css-variables/dark.css";
import "@styles/globals.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { fetchstatus } from "@hooks/useStatusAuth";
import throttle from "lodash.throttle";
import { COOKIE_PREFIX, KYC_REMINDER, RECAPTCHA_SITE_KEY } from "@config/config";
import Toast from "@components/Toast";
import { fetchOnPageSeoData } from "@redux/on-page-seo/actions";
import Script from "next/script";
import { When } from "@components/If";
import { pixelConfig } from "@config/meta-pixel";
import { setUserAgent } from "@redux/user-agent/slicer";
import { MediaContextProvider } from "@components/v2/Media";
import { Provider } from "react-redux";
import GtmScript from "@components/GtmScript";
const GlobalLoading = dynamic(() => import("@components/LoadingIndicator/GlobalLoading"), {
  ssr: false
});
const TopLoadingBar = dynamic(() => import("@components/TopLoadingBar"), {
  ssr: false
});
const ModalBappebtiKYCReminder = dynamic(() => import("@components/ModalBappebtiKYCReminder"), {
  ssr: false
});
const manrope = Manrope({
  variable: "--font-manrope",
  weight: ["300", "400", "500", "600", "700", "800"],
  style: ["normal"],
  subsets: ["latin"],
  display: "swap"
});
const MyApp = ({
  Component,
  ...rest
}: AppProps) => {
  const {
    store,
    props
  } = wrapper.useWrappedStore(rest);
  const [queryClient] = useState(() => new QueryClient());
  const hydrated = useComponentHydrated();
  const router = useRouter();
  const [loaded, setLoaded] = useState(false);
  const throttled = throttle((action: Function) => action(), 3000, {
    trailing: false
  });
  const handleVisibilty = (visible: boolean) => {
    const auth = getAuth();
    if (auth.isLoggedIn) {
      if (visible) {
        throttled(() => fetchstatus());
      }
    }
    return;
  };
  useEffect(() => {
    if (!loaded) return;
    const pageView = async () => {
      const {
        default: analytics
      } = await import("@lib/analytics");
      analytics.page();
      const {
        default: moengageAnalytic
      } = await import("@lib/moengage-analytic");
      moengageAnalytic.page();
      const {
        default: tiktokPixelAnalytic
      } = await import("@lib/tiktok-pixel-analytic");
      tiktokPixelAnalytic.page();
    };
    pageView();
  }, [router.asPath, loaded]);
  useEffect(() => {
    const handleRouteChangeEnd = async () => {
      window.scroll({
        top: 1,
        left: 0,
        behavior: "smooth"
      });
      const {
        default: analytics
      } = await import("@lib/analytics");
      analytics.page();
    };
    Router.events.on("routeChangeComplete", handleRouteChangeEnd);
    Router.events.on("routeChangeError", handleRouteChangeEnd);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChangeEnd);
      Router.events.off("routeChangeError", handleRouteChangeEnd);
    };
  }, []);
  useEffect(() => {
    ;
    (async () => {
      setLocale(router.locale || "id");
      const {
        default: moengageAnalytic
      } = await import("@lib/moengage-analytic");
      moengageAnalytic.identify("", {
        language: router.locale === "id" ? "Bahasa" : "English"
      });
    })();
  }, [router.locale]);
  const darkModeKey = `${COOKIE_PREFIX ?? ""}reku-darkmode`;
  const app = <>
            <Script async defer src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`} />
            <Script id='theme-setup' async strategy='beforeInteractive' dangerouslySetInnerHTML={{
      __html: `
                        const urlParams = new URLSearchParams(window.location.search);
                        const mode = urlParams.get('theme');

                        if (mode) {
                            const theme = document.cookie.split("; ").find((row) => row.startsWith("${darkModeKey}"));
                            const value = mode == "dark" && "1" || "0";
                            document.cookie = \`${darkModeKey}=\${value};path=/;\${theme?.split(";")[1] || ""}\`;
                        }

                        const theme = document.cookie.split("; ").find((row) => row.startsWith("${darkModeKey}"));
                        const value = theme?.split("=")[1] == "1" && "dark" || "light";
                        document.documentElement.dataset.theme = value;
                    `
    }} />
            <style jsx global>{`
                body {
                    font-family: ${manrope.style.fontFamily};
                }
            `}</style>
            <Script id='moengage-script' strategy='afterInteractive' type='text/javascript' dangerouslySetInnerHTML={{
      __html: `
                                (function(i,s,o,g,r,a,m,n){i.moengage_object=r;t={};q=function(f){return function(){(i.moengage_q=i.moengage_q||[]).push({f:f,a:arguments})}};f=['track_event','add_user_attribute','add_first_name','add_last_name','add_email','add_mobile','add_user_name','add_gender','add_birthday','destroy_session','add_unique_user_id','moe_events','call_web_push','track','location_type_attribute','track_page_view'],h={onsite:["getData","registerCallback"]};for(k
                                in f){t[f[k]]=q(f[k])}for(k in h)for(l in h[k]){null==t[k]&&(t[k]={}),t[k][h[k][l]]=q(k+"."+h[k][l])}a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);i.moe=i.moe||function(){n=arguments[0];return t};a.onload=function(){if(n){i[r]=moe(n)}}})(window,document,'script','https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js','Moengage');
                                
                                Moengage = moe({
                                    app_id: "${moengageConfig.appId}",
                                    debug_logs: ${Number(moengageConfig.debugMode)},
                                });
                            `
    }} />
            <GtmScript />
            <Script id='fb-script' strategy='afterInteractive' type='text/javascript' onLoad={() => setLoaded(true)} dangerouslySetInnerHTML={{
      __html: `
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq("init", "${pixelConfig.appId}");
                        fbq("track", "PageView");
                    `
    }} />

            <PageVisibility onChange={handleVisibilty}>
                <Provider store={store}>
                    <ThemeProvider>
                        <HydrationProvider>
                            <MediaContextProvider>
                                <Toast />
                                <TopLoadingBar />
                                <GlobalLoading />
                                <When condition={KYC_REMINDER}>
                                    <ModalBappebtiKYCReminder />
                                </When>
                                <ErrorBoundary>
                                    <Layout>
                                        <QueryClientProvider client={queryClient}>
                                            <HydrationBoundary state={props.dehydratedState}>
                                                <Component {...props.pageProps} />
                                            </HydrationBoundary>
                                        </QueryClientProvider>
                                    </Layout>
                                </ErrorBoundary>
                            </MediaContextProvider>
                        </HydrationProvider>
                    </ThemeProvider>
                </Provider>
            </PageVisibility>
        </>;
  const defaultSize = useMemo(() => ({
    width: 1280,
    height: 800
  }), []);
  return hydrated ? app : <ResponsiveContext.Provider value={defaultSize}>{app}</ResponsiveContext.Provider>;
};
MyApp.getInitialProps = wrapper.getInitialAppProps(store => async appContext => {
  const [appProps] = await Promise.all([App.getInitialProps(appContext), store.dispatch(fetchOnPageSeoData())]);
  const userAgent = String(appContext.ctx.req?.headers["user-agent"]);
  const {
    isMobile,
    isDesktop
  } = getSelectorsByUserAgent(userAgent);
  store.dispatch(setUserAgent({
    userAgent,
    isDesktop,
    isMobile
  }));
  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps
    }
  };
});
export default appWithTranslation(MyApp);