import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { internaltransfer } from "./actions";
const wdInternalTfSlicer = createSlice({
  name: "internaltransfer",
  initialState: {
    internaltransfer: null as WdInternalTransferModel | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [internaltransfer.pending.type]: state => {
      state.isLoading = true;
      state.internaltransfer = null;
    },
    [internaltransfer.fulfilled.type]: (state, action: PayloadAction<WdInternalTransferApi>) => {
      state.internaltransfer = {
        success: Boolean(action.payload.success),
        hash: action.payload.hash
      };
      state.isLoading = false;
    }
  }
});
export default wdInternalTfSlicer;