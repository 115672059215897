import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { simpleOrderAction } from "./actions";
const simpleOrderSlicer = createSlice({
  name: "simpleOrderResult",
  initialState: {
    orderResult: null as SimpleOrderResultModel | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [simpleOrderAction.pending.type]: state => {
      state.orderResult = null;
      state.isLoading = true;
    },
    [simpleOrderAction.fulfilled.type]: (state, action: PayloadAction<SimpleOrderResultAPI>) => {
      state.orderResult = {
        memoId: action.payload.memo_id,
        status: action.payload.status,
        transType: action.payload.trans_type,
        amountIdr: action.payload.quantity,
        amountCoin: action.payload.base_quantity,
        price: action.payload.price,
        fee: action.payload.fee,
        vat: action.payload.vat,
        total: action.payload.total,
        nett: action.payload.nett
      };
      state.isLoading = false;
    }
  }
});
export default simpleOrderSlicer;