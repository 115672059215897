import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface PortfolioPNLHistoryArgs {
  type: "us_stock" | "crypto" | "all";
  code?: string;
  start?: string; // rfc3339 date format
  end?: string; // rfc3339 date format
}
export const fetchPortfolioPNLHistoryAction = createAsyncThunk<PortfolioPNLHistoryAPI[], PortfolioPNLHistoryArgs>("portfolio/fetchPortfolioPNLHistory", async ({
  type,
  start,
  end,
  code
}) => {
  const response = await baseFetchApiV3({
    url: "/wallet/portfolio/pnl/history",
    params: {
      type: type === "all" ? undefined : type,
      start,
      end,
      code: code === "all" ? undefined : code
    }
  });
  return response.data ?? [];
});