/* eslint-disable no-param-reassign */
import { HYDRATE } from "next-redux-wrapper";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSettings } from "./actions";
const settingsSlicer = createSlice({
  name: "settings",
  initialState: {
    settings: null as SettingsModel | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchSettings.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchSettings.fulfilled.type]: (state, action: PayloadAction<SettingsAPI>) => {
      state.settings = {
        deposit: {
          fiat: {
            minimum: action.payload.min_deposit,
            minimumFee: action.payload.min_deposit_fee,
            fee: action.payload.deposit_fee
          }
        },
        withdraw: {
          fiat: {
            minimum: action.payload.min_withdraw,
            // @ts-ignore
            maximum: action.payload.max_withdraw?.[0],
            minimumFee: action.payload.min_withdraw_fee,
            // @ts-ignore
            fee: action.payload.withdraw_fee?.[0],
            smsFee: action.payload.smsfee,
            internalFee: action.payload.internaltransfer_fee
          },
          notes: action.payload.notes
        },
        trade: {
          minimumTradeAmount: action.payload.minprice,
          maximumPriceBuyPercentage: action.payload.pctmarketpricebuy,
          maximumPriceSellPercentage: action.payload.pctmarketpricesell
        },
        referral: {
          minimum: action.payload.min_withdraw_refferal,
          maximum: action.payload.max_withdraw_refferal
        },
        vat: {
          buy: action.payload.vatbuy,
          sell: action.payload.vatsell,
          deposit: {
            fiat: {
              internal: action.payload.vatinternaldepoidr,
              external: action.payload.vatdepoidr
            },
            coin: {
              internal: action.payload.vatinternaldepocoin,
              external: action.payload.vatdepocoin
            }
          },
          withdraw: {
            fiat: {
              internal: action.payload.vatinternalwdidr,
              external: action.payload.vatwdidr
            },
            coin: {
              internal: action.payload.vatinternalwdcoin,
              external: action.payload.vatwdcoin
            }
          },
          referral: action.payload.vatwdrefferal,
          event: action.payload.vatevent
        },
        maintenance: {
          status: Boolean(action.payload.maintenance),
          note: action.payload.maintenance_note
        },
        recaptcha: Boolean(action.payload.recaptcha),
        maticSend: action.payload.matic_send,
        periodFiat: action.payload.periodfiat,
        verificationMessage: action.payload.verification_message,
        version: action.payload.version
      };
      state.isLoading = false;
    },
    [HYDRATE]: (state, action: PayloadAction<{
      settings: {
        settings: SettingsModel;
      };
    }>) => {
      if (action.payload?.settings?.settings) {
        state.settings = action.payload.settings.settings;
      }
    }
  }
});
export default settingsSlicer;