import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WalletAssetHistoryModel } from "@modules/Wallet/hooks/useWalletBalanceHistory";
const historyBalanceIdr = createSlice({
  name: "historyBalanceIdr",
  initialState: {
    openDetailBalance: null as WalletAssetHistoryModel | null,
    isLoading: false
  },
  reducers: {
    setOpenDetailBalanceIdr: (state, action: PayloadAction<WalletAssetHistoryModel>) => {
      state.openDetailBalance = action.payload;
    },
    clearOpenDetailBalanceIdr: state => {
      state.openDetailBalance = null;
    }
  }
});
export const {
  setOpenDetailBalanceIdr,
  clearOpenDetailBalanceIdr
} = historyBalanceIdr.actions;
export default historyBalanceIdr;