import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPNLPeriodicalChartAction } from "./actions";
const portfolioPNLPeriodicalChartSlice = createSlice({
  name: "pnlPeriodicalChart",
  initialState: {
    pnlPeriodicalChart: null as PortfolioPNLPeriodicalChartModel[] | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchPNLPeriodicalChartAction.pending.type]: state => {
      state.pnlPeriodicalChart = null;
      state.isLoading = true;
    },
    [fetchPNLPeriodicalChartAction.fulfilled.type]: (state, action: PayloadAction<PortfolioPNLPeriodicalChartAPI[]>) => {
      state.pnlPeriodicalChart = action.payload.map(chart => ({
        timestamp: chart.bucket,
        percentage: chart.pnl_perc,
        amount: chart.pnl_amount,
        startAmount: chart.start_pnl,
        endAmount: chart.end_pnl,
        startPercentage: chart.start_pnl_perc,
        endPercentage: chart.end_pnl_perc
      }));
      state.isLoading = false;
    },
    [fetchPNLPeriodicalChartAction.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default portfolioPNLPeriodicalChartSlice;