import dynamic from "next/dynamic";
const EmptyStateIllustration = {
  NoAlert: dynamic(() => import("./NoAlert")),
  NoAPI: dynamic(() => import("./NoAPI")),
  NoAssets: dynamic(() => import("./NoAssets")),
  NoAssetHistory: dynamic(() => import("./NoAssetHistory")),
  NoBankAccount: dynamic(() => import("./NoBankAccount")),
  NoCoin: dynamic(() => import("./NoCoin")),
  NoConnection: dynamic(() => import("./NoConnection")),
  NoGraph: dynamic(() => import("./NoGraph")),
  NoList: dynamic(() => import("./NoList")),
  NoNotification: dynamic(() => import("./NoNotification")),
  NoPendingOrder: dynamic(() => import("./NoPendingOrder")),
  NoPhone: dynamic(() => import("./NoPhone")),
  NoSearchResult: dynamic(() => import("./NoSearchResult")),
  NoPortfolio: dynamic(() => import("./NoPortfolio")),
  NoTransactionHistory: dynamic(() => import("./NoTransactionHistory"))
};
export default EmptyStateIllustration;