interface AddressInfo {
  address: string;
  tagUrl?: string;
  tagAddress?: string;
}
type FullAddress = string;
type GetAddress = (fullAddress: FullAddress) => AddressInfo;
type FormatAddress = (address: AddressInfo) => FullAddress;

// @ts-ignore
export const getAddress: GetAddress = fullAddress => {
  const [address, qsAddress] = fullAddress.split("?");
  const [tagUrl, tagAddress] = String(qsAddress).split("=");
  return {
    address,
    tagUrl,
    tagAddress
  };
};
export const formatAddress: FormatAddress = ({
  address,
  tagAddress,
  tagUrl
}) => {
  let fullAddress = address;
  if (tagUrl && tagAddress) {
    fullAddress += `?${tagUrl}=${tagAddress}`;
  }
  return fullAddress;
};
export const getChainMatched = (address: string, regex: string) => {
  if (!address) {
    return true;
  }
  const chainMatched = regex ? RegExp(regex).test(address) : true;
  return chainMatched;
};