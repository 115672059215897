/* eslint-disable no-underscore-dangle */
import blogFetchApi from "@api/blogApi";
import { PUBLIC_ASSETS_URL } from "@config/config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { sanitizeHtml, wrapTableWithFigure } from "@utils/html";
const defaultImage = `${PUBLIC_ASSETS_URL}assets/images/no-image.webp`;
const tagFields = ["id", "description", "name", "slug", "taxonomy"].join(",");
const postsFields = ["id", "date_gmt", "slug", "title", "excerpt", "_links", "_embedded", "estimated_reading_time", "type", "old_slugs"].join();
export const fetchCoinInsights = createAsyncThunk<CoinInsightModel[], string>("coinInsights/fetchCoinInsights", async coinCode => {
  const coinCodeLower = coinCode.toLowerCase();
  const [analysisTag, campusTag] = await Promise.all([blogFetchApi<BlogCategoryAPI[]>({
    url: "/analysis_tag",
    params: {
      _fields: tagFields,
      per_page: 100
    },
    method: "GET"
  }), blogFetchApi<BlogCategoryAPI[]>({
    url: "/crypto_journey_tag",
    params: {
      _fields: tagFields,
      per_page: 100
    },
    method: "GET"
  })]);
  const analysisTagId = analysisTag.data.find(tag => tag.slug === coinCodeLower)?.id;
  const campusTagId = campusTag.data.find(tag => tag.slug === coinCodeLower)?.id;
  const [analysisPosts, campusPosts] = await Promise.all([analysisTagId ? blogFetchApi<BlogPostAPI[]>({
    url: "/analysis",
    params: {
      _fields: postsFields,
      _embed: true,
      per_page: 100,
      analysis_tag: analysisTagId
    },
    method: "GET"
  }) : {
    data: []
  }, campusTagId ? blogFetchApi<BlogPostAPI[]>({
    url: "/crypto_journey",
    params: {
      _fields: postsFields,
      _embed: true,
      per_page: 100,
      crypto_journey_tag: campusTagId
    },
    method: "GET"
  }) : {
    data: []
  }]);
  const convertToModel = (posts: BlogPostAPI[], type: string): CoinInsightModel[] => posts.map(post => ({
    id: post.id,
    date: post.date_gmt,
    slug: post.slug,
    title: post.title.rendered,
    excerpt: sanitizeHtml(post.excerpt?.rendered || ""),
    image: post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || defaultImage,
    category: null,
    estimated_reading_time: post.estimated_reading_time,
    post_tag: post._embedded?.["wp:term"]?.[1]?.filter(term => term.taxonomy.includes("tag"))?.map((i: any) => i.name)?.join(", ").toUpperCase() || null,
    type,
    old_slugs: post.old_slugs
  }));
  const posts = [...convertToModel(analysisPosts.data, "analysis"), ...convertToModel(campusPosts.data, "campus")].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  return posts;
});
export interface FetchCoinInsightDetailParams {
  slug: string;
  source: string;
}
export const fetchCoinInsightDetail = createAsyncThunk<CoinInsightDetailModel | null, FetchCoinInsightDetailParams>("coinInsights/fetchCoinInsightDetail", async ({
  slug,
  source
}) => {
  const sourceSlug = source === "analysis" ? "analysis" : "crypto_journey";
  const post = await blogFetchApi<BlogActivePostAPI[]>({
    url: `/${sourceSlug}`,
    params: {
      slug,
      _fields: ["id", "date_gmt", "slug", "title", "content", "_links", "_embedded", "estimated_reading_time", "type", "old_slugs"].join(","),
      _embed: true
    },
    method: "GET"
  });
  const convertToModel = async (item: BlogActivePostAPI): Promise<CoinInsightDetailModel> => {
    const tags = item._embedded?.["wp:term"]?.filter(term => term.some(e => e.taxonomy.includes("tag")))?.[0];
    const tag = tags?.[0];
    const sanitizedContent = sanitizeHtml(item.content.rendered);
    const wrappedContent = await wrapTableWithFigure(sanitizedContent);
    return {
      id: item.id,
      date: item.date_gmt,
      slug: item.slug,
      title: item.title.rendered,
      content: wrappedContent,
      image: item._embedded?.["wp:featuredmedia"]?.[0]?.source_url || defaultImage,
      category: null,
      tag: tag ? {
        id: tag.id,
        name: tag.name,
        slug: tag.slug
      } : null,
      estimated_reading_time: item.estimated_reading_time,
      post_tag: item._embedded?.["wp:term"]?.[1]?.filter(term => term.taxonomy.includes("tag"))?.map((i: any) => i.name)?.join(", ").toUpperCase() || null,
      type: source,
      old_slugs: item.old_slugs
    };
  };
  const data = post.data[0] ? await convertToModel(post.data[0]) : null;
  return data;
});