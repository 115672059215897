import { HYDRATE } from "next-redux-wrapper";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSimulationChartCrypto } from "./actions";
const simulationChartCryptoSlice = createSlice({
  name: "simulationChartCrypto",
  initialState: {
    simulationChartCrypto: null as number[][] | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchSimulationChartCrypto.pending.type]: state => {
      state.isLoading = true;
      state.simulationChartCrypto = null;
    },
    [fetchSimulationChartCrypto.fulfilled.type]: (state, action: PayloadAction<number[][]>) => {
      state.simulationChartCrypto = action.payload;
      state.isLoading = false;
    },
    [HYDRATE]: (state, action: PayloadAction<{
      simulationChartCrypto: {
        simulationChartCrypto: number[][];
      };
    }>) => {
      if (action.payload?.simulationChartCrypto?.simulationChartCrypto) {
        state.simulationChartCrypto = action.payload.simulationChartCrypto.simulationChartCrypto;
        state.isLoading = false;
      }
    }
  }
});
export default simulationChartCryptoSlice;