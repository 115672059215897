import { Illustration, IllustrationType } from "@reku-web/ui";
export interface PersonalityAvatarProps {
  /**
   * illustration name
   */
  riskLevelType: number;
  /**
   * illustration width
   */
  width?: number;
  /**
   * illustration name
   */
  height?: number;
}
const PersonalityAvatar: React.FC<PersonalityAvatarProps> = ({
  riskLevelType,
  width,
  height
}: PersonalityAvatarProps) => {
  const getIllustrationName = () => {
    switch (riskLevelType) {
      case 1:
        return "Leopard";
      case 2:
        return "Cheetah";
      case 3:
        return "Owl";
      case 4:
        return "Dolphin";
      case 5:
        return "Tortoise";
      default:
        return "";
    }
  };
  return <Illustration name={getIllustrationName() as IllustrationType} width={width} height={height} />;
};
PersonalityAvatar.defaultProps = {
  width: undefined,
  height: undefined
};
export default PersonalityAvatar;