/* eslint-disable import/prefer-default-export */
import { TFunction } from "next-i18next";
import BigNumber from "bignumber.js";
import dayjs, { Dayjs } from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";
import { LanguagesCode } from "@config/i18n";
import { formatNumber } from "./currency";
import "dayjs/locale/id";
export const formatDate = (date: number | Date | string | Dayjs | null, format: string, locale: LanguagesCode = "en"): string => {
  dayjs.extend(localeData);
  if (typeof date === "number") {
    const {
      length
    } = date.toString();
    if (length === 10) {
      return dayjs.unix(date).format(format);
    }
  }
  return dayjs(date).locale(locale).format(format);
};
export const formatDurationInDays = (date: number, translation: TFunction, locale: LanguagesCode = "en"): string => {
  dayjs.extend(dayjsDuration);
  const end = dayjs.unix(date).locale(locale);
  const startTime = dayjs().locale(locale);
  const duration = dayjs.duration(startTime.diff(end));
  const days = formatNumber(new BigNumber(duration.asDays()).decimalPlaces(0, BigNumber.ROUND_DOWN).toNumber());
  return translation("common:days_count", {
    days
  });
};
export const formatPublishDate = (date: number | string | Date, translation: TFunction, locale: LanguagesCode = "en"): string => {
  dayjs.extend(dayjsDuration);
  const end = dayjs(date).locale(locale);
  const startTime = dayjs().locale(locale);
  const duration = dayjs.duration(startTime.diff(end));
  const hours = new BigNumber(duration.asHours()).decimalPlaces(0, BigNumber.ROUND_DOWN).toNumber();
  const minutes = new BigNumber(duration.asMinutes()).decimalPlaces(0, BigNumber.ROUND_DOWN).toNumber();
  if (minutes < 60) {
    return translation("common:minutes", {
      minutes
    });
  }
  if (hours < 24) {
    return translation("common:hours", {
      hours
    });
  }
  return end.format("DD MMMM");
};
export const formatDateWithGMT = (date: number | Date | string | Dayjs | null, format: string, locale: LanguagesCode = "en"): string => {
  dayjs.extend(localeData);
  if (typeof date === "number") {
    const {
      length
    } = date.toString();
    if (length === 10) {
      return dayjs.unix(date).add(7, "hour").locale(locale).format(format);
    }
  }
  return dayjs(date).add(7, "hour").locale(locale).format(format);
};