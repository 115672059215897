import React, { memo, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import classNames from "classnames";
import Link from "@components/Link";
import useUserMode from "@hooks/useUserMode";
import { isBrowser } from "@utils/browser";
import LearningHubMenu from "./LearningHubMenu";
import ExploreMenu from "./ExploreMenu";
import InvestmentMenu from "./InvestmentMenu";
import RegulationSecurityMenu from "./RegulationSecurityMenu";
interface MenuItemProps {
  active?: boolean;
}
const MenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = ({
  active,
  children
}) => <span className={classNames("reku-nav-item text-md font-bold text-text-neutral-main dark:text-dark-text-neutral-main", "hover:text-actions-primary-active dark:text-white dark:hover:text-dark-actions-primary-active", {
  "text-actions-primary-active dark:text-dark-actions-primary-active": active
})}>
        {children}
    </span>;
interface MainMenuProps {
  fixedNavbar: boolean;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}
const MainMenu: React.FC<MainMenuProps> = ({
  fixedNavbar,
  setExpanded
}) => {
  const {
    t
  } = useTranslation("components");
  const router = useRouter();
  const {
    getTradeModeURL
  } = useUserMode();
  const tradePath = ["/trade/", "/lightning/price/"] as const;
  const learningHubPath = ["/information", "/rekap", "/guide-me", "/analysis", "/campus"] as const;
  const investPaths = ["/crypto", "/saham-as"] as const;
  const explorePaths = ["/staking", "/otc", "/packs", "/insight"] as const;
  const currentPath = router.route;
  const walletPath = currentPath.includes("/wallet");
  const investPath = investPaths.some(item => currentPath.includes(item));
  const explorePath = explorePaths.some(item => currentPath.includes(item));
  const transparencyPath = isBrowser && window?.location.href.includes("transparansi");
  const isActiveTradePath = tradePath.some(item => currentPath.includes(item));
  const isActiveLearningHubPath = learningHubPath.some(item => currentPath.includes(item));
  return <div className='hidden xl:flex items-center gap-8 font-bold'>
            <InvestmentMenu fixedNavbar={fixedNavbar} active={investPath} setExpanded={setExpanded} />
            <MenuItem active={isActiveTradePath}>
                <Link href={getTradeModeURL("BTC")} dataTestId='menuBar_tradeMenuBtn'>
                    {t("components:navbar.navs.trade_crypto")}
                </Link>
            </MenuItem>
            <ExploreMenu fixedNavbar={fixedNavbar} active={explorePath} setExpanded={setExpanded} />
            <MenuItem active={walletPath}>
                <Link href='/wallet' dataTestId='menuBar_walletMenuBtn'>
                    {t("components:navbar.navs.wallet")}
                </Link>
            </MenuItem>
            <LearningHubMenu fixedNavbar={fixedNavbar} active={isActiveLearningHubPath} setExpanded={setExpanded} />
            <RegulationSecurityMenu fixedNavbar={fixedNavbar} active={transparencyPath} setExpanded={setExpanded} />
        </div>;
};
export default memo(MainMenu);