/* eslint-disable simple-import-sort/imports */
/* eslint-disable import/no-cycle */
import axios, { AxiosAdapter, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { throttleAdapterEnhancer } from "axios-extensions";
import { getAuth } from "@helpers/auth";
import { MAINTENANCE, TOKEN_INVALID, TOKEN_TIMEOUT } from "@const/errno";
import { logout } from "@redux/auth/action";
import { toast } from "@components/Toast";
import { getLocale } from "@helpers/locale";
import { API_V3_URL } from "@config/config";
import querystring from "@utils/querystring";
import { setError } from "@redux/error/slicer";
import storeRegistry from "@redux/registry";
const client = axios.create({
  baseURL: API_V3_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Cache-Control": "no-cache"
  },
  adapter: throttleAdapterEnhancer(axios.defaults.adapter as AxiosAdapter)
});
const handleError = (message: string, errno: number) => {
  const store = storeRegistry.getStore();
  if (errno === TOKEN_TIMEOUT || errno === TOKEN_INVALID) {
    store.dispatch(logout(true) as any);
    return;
  }
  if (errno === MAINTENANCE) {
    const payload = {
      status: true,
      code: errno,
      message
    };
    store.dispatch(setError(payload));
    return;
  }
  toast.error(message);
};
const baseFetchApiV3 = async <T = any,>(config: AxiosRequestConfig): Promise<T> => {
  try {
    const {
      token,
      hash
    } = getAuth();
    const locale = getLocale();
    const headers: AxiosRequestHeaders = config?.headers || {};
    if (token) headers.token = token;
    if (hash) headers.hash = hash;
    if (locale) headers.lang = locale;
    const data = querystring.stringify(config?.data || {});
    const request = await client({
      ...config,
      data,
      headers
    });
    if (request.data.error) {
      handleError(request.data.error, request.data.errno);
      throw request.data.error;
    }
    return request.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err?.response?.data || err;
    }
    throw err;
  }
};
export default baseFetchApiV3;