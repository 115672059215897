import { HYDRATE } from "next-redux-wrapper";
import { createSlice } from "@reduxjs/toolkit";
import { fetchCoinInsightDetail, fetchCoinInsights } from "./actions";
const coinInsightsSlice = createSlice({
  name: "coinInsights",
  initialState: {
    allPosts: {
      posts: [] as CoinInsightModel[],
      coinCode: null as string | null,
      isLoading: true
    },
    activePost: {
      post: null as CoinInsightDetailModel | null,
      isLoading: true
    }
  },
  reducers: {},
  extraReducers: {
    [fetchCoinInsights.pending.type]: (state, action) => {
      state.allPosts.isLoading = true;
      state.allPosts.coinCode = action.meta.arg;
    },
    [fetchCoinInsights.fulfilled.type]: (state, action) => {
      state.allPosts.isLoading = false;
      state.allPosts.posts = action.payload;
    },
    [fetchCoinInsightDetail.pending.type]: state => {
      state.allPosts.isLoading = true;
    },
    [fetchCoinInsightDetail.fulfilled.type]: (state, action) => {
      state.activePost.isLoading = false;
      state.activePost.post = action.payload;
    },
    [HYDRATE]: (state, action) => {
      if (action.payload.coinInsights?.allPosts) {
        state.allPosts.posts = action.payload.coinInsights.allPosts.posts;
        state.allPosts.coinCode = action.payload.coinInsights.allPosts.coinCode;
        state.allPosts.isLoading = action.payload.coinInsights.allPosts.isLoading;
      }
      if (action.payload.coinInsights?.activePost) {
        state.activePost.post = action.payload.coinInsights.activePost.post;
        state.activePost.isLoading = action.payload.coinInsights.activePost.isLoading;
      }
    }
  }
});
export default coinInsightsSlice;