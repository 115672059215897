import { HYDRATE } from "next-redux-wrapper";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchStockChart } from "./actions";
const stockChartSlicer = createSlice({
  name: "stockChart",
  initialState: {
    stockChart: null as (number | string)[][] | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchStockChart.pending.type]: state => {
      state.isLoading = true;
      state.stockChart = null;
    },
    [fetchStockChart.fulfilled.type]: (state, action: PayloadAction<(number | string)[][]>) => {
      state.stockChart = action.payload;
      state.isLoading = false;
    },
    [HYDRATE]: (state, action: PayloadAction<{
      STOCKChart: {
        stockChart: (number | string)[][];
      };
    }>) => {
      if (action.payload?.STOCKChart?.stockChart) {
        state.stockChart = action.payload.STOCKChart.stockChart;
        state.isLoading = false;
      }
    }
  }
});
export default stockChartSlicer;