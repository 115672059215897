/* eslint-disable no-useless-return */
import { HYDRATE } from "next-redux-wrapper";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchMarketStockResponse, fetchStocks } from "./actions";
const marketStockSlicer = createSlice({
  name: "marketStock",
  initialState: {
    isLoading: true,
    stocks: null as StockModel[] | null
  },
  reducers: {
    updateMarketStocks: (state, action: PayloadAction<MarketStockSocketPayload>) => {
      if (!state.stocks) {
        return;
      }
      const data = action.payload.data;
      for (let i = 0; i < data.length; i++) {
        const code = data[i]?.[2]!;
        const index = state.stocks.findIndex(e => e.code === code);
        if (index !== -1) {
          if (state.stocks[index]) {
            state.stocks[index]!.price = {
              close: Number(data[i]?.[4]!),
              high: Number(data[i]?.[5]!),
              low: Number(data[i]?.[6]!),
              volume: Number(data[i]?.[10]!),
              previousClose: Number(data[i]?.[16]!),
              previousDayClosePrice: Number(data[i]?.[21]!),
              changePercentage: {
                default: Number(data[i]?.[11]!),
                core: Number(data[i]?.[18]!)
              },
              changeValue: Number(data[i]?.[19]!),
              changeValueCore: Number(data[i]?.[20]!),
              buy: Number(data[i]?.[12]!),
              sell: Number(data[i]?.[13]!),
              closeCore: Number(data[i]?.[17]!)
            };
          }
        }
      }
    }
  },
  extraReducers: {
    [fetchStocks.fulfilled.type]: (state, action: PayloadAction<FetchMarketStockResponse>) => {
      state.stocks = action.payload.stocks.map(stock => ({
        id: stock.id,
        name: stock.n,
        code: stock.cd,
        logo: stock.lg,
        symbol: stock.s,
        sorting: stock.sort,
        estimationSlippage: stock.slip,
        isNew: stock.new,
        lightColor: stock.lc,
        darkColor: stock.dc,
        marketBuy: stock.mar_b,
        marketSell: stock.mar_s,
        limitBuy: stock.lim_b,
        limitSell: stock.lim_s,
        fractional: stock.fr,
        extendedHoursFractional: stock.eh_fr,
        extendedHoursDelayStatus: stock.eh_ds,
        extendedHoursPreMarket: stock.eh_p,
        extendedHoursAfterMarket: stock.eh_a,
        isEtf: stock.etf,
        price: {
          high: stock.h,
          low: stock.l,
          close: stock.c,
          closeCore: stock.c_c,
          buy: stock.bp,
          sell: stock.sp,
          volume: stock.v,
          previousClose: stock.pc,
          previousDayClosePrice: stock.pdc,
          changePercentage: {
            default: stock.cp,
            core: stock.cp_c
          },
          changeValue: stock.cv,
          changeValueCore: stock.cv_c
        },
        information: {
          marketCap: stock.mc
        },
        decimals: {
          quote: stock.qd,
          shares: stock.sd
        }
      }));
      state.isLoading = false;
    },
    [fetchStocks.rejected.type]: state => {
      state.isLoading = false;
      state.stocks = null;
    },
    [HYDRATE]: (state, action) => {
      if (action.payload?.marketStock?.stocks) {
        state.isLoading = false;
        state.stocks = action.payload.marketStock.stocks;
      }
    }
  }
});
export const {
  updateMarketStocks
} = marketStockSlicer.actions;
export default marketStockSlicer;