import { v4 as uuidv4 } from "uuid";
import { getDeviceId as moengageDeviceId } from "@config/moengage";
import { isBrowser } from "@utils/browser";
import { getCookie, setCookie } from "./cookies";
const COOKIE_DEVICE_ID = "reku_uuid";
const getCookieDeviceId = () => {
  const cookieDeviceId = getCookie(COOKIE_DEVICE_ID);
  if (cookieDeviceId) {
    return cookieDeviceId;
  }
  return undefined;
};
const setCookieDeviceId = (newDeviceId: string) => {
  setCookie(COOKIE_DEVICE_ID, newDeviceId, {
    maxAge: undefined
  });
};
const getMoengageDeviceId = async () => {
  return new Promise<string>((resolve, reject) => {
    const deviceId = moengageDeviceId();
    if (deviceId) {
      resolve(deviceId);
      return;
    }
    const timeout = setTimeout(() => {
      reject(new Error("Moengage Device Id not found"));
      if (typeof window !== "undefined") {
        window.removeEventListener("MOE_LIFECYCLE", () => {});
      }
    }, 3000);
    if (typeof window !== "undefined") {
      window.addEventListener("MOE_LIFECYCLE", async (e: any) => {
        if (e.detail.name === "SETTINGS_FETCHED") {
          const newDeviceId = moengageDeviceId();
          if (newDeviceId) {
            clearTimeout(timeout);
            resolve(newDeviceId);
          }
        }
      });
    }
  });
};
const getDeviceId = async (): Promise<string | undefined> => {
  try {
    if (!isBrowser) return undefined;
    const cookieDeviceId = getCookieDeviceId();
    if (cookieDeviceId) {
      return cookieDeviceId;
    }
    const newDeviceId = await getMoengageDeviceId();
    setCookieDeviceId(newDeviceId);
    return newDeviceId;
  } catch (err) {
    const newDeviceId = uuidv4();
    setCookieDeviceId(newDeviceId);
    return newDeviceId;
  }
};
export default getDeviceId;