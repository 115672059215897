import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPortfolioPNLHistoryFilterAction } from "./actions";
const portfolioPNLHistoryFilterSlice = createSlice({
  name: "pnlHistoryFilter",
  initialState: {
    pnlHistoryFilter: null as PortfolioPNLHistoryFilterModel[] | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchPortfolioPNLHistoryFilterAction.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchPortfolioPNLHistoryFilterAction.fulfilled.type]: (state, action: PayloadAction<PortfolioPNLHistoryFilterAPI[]>) => {
      state.pnlHistoryFilter = action.payload.map(item => ({
        assetType: item.asset_type,
        code: item.code,
        name: item.name,
        logo: item.logo
      }));
      state.isLoading = false;
    }
  }
});
export default portfolioPNLHistoryFilterSlice;