import { startGlobalLoading, stopGlobalLoading } from "@redux/global-loading/slicer";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

/**
 *
 * How to use this hooks
 * @example
 * import useGlobalLoading from "@hooks/useGlobalLoading"
 *
 * const Component = () => {
 * const { loading } = useGlobalLoading()
 *
 * const handleSubmitData = () => {
 *  loading.start()
 *  // do something
 *  loading.stop()
 * }
 *
 * return (
 * <form> onSubmit={handleSubmitData}>
 *      <button type="submit">Submit</button>
 * </form>
 * )}
 *
 */
const useGlobalLoading = () => {
  const {
    isGlobalLoading
  } = useAppSelector(state => state.globalLoading);
  const dispatch = useAppDispatch();
  const loading = {
    start: () => dispatch(startGlobalLoading()),
    stop: () => dispatch(stopGlobalLoading())
  };
  return {
    isGlobalLoading,
    loading
  };
};
export default useGlobalLoading;