import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAssetSummaryAction } from "./actions";
const portfolioAssetSummarySlice = createSlice({
  name: "assetSummary",
  initialState: {
    assetSummary: null as PortfolioAssetSummaryModel | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchAssetSummaryAction.pending.type]: state => {
      state.assetSummary = null;
      state.isLoading = true;
    },
    [fetchAssetSummaryAction.fulfilled.type]: (state, action: PayloadAction<PortfolioAssetSummaryAPI>) => {
      state.assetSummary = {
        total: action.payload.total,
        assetValue: action.payload.asset_value,
        idrQty: action.payload.idr_qty,
        usdQty: action.payload.usd_qty,
        usdQtyIdr: action.payload.usd_qty_idr,
        unrealizedIdr: action.payload.unrealized_idr,
        unrealizedPercentage: action.payload.unrealized_perc,
        hasAsset: action.payload.has_asset,
        hasUssAsset: action.payload.has_uss_asset
      };
      state.isLoading = false;
    }
  }
});
export default portfolioAssetSummarySlice;