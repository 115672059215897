import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSummaryBreakdownAction } from "./actions";
const portfolioSummaryBreakdownSlice = createSlice({
  name: "summaryBreakdown",
  initialState: {
    summaryBreakdown: null as PortfolioSummaryBreakdownModel | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchSummaryBreakdownAction.pending.type]: state => {
      state.summaryBreakdown = null;
      state.isLoading = true;
    },
    [fetchSummaryBreakdownAction.fulfilled.type]: (state, action: PayloadAction<PortfolioSummaryBreakdownAPI>) => {
      state.summaryBreakdown = {
        marketValue: action.payload.market_value,
        availableBalance: action.payload.buying_power,
        pendingBalance: action.payload.cash_balance,
        cryptoOwned: action.payload.crypto_owned
      };
      state.isLoading = false;
    }
  }
});
export default portfolioSummaryBreakdownSlice;