import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface StockChartArgs {
  symbol: string;
  frame?: number; // in minute
  start?: number;
  end?: number;
}
export const fetchSimulationChartStock = createAsyncThunk<number[][], StockChartArgs>("otcStock/fetchSimulationChartStock", async ({
  frame = 1440,
  symbol,
  end,
  start
}) => {
  const response = await baseFetchApiV3({
    url: `/us-stock/chart`,
    method: "GET",
    params: {
      f: frame,
      s: symbol,
      end,
      start
    }
  });
  return response.sort();
});