import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface FetchFaqtrade {
  code: string;
}
const handleFetchFaqTradeCount = async ({
  code
}: FetchFaqtrade) => {
  const response = await baseFetchApiV3({
    url: "/signals/faq_list_crypto?select=code,count()",
    method: "GET",
    params: {
      code: `eq.${code}`
    }
  });
  return response;
};
const handleFetchFaqStockCount = async ({
  code
}: FetchFaqtrade) => {
  const response = await baseFetchApiV3({
    url: "/signals/faq_list_stock?select=code,count()",
    method: "GET",
    params: {
      code: `eq.${code}`
    }
  });
  return response;
};
const handleFetchFaqTrade = async ({
  code
}: FetchFaqtrade) => {
  const response = await baseFetchApiV3({
    url: "/signals/faq_list_crypto",
    method: "GET",
    params: {
      code: `eq.${code}`,
      order: "order.asc"
    }
  });
  return response;
};
const handleFetchFaqStock = async ({
  code
}: FetchFaqtrade) => {
  const response = await baseFetchApiV3({
    url: "/signals/faq_list_stock",
    method: "GET",
    params: {
      code: `eq.${code}`,
      order: "order.asc"
    }
  });
  return response;
};
export const fetchFaqTrade = createAsyncThunk<FaqTradeAPI, FetchFaqtrade>("faqTrade/fetchFaqTrade", handleFetchFaqTrade);
export const fetchFaqStock = createAsyncThunk<FaqStockAPI, FetchFaqtrade>("faqTrade/fetchFaqStock", handleFetchFaqStock);
export const fetchFaqTradeCount = createAsyncThunk<FaqTradeCountAPI, FetchFaqtrade>("faqTrade/fetchFaqTradeCount", handleFetchFaqTradeCount);
export const fetchFaqStockCount = createAsyncThunk<FaqStockCountAPI, FetchFaqtrade>("faqTrade/fetchFaqStockCount", handleFetchFaqStockCount);