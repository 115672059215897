import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPNLCumulativeChartAction } from "./actions";
const portfolioPNLCumulativeChartSlice = createSlice({
  name: "pnlCumulativeChart",
  initialState: {
    pnlCumulativeChart: null as PortfolioPNLCumulativeChartModel[] | null,
    pnlCumulativeChartDetail: null as PortfolioPNLCumulativeChartModel | null,
    isLoading: false
  },
  reducers: {
    setHoverData: (state, action: PayloadAction<PortfolioPNLCumulativeChartModel | null>) => {
      state.pnlCumulativeChartDetail = action.payload;
    }
  },
  extraReducers: {
    [fetchPNLCumulativeChartAction.pending.type]: state => {
      state.pnlCumulativeChart = null;
      state.pnlCumulativeChartDetail = null;
      state.isLoading = true;
    },
    [fetchPNLCumulativeChartAction.fulfilled.type]: (state, action: PayloadAction<PortfolioPNLCumulativeChartAPI>) => {
      state.pnlCumulativeChart = action.payload.map(chart => ({
        timestamp: chart.t,
        amount: chart.a
      }));
      state.pnlCumulativeChartDetail = state.pnlCumulativeChart[state.pnlCumulativeChart.length - 1] as PortfolioPNLCumulativeChartModel;
      state.isLoading = false;
    }
  }
});
export default portfolioPNLCumulativeChartSlice;
export const {
  setHoverData
} = portfolioPNLCumulativeChartSlice.actions;