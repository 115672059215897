/* eslint-disable import/prefer-default-export */
import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
interface Filter {
  offset?: number;
  start_date?: string;
  end_date?: string;
  trans_type?: number;
  coin_code?: string;
  status?: number;
}
export interface ModalAssetsArgs {
  transaction_id: string;
  id: number;
  wid: number;
  did: string;
  mi: number;
  datetime: number;
  initial_date: number;
  account_name: string;
  account_code: string;
  trans_type: number;
  status: number;
  from_address: string;
  to_address: string;
  label_address: string;
  amount: number;
  total_done: number;
  trx_amount: number;
  fee: number;
  trans_fee: number;
  sms_fee: number;
  vat: number;
  digits: number;
  decimals: number;
  digits_new: number;
  tx_id: string;
  explorer_url: string;
  chain_name: string;
  hash: string;
  is_internal?: boolean;
}
export interface FetchHistoryTransAssetsArgs extends Filter {
  hash?: string;
}
export const handleFetchHistoryTransAssets = async ({
  offset,
  start_date,
  end_date,
  trans_type,
  coin_code,
  status,
  hash
}: FetchHistoryTransAssetsArgs) => {
  let params: object = {
    offset,
    start_date,
    end_date
  };
  if (status) {
    params = {
      ...params,
      status
    };
  }
  if (trans_type) {
    params = {
      ...params,
      trans_type
    };
  }
  if (coin_code) {
    params = {
      ...params,
      coin_code
    };
  }
  const response = await baseFetchApi<HistoryAssetsApi[]>({
    url: hash ? `/wallet/history/family/${hash}/assets` : "/wallet/history/assets",
    method: "GET",
    params
  });
  return response;
};
export const fetchHistoryTransAssets = createAsyncThunk<HistoryAssetsApi[], FetchHistoryTransAssetsArgs>("history/fetchHistoryTransAssets", handleFetchHistoryTransAssets);
export const fetchHistoryTransAssetsPagination = createAsyncThunk<HistoryAssetsApi[], FetchHistoryTransAssetsArgs>("history/fetchHistoryTransAssetsPagination", async ({
  offset,
  end_date,
  start_date,
  status,
  trans_type,
  coin_code,
  hash
}) => {
  let params: object = {
    offset,
    start_date,
    end_date
  };
  if (status) {
    params = {
      ...params,
      status
    };
  }
  if (trans_type) {
    params = {
      ...params,
      trans_type
    };
  }
  if (coin_code) {
    params = {
      ...params,
      coin_code
    };
  }
  const response = await baseFetchApi({
    url: hash ? `/wallet/history/family/${hash}/assets` : "/wallet/history/assets",
    method: "GET",
    params
  });
  return response;
});