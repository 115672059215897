import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchCurrencyAction = createAsyncThunk<CurrencyAPI, string>("currency/fetchCurrency", async pair => {
  const response = await baseFetchApiV3({
    url: "/us-stock/currency",
    method: "GET",
    params: {
      s: pair
    }
  });
  return response;
});