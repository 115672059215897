import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useMediaQuery } from "react-responsive";
import Link from "@components/Link";
import Container from "@components/v4/Container";
import Logo from "@components/v3/Logo";
import { useTheme } from "@emotion/react";
import useEmbedView from "@hooks/useEmbedView";
import { Icons } from "@reku-web/ui";
import { isHideFooter } from "./utils/footer";
const AbiIcon = dynamic(() => import("./images/abi"), {
  ssr: false
});
const AspakrindoIcon = dynamic(() => import("./images/aspakrindo"), {
  ssr: false
});
const Bappebti = dynamic(() => import("./images/bappebti"), {
  ssr: false
});
const FacebookIcon = dynamic(() => import("./images/facebook"), {
  ssr: false
});
const InstagramIcon = dynamic(() => import("./images/instagram"), {
  ssr: false
});
const TelegramIcon = dynamic(() => import("./images/telegram"), {
  ssr: false
});
const TwitterIcon = dynamic(() => import("./images/twitter"), {
  ssr: false
});
const Iso = dynamic(() => import("./images/iso"), {
  ssr: false
});
const Kominfo = dynamic(() => import("./images/kominfo"), {
  ssr: false
});
const Footer: React.FC<React.PropsWithChildren> = () => {
  const {
    t
  } = useTranslation("components");
  const router = useRouter();
  const isEmbed = useEmbedView();
  const isMobile = useMediaQuery({
    maxWidth: 1279
  });
  const isDarkmode = useTheme().mode === "dark";
  const coinCode = String(router.query.symbol);
  const settingsPath = "/settings";
  const tradePath = `/trade/${coinCode}`;
  const lightPath = `/lightning/price/${coinCode}`;
  const isTradPath = Boolean(tradePath === router.asPath);
  const isLightPath = Boolean(lightPath === router.asPath);
  const isSettingsPath = router.asPath.includes(settingsPath);
  if (isHideFooter(router.pathname, isEmbed)) {
    return null;
  }
  if (isTradPath && isMobile || isLightPath && isMobile || isSettingsPath && isMobile) {
    return null;
  }
  const lists = [{
    title: t("components:footer.product.title"),
    links: [{
      title: t("components:footer.product.crypto_transaction"),
      url: "/markets"
    }, {
      title: t("components:footer.product.withdraw"),
      url: "/help/cara-melakukan-withdraw-rupiah"
    }, {
      title: t("components:footer.product.two_fa"),
      url: "/help/cara-mengaktifkan-2-factor-authentication"
    }, {
      title: t("components:footer.product.affiliate"),
      url: "/settings/referral-program"
    }]
  }, {
    title: t("components:footer.information.title"),
    links: [{
      title: t("components:footer.information.trading_rules"),
      url: "/trading-rule"
    }, {
      title: t("components:footer.information.fees"),
      url: "/fees"
    }, {
      title: t("components:footer.information.coinmarketcap"),
      url: "https://coinmarketcap.com/exchanges/rekeningku-com/",
      newTab: true
    }, {
      title: t("components:footer.information.blog"),
      url: "/information"
    }, {
      title: t("components:footer.information.terms"),
      url: "/help/syarat-dan-ketentuan-pelanggan-aset-kripto-rekeningku-com"
    }, {
      title: t("components:footer.information.privacy_terms"),
      url: "/help/kebijakan-privasi-reku"
    }, {
      title: t("components:footer.information.help"),
      url: "/help"
    }, {
      title: "FAQ",
      url: "/help"
    }, {
      title: t("components:footer.information.api"),
      url: "https://api.reku.id/",
      newTab: true
    }]
  }, {
    title: t("components:footer.company.title"),
    links: [{
      title: t("components:footer.company.about_us"),
      url: "/about"
    }, {
      title: t("components:footer.company.career"),
      url: "/careers"
    }]
  }];
  return <section className='reku-new-theme py-6 xl:py-8 bg-background-neutral-main dark:bg-dark-background-neutral-main'>
            <Container className='flex flex-col gap-6'>
                <div className='flex flex-col xl:flex-row gap-8'>
                    <div className='xl:w-[414px] space-y-4 xl:space-y-6'>
                        <Logo logo={isDarkmode ? "LogotypeWhite" : "LogotypeDefault"} className='h-8 w-[99px]' />
                        <div className='space-y-2'>
                            <div className='flex gap-2 text-body-md text-text-neutral-subtle dark:text-dark-text-neutral-subtle'>
                                <Icons icon='Mail' width={20} height={20} />
                                <a href='mailto:cs@reku.id'>cs@reku.id</a>
                            </div>
                            <div className='flex gap-2 text-body-md text-text-neutral-subtle dark:text-dark-text-neutral-subtle'>
                                <Icons icon='Location' width={20} height={20} />
                                <a href='https://www.google.com/maps/place/Reku+(Rekeningku.com)/@-6.2261139,106.8088843,15z/data=!4m2!3m1!1s0x0:0x4944702b0a9627ec' target='_blank'>
                                    Equity Tower - 11th Floor, Suite E <br /> SCBD - Jakarta Selatan 12190
                                </a>
                            </div>
                        </div>
                        <div className='flex gap-4 xl:gap-6'>
                            <a href='https://fb.me/reku.id' target='_blank' rel='noreferrer' aria-label='Reku Facebook link'>
                                <FacebookIcon />
                            </a>
                            <a href='https://www.instagram.com/reku_id' target='_blank' rel='noreferrer' aria-label='Reku Instagram link'>
                                <InstagramIcon />
                            </a>
                            <a href='https://twitter.com/reku_id' target='_blank' rel='noreferrer' aria-label='Reku Twitter link'>
                                <TwitterIcon />
                            </a>
                            <a href='https://t.me/reku_id' target='_blank' rel='noreferrer' aria-label='Reku Telegram link'>
                                <TelegramIcon />
                            </a>
                        </div>
                    </div>
                    <div className='flex-1 flex flex-col xl:flex-row gap-4 xl:justify-between'>
                        {lists.map(list => <div key={list.title} className='flex flex-col gap-1 flex-1'>
                                <span className='text-md font-bold text-text-neutral-main dark:text-dark-text-neutral-main mb-2'>
                                    {list.title}
                                </span>
                                {list.links.map(item => <Link href={item.url} target={item.newTab ? "_blank" : undefined} className='text-body-md text-text-neutral-subtle dark:text-dark-text-neutral-subtle !w-fit'>
                                        {item.title}
                                    </Link>)}
                            </div>)}
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex flex-col xl:flex-row gap-4 xl:gap-10'>
                        <div className='space-y-2'>
                            <span className='text-body-sm text-text-neutral-subtle dark:text-dark-text-neutral-subtle'>
                                {t("components:footer.registed")}
                            </span>
                            <div className='flex gap-4'>
                                <Link href='/help/rekeningku-com-telah-terdaftar-dan-diawasi-oleh-bappebti' target='_blank' ariaLabel="Bappebti's logo">
                                    <Bappebti height={48} />
                                </Link>
                                <Kominfo height={48} />
                                <Link href='https://www.cbqaglobal.com/validation/company/pt-rekeningku-dotcom-indonesia/' target='_blank' ariaLabel="ISO's logo">
                                    <Iso height={48} />
                                </Link>
                            </div>
                        </div>
                        <div className='space-y-2'>
                            <span className='text-body-sm text-text-neutral-subtle dark:text-dark-text-neutral-subtle'>
                                {t("components:footer.registed")}
                            </span>
                            <div className='flex gap-4'>
                                <a href='https://asosiasiblockchain.co.id/' target='_blank' rel='noreferrer' aria-label="ABI's logo">
                                    <AbiIcon />
                                </a>
                                <a href='https://aspakrindo.org/' target='_blank' rel='noreferrer' aria-label="Aspakrindo's logo">
                                    <AspakrindoIcon height={48} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <p className='text-body-sm text-text-neutral-disabled dark:text-dark-text-neutral-disable'>
                        {t("footer.warning")}
                    </p>
                </div>
                <div className='h-[1px] w-full bg-outline-neutral-main dark:bg-dark-outline-neutral-main' />
                <p className='text-body-sm text-text-neutral-disabled dark:text-dark-text-neutral-disabled text-center'>
                    © {new Date().getFullYear()} PT Rekeningku Dotcom Indonesia | All rights reserved.
                </p>
            </Container>
        </section>;
};
export default Footer;