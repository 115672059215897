import { HYDRATE } from "next-redux-wrapper";
import { REHYDRATE } from "redux-persist";
import { DARKMODE_COOKIE_KEY } from "@config/darkmode";
import { TRADE_PRO_INDICATORS } from "@const/moengage-analytic-event";
import { getCookie } from "@helpers/cookies";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const userPreferencesSlicer = createSlice({
  name: "userPreferences",
  initialState: {
    darkMode: false,
    livechat: {
      open: false
    },
    coinTableTab: "all",
    chartAdvancePeriod: 30,
    chartAdvanceIndicators: [{
      name: "VOL",
      type: "sub"
    }],
    chartAdvanceType: "original",
    rehydrated: false,
    transactionId: ""
  } as UserPreferencesModel,
  reducers: {
    updateChartAdvanceIndicator: (state, action: PayloadAction<ChartAdvanceIndicator>) => {
      state.chartAdvanceIndicators = state.chartAdvanceIndicators ?? [];
      const exist = state.chartAdvanceIndicators.find(item => item.name === action.payload.name);
      if (exist) {
        if (typeof action.payload.height !== "undefined") {
          exist.height = action.payload.height;
          return;
        }
        state.chartAdvanceIndicators = state.chartAdvanceIndicators.filter(indicator => indicator.name !== action.payload.name);
      } else if (action.payload.type === "main") {
        state.chartAdvanceIndicators = state.chartAdvanceIndicators.filter(indicator => indicator.type !== "main");
        state.chartAdvanceIndicators.push(action.payload);
      } else {
        const {
          length
        } = state.chartAdvanceIndicators.filter(item => item.type === action.payload.type);
        if (length >= 2) {
          state.chartAdvanceIndicators.shift();
        }
        state.chartAdvanceIndicators.push(action.payload);
      }
      const eventProperties = {
        vol_indicator: state.chartAdvanceIndicators?.find(item => item.name === "VOL") ? "Y" : "N",
        primary_indicator: state.chartAdvanceIndicators?.find(item => item.type === "main")?.name || null,
        secondary_indicator: state.chartAdvanceIndicators?.filter(item => item.type === "sub" && item.name !== "VOL")?.map(item => item.name).join("/") || null
      };
      (async () => {
        const {
          default: moengageAnalytic
        } = await import("@lib/moengage-analytic");
        moengageAnalytic.track(TRADE_PRO_INDICATORS, eventProperties);
      })();
    },
    changeUserPreference: (state, action: PayloadAction<UserPreferencesModel>) => ({
      ...state,
      ...action.payload
    })
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (action.payload.userPreferences) {
        state.darkMode = action.payload.userPreferences.darkMode || Boolean(Number(getCookie(DARKMODE_COOKIE_KEY)));
      }
    },
    [REHYDRATE]: (state, action) => {
      if (action.payload?.userPreferences) {
        if (action.payload.userPreferences.chartAdvancePeriod) {
          state.chartAdvancePeriod = action.payload.userPreferences.chartAdvancePeriod;
        }
      }
      state.rehydrated = true;
    }
  }
});
export const {
  updateChartAdvanceIndicator,
  changeUserPreference
} = userPreferencesSlicer.actions;
export default userPreferencesSlicer;