import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserMode, UserModeAPI } from "@type/api/user-mode";
import { updateUserMode } from "./actions";
const userModeSlicer = createSlice({
  name: "userMode",
  initialState: {
    userMode: {
      mode: UserMode.Pro
    } as UserModeAPI
  },
  reducers: {},
  extraReducers: {
    [updateUserMode.pending.type]: (state, action) => {
      state.userMode = action.meta.arg;
    },
    [updateUserMode.fulfilled.type]: (state, action: PayloadAction<UserModeAPI>) => {
      state.userMode = action.payload;
    }
  }
});
export default userModeSlicer;