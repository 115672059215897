import { memo, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Container from "@components/v4/Container";
import { Icons } from "@reku-web/ui";
import useScroll from "@hooks/useScroll";
import CollapseMenu from "./CollapseMenu";
import MenuCard, { MenuCardProps } from "./MenuCard";
interface ExploreMenuProps {
  fixedNavbar: boolean;
  active?: boolean;
  setExpanded: (expanded: boolean) => void;
}
const ExploreMenu: React.FC<ExploreMenuProps> = ({
  fixedNavbar,
  active,
  setExpanded
}) => {
  const {
    t
  } = useTranslation("components");
  const [open, setOpen] = useState(false);
  const {
    scrollVertical
  } = useScroll();
  useEffect(() => {
    if (fixedNavbar) {
      setOpen(false);
    }
  }, [scrollVertical, fixedNavbar]);
  useEffect(() => {
    setExpanded(open);
  }, [open]);
  const menu: MenuCardProps[] = [{
    title: "explore.for_crypto",
    variant: "teal",
    list: [{
      href: "/staking",
      icon: "CoinStaked3",
      label: "explore.staking.title",
      description: "explore.staking.description",
      dataTestId: "menuBar_stakingMenuBtn"
    }, {
      href: "/otc",
      icon: "LineChartUp",
      label: "explore.otc.title",
      description: "explore.otc.description",
      dataTestId: "menuBar_otcMenuBtn"
    }]
  }, {
    title: "explore.for_stock",
    variant: "purple",
    list: [{
      href: "/packs",
      icon: "CoinStaked3",
      label: "explore.sam.title",
      description: "explore.sam.description",
      dataTestId: "menuBar_samMenuBtn"
    }, {
      href: "/insight",
      icon: "LineChartUp",
      label: "explore.insight.title",
      description: "explore.insight.description",
      dataTestId: "menuBar_insightMenuBtn"
    }]
  }];
  return <CollapseMenu open={open} onChange={setOpen} dataTestId='menuBar_ExploreMenuBtn' overlay={<Container className='py-6'>
                    <div className='flex gap-8'>
                        {menu.map(({
        title,
        list,
        variant
      }) => <MenuCard className='!flex-1' navClassName='!flex-1' title={title} list={list} variant={variant} onClick={() => setOpen(false)} />)}
                    </div>
                </Container>}>
            <span className={classNames("reku-nav-item flex gap-[2px] items-center text-md font-bold text-text-neutral-main dark:text-dark-text-neutral-main hover:text-actions-primary-active dark:hover:text-dark-actions-primary-active", {
      "!text-actions-primary-active dark:!text-dark-actions-primary-active": active || open
    })}>
                {t("navbar.navs.explore.title")}
                <Icons icon='ChevronDown' width={20} height={20} className={classNames("transition-transform duration-300 rotate-0", {
        "!rotate-180": open
      })} />
            </span>
        </CollapseMenu>;
};
export default memo(ExploreMenu);