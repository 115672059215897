import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPNLSummaryAction } from "./actions";
const portfolioPNLSummarySlice = createSlice({
  name: "pnlSummary",
  initialState: {
    pnlSummary: null as PortfolioPNLSummaryModel | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchPNLSummaryAction.pending.type]: state => {
      state.pnlSummary = null;
      state.isLoading = true;
    },
    [fetchPNLSummaryAction.fulfilled.type]: (state, action: PayloadAction<PortfolioPNLSummaryAPI>) => {
      state.pnlSummary = {
        assetValue: action.payload.asset_value,
        assetValueUsd: action.payload.asset_value_usd,
        realizedTotal: action.payload.realized_asset + action.payload.realized_forex,
        realizedAsset: action.payload.realized_asset,
        realizedForex: action.payload.realized_forex,
        realizedUsd: action.payload.realized_usd,
        unrealizedTotal: action.payload.unrealized_asset + action.payload.unrealized_forex,
        unrealizedAsset: action.payload.unrealized_asset,
        unrealizedForex: action.payload.unrealized_forex,
        unrealizedUsd: action.payload.unrealized_usd,
        otherEarnings: action.payload.reward + action.payload.dividend + action.payload.staking + action.payload.yield,
        reward: action.payload.reward,
        dividend: action.payload.dividend,
        staking: action.payload.staking,
        yield: action.payload.yield
      };
      state.isLoading = false;
    },
    [fetchPNLSummaryAction.rejected.type]: state => {
      state.pnlSummary = null;
      state.isLoading = false;
    }
  }
});
export default portfolioPNLSummarySlice;