import { useEffect } from "react";
import { useRouter } from "next/router";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { fetchSettings } from "@redux/settings/actions";
const useSettings = () => {
  const {
    settings,
    isLoading
  } = useAppSelector(state => state.settings);
  const dispatch = useAppDispatch();
  const router = useRouter();
  useEffect(() => {
    if (settings === null) {
      dispatch(fetchSettings());
    }
  }, [dispatch, settings, router.locale]);
  useEffect(() => {
    dispatch(fetchSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.locale]);
  const refetch = () => {
    dispatch(fetchSettings());
  };
  return {
    settings,
    isLoading,
    refetch
  };
};
export default useSettings;