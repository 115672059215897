import { HYDRATE } from "next-redux-wrapper";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSimulationChartStock } from "./actions";
const simulationChartStockSlice = createSlice({
  name: "simulationChartStock",
  initialState: {
    simulationChartStock: null as number[][] | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchSimulationChartStock.pending.type]: state => {
      state.isLoading = true;
      state.simulationChartStock = null;
    },
    [fetchSimulationChartStock.fulfilled.type]: (state, action: PayloadAction<number[][]>) => {
      state.simulationChartStock = action.payload;
      state.isLoading = false;
    },
    [HYDRATE]: (state, action: PayloadAction<{
      simulationChartStock: {
        simulationChartStock: number[][];
      };
    }>) => {
      if (action.payload?.simulationChartStock?.simulationChartStock) {
        state.simulationChartStock = action.payload.simulationChartStock.simulationChartStock;
        state.isLoading = false;
      }
    }
  }
});
export default simulationChartStockSlice;