import throttle from "lodash.throttle";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { updateUserMode as updateUserModeAction } from "@redux/user-mode/actions";
import { UserMode } from "@type/api/user-mode";
const throttled = throttle((action: Function) => action(), 500, {
  trailing: false
});
const useUserMode = () => {
  const {
    userMode
  } = useAppSelector(state => state.userMode);
  const dispatch = useAppDispatch();
  const updateUserMode = (mode: UserMode) => {
    if (userMode.mode === mode) return;
    throttled(() => dispatch(updateUserModeAction(mode)));
  };
  const getTradeModeURL = (base: string) => {
    if (userMode.mode === UserMode.Pro) {
      return `/trade/${base}-IDR`;
    }
    return `/lightning/price/${base}`;
  };
  return {
    userMode,
    updateUserMode,
    getTradeModeURL
  };
};
export default useUserMode;