import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCurrencyAction } from "./actions";
const currencySlicer = createSlice({
  name: "currency",
  initialState: {
    currency: null as CurrencyModel | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchCurrencyAction.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchCurrencyAction.fulfilled.type]: (state, action: PayloadAction<CurrencyAPI>) => {
      state.currency = {
        bestAsk: action.payload.best_ask,
        bestBid: action.payload.best_bid,
        currency: action.payload.currency,
        id: action.payload.id,
        minimumTrade: action.payload.minimum_trade,
        symbol: action.payload.symbol
      };
      state.isLoading = false;
    }
  }
});
export default currencySlicer;