/* eslint-disable import/prefer-default-export */
import dayjs from "dayjs";
export interface OTCFeedsArgs {
  search?: string;
}
export const getOTCFeeds = async (args: OTCFeedsArgs) => {
  const {
    default: Parser
  } = await import("rss-parser");
  const search = args.search?.toLowerCase().split(",");
  const parser = new Parser({
    customFields: {
      item: ["description", "media:content"]
    }
  });
  const feed = await parser.parseURL("https://www.coindesk.com/arc/outboundfeeds/rss/?outputType=xml");
  let filteredItems = feed.items;
  if (search) {
    filteredItems = feed.items.filter(item => {
      const title = item.title?.toLowerCase();
      return search.some(value => title?.split(" ").includes(value));
    });
    if (filteredItems.length === 0) {
      filteredItems = feed.items;
    }
  }
  const feeds: OTCFeedsAPI[] = filteredItems.map(item => ({
    date: dayjs(item.pubDate).unix(),
    publish_date: item.pubDate as string,
    title: item.title as string,
    description: item.description,
    content: item.content as string,
    link: item.link as string,
    image: item["media:content"].$.url,
    source: "CoinDesk"
  }));
  return feeds.slice(0, 10);
};