import { useTranslation } from "next-i18next";
import Link from "@components/Link";
import { Icons } from "@reku-web/ui";
interface CTAPersonalityProps {
  /**
   * The information regarding user risk level
   */
  riskLevelName: string | undefined;
  /**
   * onClick event handler
   */
  onClick?: () => void;
}
const CTAPersonality: React.FC<CTAPersonalityProps> = ({
  riskLevelName,
  onClick
}: CTAPersonalityProps) => {
  const {
    t
  } = useTranslation();
  return <Link onClick={onClick} href={riskLevelName ? "/settings/investor-personality-profile" : "/settings/investor-personality"}>
            <div className='flex items-center gap-1 reku-new-theme'>
                {riskLevelName ? <span className='text-sm font-bold text-actions-primary-active dark:text-dark-action-primary'>
                        {riskLevelName}
                    </span> : <span className='text-sm font-bold text-actions-primary-active dark:text-dark-action-primary'>
                        {t("common:personality.sidebar_title")}
                    </span>}
                <Icons icon='ChevronRight' width={16} height={16} className='text-icon-primary dark:text-dark-icon-primary' />
            </div>
        </Link>;
};
CTAPersonality.defaultProps = {
  onClick: undefined
};
export default CTAPersonality;