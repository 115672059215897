/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import ContextIllustration from "./Context";
import EmptyStateIllustration from "./EmptyState";
import FeedbackIllustration from "./Feedback";
import PersonalityIllustration from "./Personality";
export const IllustrationComponent = {
  ...FeedbackIllustration,
  ...ContextIllustration,
  ...EmptyStateIllustration,
  ...PersonalityIllustration
};
export type IllustrationType = keyof typeof IllustrationComponent;
export interface IllustrationProps extends React.SVGProps<SVGSVGElement> {
  /**
   * Illustration name
   */
  name: keyof typeof IllustrationComponent;
  /**
   * Theme to applied
   */
  theme?: "light" | "dark";
}
const Illustration: React.FC<IllustrationProps> = ({
  name,
  theme = "light",
  ...props
}) => {
  const Component = IllustrationComponent[name] as React.ComponentType<any>;
  return <Component data-testid='reku-illustration' data-name={name} theme={theme} {...props} />;
};
export default Illustration;