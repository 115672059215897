import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchPortfolioPNLHistoryFilterAction = createAsyncThunk<PortfolioPNLHistoryFilterAPI[], "us_stock" | "crypto" | "all">("portfolio/fetchPortfolioPNLFilterHistory", async type => {
  const response = await baseFetchApiV3({
    url: "/wallet/portfolio/pnl/history/filter",
    params: {
      type: type === "all" ? "us_stock,crypto" : type
    }
  });
  return response.data ?? [];
});