import BigNumber from "bignumber.js";
import { LEADERBOARD_TICKET_PRICE } from "@config/leaderboard";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCompetitionLeaderboard } from "./actions";
const competitionLeaderboardSlicer = createSlice({
  name: "competitionLeaderboard",
  initialState: {
    competitionLeaderboard: null as CompetitionLeaderboardModel | null,
    isLoading: true,
    type: null as "trading" | "staking" | null
  },
  reducers: {},
  extraReducers: {
    [fetchCompetitionLeaderboard.pending.type]: (state, action) => {
      state.isLoading = true;
      if (action.meta.arg.type !== state.type) {
        state.competitionLeaderboard = null;
        state.type = action.meta.arg.type;
      }
    },
    [fetchCompetitionLeaderboard.fulfilled.type]: (state, action: PayloadAction<CompetitionLeaderboardAPI>) => {
      state.competitionLeaderboard = {
        ...state.competitionLeaderboard,
        [action.payload.meta.current_page]: action.payload.data?.map(item => ({
          rank: item.rank,
          username: item.username,
          score: item.score,
          ticket: BigNumber(item.score).div(LEADERBOARD_TICKET_PRICE).dp(0, BigNumber.ROUND_DOWN).toNumber(),
          isCurrentUser: item.is_current_user
        })) ?? [],
        meta: {
          currentPage: action.payload.meta.current_page,
          totalPage: action.payload.meta.total_page,
          total: action.payload.meta.total,
          size: action.payload.meta.size,
          from: action.payload.meta.from,
          to: action.payload.meta.to
        }
      };
      state.isLoading = false;
    }
  }
});
export default competitionLeaderboardSlicer;