/* eslint-disable import/prefer-default-export */

export const TERM_AND_CONDITION = "Term_and_Condition";
export const SIGN_UP_SUBMIT = "Sign_up_submit";
export const SIGN_UP_EMAIL_OTP_SUCCESS = "Sign_up_email_otp_success";
export const SIGN_UP_EMAIL_OTP_FAILED = "Sign_up_email_otp_failed";
export const SIGN_UP_EMAIL_OTP_RESEND = "Sign_up_email_otp_resend";
export const SIGN_UP_WITH_GOOGLE = "Sign_up_with_Google";
export const SIGN_UP_WITH_APPLE = "Sign_up_with_Apple";
export const REGISTRATION_REFERRAL_CHECK = "Registration_referral_check";
export const REGISTRATION_REFERRAL_SUBMIT = "Registration_referral_submit";
export const REGISTRATION_REFERRAL_SKIP = "Registration_referral_skip";
export const SIGN_IN = "Sign_in";
export const SIGN_IN_LOCKED_OUT = "Sign_in_locked_out";
export const FORGET_PASSWORD = "Forget_password";
export const FORGET_PASSWORD_EMAIL_INPUT = "Forget_password_email_input";
export const FORGET_PASSWORD_RESET_SUCCESS = "Forget_password_reset_success";
export const FORGET_PASSWORD_RESET_FAILED = "Forget_password_reset_failed";
export const LOST_GOOGLE_AUTH = "Lost_google_auth";
export const SIGN_IN_WITH_GOOGLE = "Sign_in_with_Google";
export const SIGN_IN_WITH_APPLE = "Sign_in_with_Apple";
export const INFO_AND_NEWS_CATEGORY_SELECTION = "Info_and_news_category_selection";
export const INFO_AND_NEWS_ARTICLE_CONTENT = "Info_and_news_article_content";
export const INFO_AND_NEWS_SEARCH = "Info_and_news_search";
export const INFO_AND_NEWS_SHARE_ARTICLE = "Info_and_news_share_article";
export const ANALYSIS_CATEGORY_SELECTION = "Analysis_category_selection";
export const ANALYSIS_ARTICLE_CONTENT = "Analysis_article_content";
export const ANALYSIS_SEARCH = "Analysis_search";
export const ANALYSIS_SHARE_ARTICLE = "Analysis_share_article";
export const REKU_RECAP = "Reku_recap";
export const REKU_RECAP_ARTICLE_CONTENT = "Reku_recap_article_content";
export const REKU_RECAP_SHARE_ARTICLE = "Reku_recap_share_article";
export const REKU_RECAP_SEARCH = "Reku_recap_search";
export const REKU_CAMPUS_CATEGORY_SELECTION = "Reku_Campus_category_selection";
export const REKU_CAMPUS_ARTICLE_CONTENT = "Reku_Campus_article_content";
export const REKU_CAMPUS_SEARCH = "Reku_Campus_search";
export const REKU_CAMPUS_SHARE_ARTICLE = "Reku_Campus_share_article";
export const DEPOSIT_METHOD_PAGE = "Deposit_method_page";
export const DEPOSIT_DETAIL_PAGE = "Deposit_detail_page";
export const DEPOSIT_PAYMENT_MADE = "Deposit_payment_made";
export const DEPOSIT_PAY_LATER = "Deposit_pay_later";
export const DEPOSIT_SUBMIT_SUCCESS = "Deposit_submit_success";
export const DEPOSIT_SUBMIT_FAILED = "Deposit_submit_failed";
export const DEPOSIT_TRANSFER_GUIDE = "Deposit_transfer_guide";
export const DEPOSIT_CANCEL = "Deposit_cancel";
export const TRADE_CHANGE_COIN = "Trade_change_coin";
export const TRADE_ADD_COIN_WATCHLIST = "Trade_add_coin_watchlist";
export const MODE_SWITCH = "Mode_switch";
export const TRADE_GRAPH_DETAILS = "Trade_graph_details";
export const TRADE_GRAPH_TIME_FRAME = "Trade_graph_time_frame";
export const TRADE_PRO_DEPTH = "Trade_pro_depth";
export const TRADE_PRO_INDICATORS = "Trade_pro_indicators";
export const TRADE_PRO_ADVANCE = "Trade_pro_advance";
export const POPOP_INFORMATION_MARKET_STATISTIC_COIN = "popup_information_market_statistic_coin";
export const WITHDRAW_METHOD_PAGE = "Withdraw_method_page";
export const ADD_WITHDRAWAL_ACCOUNT = "Add_withdrawal_account";
export const ADD_WITHDRAWAL_ACCOUNT_SUCCESS = "Add_withdrawal_account_success";
export const ADD_WITHDRAWAL_ACCOUNT_FAIL = "Add_withdrawal_account_fail";
export const WITHDRAW_ADDRESS_BOOK = "Withdraw_address_book";
export const WITHDRAW_ADD_NEW_ADDRESS = "Withdraw_add_new_address";
export const WITHDRAW_SUBMIT_SUCCESS = "Withdraw_submit_success";
export const WITHDRAW_SUBMIT_FAILED = "Withdraw_submit_failed";
export const WITHDRAW_CANCELED = "Withdraw_canceled";
export const WITHDRAW_FORM = "Withdraw_form";
export const WITHDRAW_INPUT_ADDRESS = "Withdraw_input_address";
export const WITHDRAW_SMS_OTP_FAILED = "Withdraw_sms_otp_failed";
export const WITHDRAW_AUTHENTICATOR_FAILED = "Withdraw_authenticator_failed";
export const WITHDRAW_SMS_OTP_RESEND = "Withdraw_sms_otp_resend";
export const WITHDRAW_EMAIL_OTP_FAILED = "Withdraw_email_otp_failed";
export const WITHDRAW_EMAIL_OTP_RESEND = "Withdraw_email_otp_resend";
export const HISTORY_WITHDRAW_DOWNTIME_INFORMATION = "History_withdraw_downtime_information";
export const WITHDRAW_DOWNTIME_INFORMATION = "Withdraw_downtime_information";
export const WITHDRAW_INPUT_AMOUNT = "Withdraw_input_amount";
export const WITHDRAW_PREVIEW = "Withdraw_preview";
export const WITHDRAW_RECEIVE_FULL_AMOUNT = "Withdraw_receive_full_amount";
export const WITHDRAW_VERIFICATION = "Withdraw_verification";
export const MARKET_SEARCH = "Market_search";
export const MARKET_SORT_SELECTION = "Market_sort_selection";
export const MARKET_ADD_TO_WATCHLIST = "Market_add_to_watchlist";
export const COIN_TRADE_PAGE = "Coin_trade_page";
export const BUY_LIGHTNING_SWITCH = "Buy_lightning_switch_input_amount";
export const BUY_LIGHTNING_PREVIEW = "Buy_lightning_preview";
export const BUY_ORDER_CANCEL = "Order_cancel";
export const BUY_ORDER_CANCEL_ALL = "Order_cancel_all";
export const BUY_ERROR = "Buy_error";
export const BUY_SUCCESS = "Buy_success";
export const BUY_FAILED = "Buy_failed";
export const NOTIFICATION = "Notification";
export const GOOGLE_AUTH_CREATED = "Google_auth_created";
export const SELL_SUCCESS = "sell_success";
export const SELL_FAILED = "sell_failed";
export const SELL_LIGHTNING_SWITCH = "sell_lightning_switch_input_amount";
export const SELL_ORDER_CANCEL = "Order_cancel";
export const SELL_LIGHTNING_PREVIEW = "sell_lightning_preview";
export const REFERRAL_PAGE = "Referral_page";
export const REFERRAL_SEND_INVITE = "Referral_send_invite";
export const REFERRAL_EDIT_USERNAME = "Referral_edit_username";
export const REFERRAL_LEARN_MORE = "Referral_learn_more";
export const REFERRAL_COMMISSION_BALANCE_PAGE = "Referral_commission_balance_page";
export const REFERRAL_WITHDRAW_COMMISSION = "Referral_withdraw_commission";
export const STAKING_MAIN_PAGE = "Staking_main_page";
export const STAKED_LANDING_PAGE = "Staked_landing_page";
export const TRANSACTIONS_HISTORY = "Transaction_history";
export const REKUIZ_AWARENESS = "Rekuiz_awareness_bar";
export const INVESTOR_PERSONALITY_TEST = "Investor_personality_test";
export const OTC_LANDING = "OTC_landing";
export const OTC_SETSCHEDULE_BANNER = "OTC_set_schedule_banner";
export const OTC_SETSCHEDULE = "OTC_set_schedule";
export const RETAKE_PERSONALITY_TEST = "Retake_personality_test";
export const ALLOCATION_INFORMATION = "Allocation_information";
export const ALLOCATION_CRYPTO = "Allocation_crypto";
export const ALLOCATION_CRYPTO_BUY = "Allocation_crypto_buy";
export const ALLOCATION_CRYPTO_PIE_CHART = "Allocation_crypto_pie_chart";
export const INVESTOR_PROFILE = "Investor_profile";
export const ALLOCATION_GENERAL = "Allocation_general";
export const START_PERSONALITY_TEST = "Start_personality_test";
export const INVESTOR_PERSONALITY_TEST_SUCCESS = "Investor_personality_test_success";
export const INVESTOR_PERSONALITY_TEST_FAILED = "Investor_personality_test_failed";
export const HISTORY_CHECK_EXPLORER = "History_check_explorer";
export const HISTORY_CONTACT_US = "History_contact_us";
export const JOIN_FAMILY_SUCCESS = "join_family_success";
export const JOIN_FAMILY_FAILED = "join_family_failed";
export const FAMILY_OWNER_DISCONNECT = "family_owner_disconnect";
export const FAMILY_MEMBER_DISCONNECT = "family_member_disconnect";
export const GENERATE_FAMILY_ACCOUNT_SUCCESS = "generate_family_account_success";
export const GENERATE_FAMILY_ACCOUNT_FAILED = "generate_family_account_failed";
export const FAMILY_OWNER_DEACTIVATE = "family_owner_deactivate";
export const LOST_PHONE_NUMBER = "lost_phone_number";
export const RECOVERY_PHONE_EMAIL_REQUEST = "recovery_phone_email_request";
export const RECOVERY_PHONE_PHOTO_SELFIE = "recovery_phone_photo_selfie";
export const RECOVERY_PHONE_VERIFICATION = "recovery_phone_verification";
export const EXPORT_TRANSACTION_HISTORY = "Export_transaction_history";
export const EXPORT_TRANSACTION_SUCCESS = "Export_transaction_success";
export const EXPORT_TRANSACTION_FAILED = "Export_transaction_failed";
export const PORTFOLIO_PAGE = "Portfolio_page";
export const PORTFOLIO_INFO_BUTTON = "Portfolio_info_button";
export const PORTFOLIO_HOLDING_TAB = "Portfolio_holding_tab";
export const PORTFOLIO_INSIGHT = "Portfolio_insight";
export const PORTFOLIO_INSIGHT_WAITLIST = "Portfolio_insight_waitlist";
export const KYC_DO_IT_LATER = "do_it_later";
export const KYC_DO_IT_NOW = "do_it_now";
export const TAX_REPORT_PAGE = "tax_report_page";
export const TAX_REPORT_DOWNLOAD = "Tax_report_download";
export const JESSE_LETTER_READ_MORE = "jesse_letter_read_more";
export const JESSE_LETTER_REKU_FORUM = "jesse_letter_reku_forum";