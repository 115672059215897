/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUserSettingsAction } from "./actions";
const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState: {
    userSettings: null as UserSettingsModel | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchUserSettingsAction.pending.type]: state => {
      state.isLoading = true;
      state.userSettings = null;
    },
    [fetchUserSettingsAction.fulfilled.type]: (state, action: PayloadAction<UserSettingsAPI>) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {
        user_id,
        ...rest
      } = action.payload;
      state.userSettings = {
        ...rest,
        userId: user_id
      };
      state.isLoading = false;
    },
    [fetchUserSettingsAction.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default userSettingsSlice;