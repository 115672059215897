/* eslint-disable import/no-cycle */
import { HYDRATE } from "next-redux-wrapper";
import dayjs from "dayjs";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileModel, UserStatus } from "@type/model/profile";
import { fetchProfile } from "./action";
const profileSlicer = createSlice({
  name: "profile",
  initialState: {
    profile: {} as ProfileModel,
    isLoading: true
  },
  reducers: {
    updateUserEmail: (state, action: PayloadAction<string>) => {
      state.profile.email = action.payload;
    },
    updateUserId: (state, action: PayloadAction<number>) => {
      state.profile.userId = action.payload;
    },
    clearUserProfile: state => {
      state.profile = {} as ProfileModel;
    }
  },
  extraReducers: {
    [fetchProfile.fulfilled.type]: (state, action: PayloadAction<ProfileAPI>) => {
      state.profile = {
        userId: action.payload.uid,
        fullName: action.payload.fullname,
        email: action.payload.email,
        status: Number(action.payload.status) as UserStatus,
        riskLevelName: action.payload.risk_level_name,
        riskLevelType: action.payload.risk_level_type,
        dob: action.payload.dob ? dayjs(action.payload.dob).format() : null,
        city: action.payload.city,
        lang: action.payload.lang,
        alias: action.payload.alias,
        isAllowChangePassword: action.payload.is_allow_change_password,
        identityNumber: action.payload.no_identitas
      };
      state.isLoading = false;
    },
    [HYDRATE]: (state, action: PayloadAction<any>) => {
      if (action.payload?.profile?.profile.email) {
        state.profile.email = action.payload.profile.profile.email;
      }
      if (action.payload?.profile?.profile.userId) {
        state.profile.userId = action.payload.profile.profile.userId;
      }
    }
  }
});
export const {
  updateUserEmail,
  updateUserId,
  clearUserProfile
} = profileSlicer.actions;
export default profileSlicer;