import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Link from "@components/Link";
import { Icons, IconsProps } from "@reku-web/ui";
import { memo } from "react";
interface NavItemProps {
  isActive?: boolean;
  onClick?: () => void;
  className?: string;
}
const NavItem: React.FC<React.PropsWithChildren<NavItemProps>> = ({
  isActive,
  className,
  children,
  onClick
}) => <div className={classNames("w-full h-[28px]", "flex items-center justify-between", "text-md font-bold text-actions-grey-active dark:text-dark-actions-grey-active", {
  "!text-actions-primary-active dark:!text-dark-actions-primary-active": isActive
}, className)} onClick={onClick}>
        {children}
    </div>;
interface NavChildrenProps {
  href: string;
  icon: IconsProps;
  label: string;
  description: string;
  dataTestId?: string;
  onClick?: () => void;
  links?: Array<{
    href: string;
    label: string;
    dataTestId?: string;
  }>;
}
export interface MenuCardProps {
  title?: string;
  list: Array<NavChildrenProps>;
  variant: "teal" | "purple";
  onClick?: () => void;
  className?: string;
  navClassName?: string;
  suffix?: React.ReactNode;
}
const MenuCard = ({
  variant,
  title,
  list,
  className,
  navClassName,
  suffix,
  onClick
}: MenuCardProps) => {
  const {
    t
  } = useTranslation("components");
  return <div className={classNames("flex flex-col gap-2 p-3 rounded-md min-h-[116px]", className, {
    "bg-[#EDF7F7] dark:bg-[#1E4548]": variant === "teal",
    "bg-[#F5F0FE] dark:bg-[#432183]": variant === "purple"
  })}>
            {title && <span className='text-xs font-bold tracking-[2px] text-text-neutral-subtle dark:text-dark-text-neutral-subtle uppercase'>
                    {t(`navbar.navs.${title}`)}
                </span>}
            <div className='flex flex-col gap-3 xl:flex-row xl:gap-6 xl:flex-wrap'>
                <>
                    {list.map(({
          href,
          icon,
          label,
          description,
          dataTestId,
          links,
          onClick: subClick
        }) => <NavItem className={`!h-[unset] flex !items-start !justify-start !gap-3 xl:!w-[260px] ${navClassName}`}>
                            <Icons icon={icon} width={28} height={28} className={classNames({
            "text-actions-primary-active dark:text-dark-actions-primary-active": variant === "teal",
            "text-actions-secondary-active dark:text-dark-actions-secondary-active": variant === "purple"
          })} />
                            <div className='flex flex-col gap-1'>
                                <Link href={href} onClick={() => {
              onClick?.();
              subClick?.();
            }} dataTestId={dataTestId}>
                                    <span className='flex items-center gap-0.5 text-lg font-bold text-text-neutral-strong dark:text-dark-text-neutral-strong'>
                                        {t(`navbar.navs.${label}`)}
                                        <Icons icon='ArrowUpRight' className='w-5 h-5' />
                                    </span>
                                </Link>
                                <span className='text-body-sm xl:text-body-md text-text-neutral-subtle dark:text-dark-text-neutral-subtle line-clamp-2 xl:line-clamp-3'>
                                    {t(`navbar.navs.${description}`)}
                                </span>
                                {links && <div className='flex gap-8'>
                                        {links.map(({
                href,
                label,
                dataTestId
              }) => <Link href={href} onClick={onClick} dataTestId={dataTestId} className={classNames("flex items-center gap-0.5 text-md font-bold", {
                "text-actions-primary-active dark:text-dark-actions-primary-active": variant === "teal",
                "text-actions-secondary-active dark:text-dark-actions-secondary-active": variant === "purple"
              })}>
                                                {t(`navbar.navs.${label}`)}
                                                <Icons icon='ArrowUpRight' className='w-5 h-5' />
                                            </Link>)}
                                    </div>}
                            </div>
                        </NavItem>)}
                    {suffix && suffix}
                </>
            </div>
        </div>;
};
export default memo(MenuCard);