/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { isBrowser } from "@utils/browser";
import { getOTCFeeds, OTCFeedsArgs } from "@utils/otc-feeds";
export const fetchOTCFeeds = createAsyncThunk<OTCFeedsAPI[], OTCFeedsArgs>("otcFeeds/fetchOTCFeeds", async ({
  search
}) => {
  if (isBrowser) {
    const response = await axios.get("/api/otc/feeds", {
      params: {
        search
      }
    });
    return response.data;
  }
  const data = await getOTCFeeds({
    search
  });
  return data;
});