/* eslint-disable import/no-cycle */
/* eslint-disable simple-import-sort/imports */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAuth, setAuth, resetAuth as resetAuthCookie } from "@helpers/auth";
import { logout, login, login2fa, loginGoogle, register, verificationOtp, loginApple, loginCallback } from "./action";
const auth = getAuth();
const authSlicer = createSlice({
  name: "auth",
  initialState: {
    open2fa: false,
    token: auth.token ?? null,
    hash: auth.hash ?? null,
    isLoggedIn: auth.isLoggedIn
  } as AuthModel,
  reducers: {
    updateAuth: (state, action: PayloadAction<AuthModel>) => {
      state.token = action.payload?.token;
      state.hash = action.payload?.hash;
      state.isLoggedIn = action.payload?.isLoggedIn;
    },
    resetAuth: state => {
      state.token = undefined;
      state.hash = undefined;
      state.isLoggedIn = false;
    },
    open2fa: state => {
      state.open2fa = true;
    },
    close2fa: state => {
      state.open2fa = false;
    }
  },
  extraReducers: {
    [logout.fulfilled.type]: state => {
      state.hash = undefined;
      state.token = undefined;
      state.isLoggedIn = false;
      resetAuthCookie();
    },
    [login.pending.type]: state => {
      state.hash = undefined;
      state.token = undefined;
      state.isLoggedIn = false;
    },
    [login.fulfilled.type]: (state, action: PayloadAction<AuthAPI>) => {
      state.token = action.payload?.token;
      state.hash = action.payload?.hash;
      state.isLoggedIn = Boolean(action.payload?.token && action.payload?.hash);
      setAuth({
        token: action.payload?.token,
        hash: action.payload?.hash
      });
    },
    [login2fa.fulfilled.type]: (state, action: PayloadAction<AuthAPI>) => {
      state.hash = action.payload.hash;
      state.token = action.payload.token;
      state.isLoggedIn = Boolean(action.payload?.token && action.payload?.hash);
      setAuth({
        token: action.payload?.token,
        hash: action.payload?.hash
      });
    },
    [loginCallback.fulfilled.type]: (state, action: PayloadAction<AuthAPI>) => {
      state.token = action.payload?.token;
      state.hash = action.payload?.hash;
      state.isLoggedIn = !!action.payload?.token;
      setAuth({
        token: action.payload?.token,
        hash: action.payload?.hash
      });
    },
    [loginGoogle.fulfilled.type]: (state, action: PayloadAction<LoginGooglePayload>) => {
      state.hash = action.payload?.hash;
      state.token = action.payload?.token;
      state.isLoggedIn = Boolean(action.payload?.hash) && Boolean(action.payload?.token);
      setAuth({
        token: action.payload?.token,
        hash: action.payload?.hash
      });
    },
    [loginApple.fulfilled.type]: (state, action: PayloadAction<LoginAPI>) => {
      state.hash = action.payload.hash;
      state.token = action.payload?.token;
      state.isLoggedIn = Boolean(action.payload?.hash) && Boolean(action.payload?.token);
      setAuth({
        token: action.payload?.token,
        hash: action.payload?.hash
      });
    },
    [verificationOtp.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.token = action.payload?.token;
      state.hash = action.payload?.hash;
      state.isLoggedIn = Boolean(action.payload?.token && action.payload?.hash);
      setAuth({
        token: action.payload?.token,
        hash: action.payload?.hash
      });
    },
    [register.fulfilled.type]: (state, action: PayloadAction<AuthModel & RegisterPayload>) => {
      state.token = action.payload?.token;
      state.hash = action.payload?.hash;
      state.isLoggedIn = Boolean(action.payload?.token && action.payload?.hash);
      setAuth({
        token: action.payload?.token,
        hash: action.payload?.hash
      });
    }
  }
});
export const {
  updateAuth,
  resetAuth,
  open2fa,
  close2fa
} = authSlicer.actions;
export default authSlicer;