import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchTaxReportAction } from "./actions";
const taxReportSlice = createSlice({
  name: "taxReport",
  initialState: {
    taxReport: null as TaxReportModel | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchTaxReportAction.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchTaxReportAction.fulfilled.type]: (state, action: PayloadAction<TaxReportAPI>) => {
      state.isLoading = false;
      state.taxReport = {
        id: action.payload.id,
        totalBalance: Number(action.payload.total_balance),
        totalVolume: Number(action.payload.total_volume),
        totalVolumeBuy: Number(action.payload.total_volume_buy),
        totalVolumeSell: Number(action.payload.total_volume_sell),
        totalTax: Number(action.payload.total_tax),
        totalTaxBuy: Number(action.payload.total_tax_buy),
        totalTaxSell: Number(action.payload.total_tax_sell),
        year: action.payload.year
      };
    },
    [fetchTaxReportAction.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default taxReportSlice;