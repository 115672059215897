import axios from "axios";
import baseFetchApi from "@api/baseApi";
import { FIREBASE_API_KEY } from "@config/config";
import { getAuth } from "@helpers/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchReferralIdentity = createAsyncThunk<ReferralIdentityAPI, string>("referral/fetchReferralIdentity", async email => {
  const response = await baseFetchApi<ReferralIdentityAPI>({
    url: "/affiliateurl",
    method: "GET"
  });
  const generateReferralWebUrl = () => {
    let origin = String(window.location.origin);
    if (origin.includes("localhost")) {
      origin = "https://reku.id";
    }
    return `${origin}/ref/${response.refname}`;
  };
  try {
    const firebaseResponse = await axios.post<FirebaseDynamicLinkAPI>(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks`, {
      longDynamicLink: `https://reku.page.link/?link=${generateReferralWebUrl()}&apn=com.rekeningku&ibi=com.rekeningku.app&isi=1458706382`,
      suffix: {
        option: "UNGUESSABLE"
      }
    }, {
      params: {
        key: FIREBASE_API_KEY
      }
    });
    return {
      ...response,
      email,
      ref_url: firebaseResponse.data.shortLink
    };
  } catch (err) {
    return {
      ...response,
      email,
      ref_url: generateReferralWebUrl()
    };
  }
});
export const updateAlias = createAsyncThunk<SuccessAPI, string>("referral/updateAlias", async username => {
  const auth = getAuth();
  const response = await baseFetchApi({
    url: "/updatealias",
    method: "POST",
    data: {
      alias: username,
      token: auth.token
    }
  });
  return response;
});