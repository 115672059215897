/* eslint-disable import/no-cycle */
import { baseFetchApiV3 } from "@api/baseApi";
import { getAuth } from "@helpers/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchWatchlist = createAsyncThunk<WatchlistAPI>("watchlist/fetchWatchlist", async () => {
  const {
    isLoggedIn
  } = getAuth();
  if (isLoggedIn) {
    const res = await baseFetchApiV3({
      url: "/coins/watchlist",
      method: "GET"
    });
    return res;
  }
  return null;
});
export const addWatchlist = createAsyncThunk("watchlist/addWatchlist", (payload: number, {
  getState
}) => {
  const {
    isLoggedIn
  } = getAuth();
  const {
    watchlist: {
      watchlist
    }
  }: any = getState();
  const newWatchlist = [...watchlist];
  newWatchlist.unshift(payload);
  if (isLoggedIn) {
    baseFetchApiV3({
      url: "/coins/watchlist",
      method: "PUT",
      data: newWatchlist,
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
  return newWatchlist;
});
export const removeWatchlist = createAsyncThunk("watchlist/removeWatchlist", (payload: number, {
  getState
}) => {
  const {
    isLoggedIn
  } = getAuth();
  const {
    watchlist: {
      watchlist
    }
  }: any = getState();
  const index = watchlist.indexOf(payload);
  if (index !== -1) {
    const newWatchlist = [...watchlist.slice(0, index), ...watchlist.slice(index + 1)];
    if (isLoggedIn) {
      baseFetchApiV3({
        url: "/coins/watchlist",
        method: "PUT",
        data: newWatchlist,
        headers: {
          "Content-Type": "application/json"
        }
      });
    }
    return newWatchlist;
  }
  return watchlist;
});
export const fetchStockWatchlist = createAsyncThunk<StockWatchlistAPI>("watchlist/fetchStockWatchlist", async () => {
  const {
    isLoggedIn
  } = getAuth();
  if (isLoggedIn) {
    const res = await baseFetchApiV3({
      url: "/us-stock/watchlist",
      method: "GET"
    });
    return res;
  }
  return null;
});
export const addStockWatchlist = createAsyncThunk("watchlist/addStockWatchlist", (payload: string, {
  getState
}) => {
  const {
    isLoggedIn
  } = getAuth();
  const {
    watchlist: {
      stockWatchlist
    }
  }: any = getState();
  const newStockWatchlist = [...stockWatchlist];
  newStockWatchlist.unshift(payload);
  if (isLoggedIn) {
    baseFetchApiV3({
      url: "/us-stock/watchlist",
      method: "PATCH",
      data: newStockWatchlist,
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
  return newStockWatchlist;
});
export const removeStockWatchlist = createAsyncThunk("watchlist/removeStockWatchlist", (payload: string, {
  getState
}) => {
  const {
    isLoggedIn
  } = getAuth();
  const {
    watchlist: {
      stockWatchlist
    }
  }: any = getState();
  const index = stockWatchlist.indexOf(payload);
  if (index !== -1) {
    const newStockWatchlist = [...stockWatchlist.slice(0, index), ...stockWatchlist.slice(index + 1)];
    if (isLoggedIn) {
      baseFetchApiV3({
        url: "/us-stock/watchlist",
        method: "PATCH",
        data: newStockWatchlist,
        headers: {
          "Content-Type": "application/json"
        }
      });
    }
    return newStockWatchlist;
  }
  return stockWatchlist;
});