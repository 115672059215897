import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchPNLCumulativeChartAction = createAsyncThunk<PortfolioPNLCumulativeChartAPI, "us_stock" | "crypto" | undefined>("portfolio/fetchPNLCumulativeChart", async type => {
  const response = await baseFetchApiV3<BaseResponseAPIV3<PortfolioPNLCumulativeChartAPI>>({
    url: "/wallet/portfolio/pnl/cumulative/chart",
    params: {
      type
    }
  });
  return response.data ?? [];
});