import { useCollapse } from "react-collapsed";
interface Props extends React.PropsWithChildren {
  isExpanded: boolean;
}
const Collapse: React.FC<Props> = ({
  isExpanded,
  children
}) => {
  const {
    getCollapseProps
  } = useCollapse({
    isExpanded
  });

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div {...getCollapseProps()}>{children}</div>;
};
export default Collapse;