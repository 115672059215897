/* eslint-disable simple-import-sort/imports */
import { combineReducers } from "@reduxjs/toolkit";
import feeApi from "@modules/Fees/services/fee";
import helpPostsApi from "@modules/Help/services/helpPosts";
import helpDetailPostApi from "@modules/Help/services/helpDetailPost";
import helpRedirectionApi from "@modules/Help/services/helpRedirection";
import authSlicer from "./auth/slicer";
import adsSlicer from "./ads/slicer";
import advanceTradeFormSlicer from "./advance-trade-form/slicer";
import analysisSlicer from "./analysis/slicer";
import announcementSlicer from "./announcement/slicer";
import apiKeysSlicer from "./api-key/slicer";
import balancesSlicer from "./balances/slicer";
import balancesMemberSlice from "./balances-member/slicer";
import balancesStockSlicer from "./balances-stock/slicer";
import banksSlicer from "./banks/slicer";
import bankUserSlicer from "./bank-user/slicer";
import bankTransferSlicer from "./bank-transfer/slicer";
import bankSourcesSlicer from "./bank-source/slicer";
import blogSlicer from "./blog/slicer";
import blogDetailSlicer from "./blog-detail/slicer";
import blogRedirectionSlicer from "./blog-redirection/slicer";
import buySellVolumeSlicer from "./buy-sell-volume/slicer";
import careerDetailSlicer from "./career-detail/slicer";
import careersSlicer from "./careers/slicer";
import chartSlicer from "./chart/slicer";
import coinsSlicer from "./coins/slicer";
import competitionLeaderboardSlicer from "./competition-leaderboard/slicer";
import coinInfoSlicer from "./coin-info/slicer";
import coinInformationSlicer from "./coin-information/slicer";
import coinInsightsSlice from "./coin-insights/slicer";
import coinNetworkSlicer from "./coin-network/slicer";
import coinWalletBookSlicer from "./coin-wallet-book/slicer";
import competitionSlicer from "./competitions/slicer";
import contentOverlaySlice from "./content-overlay/slicer";
import countryCodes from "./country-codes/slicer";
import currencySlicer from "./currency/slicer";
import eWalletsSlicer from "./e-wallet/slicer";
import ewalletUserSlicer from "./e-wallet-user/slicer";
import familyStatusInformationSlice from "./family-generate/slicer";
import familyOwnerDataSlice from "./family-owner-data/slicer";
import familyMemberDataSlice from "./family-member-data/slicer";
import faqTradeSlicer from "./faq-trade/slicer";
import feeWdSlicer from "./fee-wd/slicer";
import forgetPasswordSlice from "./forget-password/slicer";
import lightningCoinFees from "./lightning-coin-fees/slicer";
import lostAuthSlicer from "./lost-auth/slicer";
import marketTransactionsSlicer from "./market-transactions/slicer";
import globalLoadingSlice from "./global-loading/slicer";
import googleUriSlicer from "./google/slicer";
import feeSendCoinSlicer from "./fee-send-coin/slicer";
import depthChartSlicer from "./depth-chart/slicer";
import emailConfirmationSlice from "./email-confirmation/slicer";
import errorSlicer from "./error/slicer";
import kycStatusSlice from "./kyc-status/slicer";
import campusSlicer from "./campus/slicer";
import marketsSlicer from "./markets/slicer";
import marketStockSlicer from "./market-stock/slicer";
import missionReferrerConfigSlicer from "./mission-referrer-config/slicer";
import navigationSlicer from "./navigation/slicer";
import notificationsSlicer from "./notifications/slicer";
import onPageSeoSlicer from "./on-page-seo/slicer";
import orderHistorySlicer from "./order-history/slicer";
import orderDetailSlicer from "./order-detail/slicer";
import otcChartSlicer from "./otc-chart/slicer";
import otcFeedsSlicer from "./otc-feeds/slicer";
import otcHistorySlicer from "./otc-history/slicer";
import simpleOrderSlicer from "./simple-order/slicer";
import simulationChartCryptoSlice from "./simulation-chart-crypto/slicer";
import simulationChartStockSlice from "./simulation-chart-stock/slicer";
import otcSettingsSlicer from "./otc-settings/slicer";
import orderbookSlicer from "./orderbook/slicer";
import ourBanksSlicer from "./our-banks/slicer";
import otpVerifySlicer from "./otp-verify/slicer";
import pendingOrdersSlicer from "./pending-orders/slicer";
import personalityTestQuestionSlicer from "./personality-test-question/slicer";
import portfolioCoinPieChartSlice from "./portfolio-coin-pie-chart/slicer";
import portfolioCumulativeChartSlice from "./portfolio-cumulative-chart/slicer";
import portfolioSummaryBreakdownSlice from "./portfolio-summary-breakdown/slicer";
import portfolioSummaryChartSlice from "./portfolio-summary-chart/slicer";
import portfolioSummarySlice from "./portfolio-summary/slicer";
import portfolioPNLSummarySlice from "./portfolio-pnl-summary/slicer";
import portfolioPNLCumulativeChartSlice from "./portfolio-pnl-cumulative-chart/slicer";
import portfolioPNLCalendar from "./portfolio-pnl-calendar/slicer";
import portfolioPNLPeriodicalChartSlice from "./portfolio-pnl-periodical-chart/slicer";
import portfolioEquitySummarySlice from "./portfolio-equity-summary/slicer";
import portfolioEquitySlice from "./portfolio-equity/slicer";
import portfolioAssetSummarySlice from "./portfolio-asset-summary/slicer";
import portfolioAssetChartSlice from "./portfolio-asset-chart/slicer";
import portfolioPNLHistorySlice from "./portfolio-pnl-history/slicer";
import portfolioPNLHistoryFilterSlice from "./portfolio-pnl-history-filter/slicer";
import pricesSlicer from "./prices/slicer";
import profileSlicer from "./profile/slicer";
import plCoinListSlicer from "./pl-coin-list/slicer";
import plCalendarBarSlicer from "./pl-calendar/slicer";
import plBreakdownSlicer from "./pl-breakdown/slicer";
import receiveCoinSlicer from "./receive-coin/slicer";
import recoveryPhoneSlice from "./recovery-phone/slicer";
import resetPasswordSlicer from "./reset-password/slicer";
import referralBalanceSlicer from "./referral-balance/slicer";
import referralDownlineSlicer from "./referral-donwline/slicer";
import referralCommissionSlicer from "./referral-commission/slicer";
import referralIdentitySlicer from "./referral-identity/slicer";
import sendCoinSlicer from "./send-coin/slicer";
import sendCoinFeeSlicer from "./send-coin-fee/slicer";
import sendSmsSlicer from "./send-sms/slicer";
import rekapSlicer from "./rekap/slicer";
import removeAccountSlicer from "./remove-account/slicer";
import settingsSlicer from "./settings/slicer";
import simpleTradePreferences from "./simple-trade-preferences/slicer";
import simpleTradeFormSlicer from "./simple-trade-form/slicer";
import stakingBalancesSlicer from "./staking-balances/slicer";
import stakingBalancesMemberSlice from "./staking-balances-member/slicer";
import stakingComingsoonSlicer from "./staking-comingsoon/slicer";
import stakingProductsSlicer from "./staking-product/slicer";
import sendOtpEmailSlicer from "./send-otp-email/slicer";
import stockChartSlicer from "./stock-chart/slicer";
import taxReportSlice from "./tax-report/slicer";
import transactionsSlicer from "./transactions/slicer";
import userAgentSlicer from "./user-agent/slicer";
import userModeSlicer from "./user-mode/slicer";
import userSettingsSlice from "./user-settings/slicer";
import tradePreferences from "./trade-preferences/slicer";
import userPreferencesSlicer from "./user-preferences/slicer";
import virtualAccountsSlicer from "./virtual-account/slicer";
import walletBooksSlicer from "./wallet-book/slicer";
import walletDetailSlicer from "./wallet-detail/slicer";
import walletHistorySlicer from "./wallet-history/slicer";
import walletPreferencesSlicer from "./wallet-preferences/slicer";
import watchlistSlicer from "./watchlist/slicer";
import wdInternalTfSlicer from "./wdinternaltf/slicer";
import phoneNumberSlicer from "./phonenumber/slicer";
import walletUserSlicer from "./wallet-user/slicer";
import statusAuthSlicer from "./status-auth/slicer";
import historyIdrSlicer from "./history-idr/slicer";
import historyAssetsSlicer from "./history-assets/slicer";
import historyBalanceIdr from "./history-balance/slicer";
import cancelInternalTfSlicer from "./cancel-internaltransfer/slicer";
import detailtradeSlicer from "./detail-trade/slicer";
import cancelSendCoinSlicer from "./cancel-coin/slicer";
import disableAuthSlicer from "./disable-auth/slicer";
import generateTwoFaSlicer from "./generate-2fa/slicer";
import submitTwoFaSlicer from "./submit-twofa/slicer";
import changePasswordSlicer from "./change-password/slicer";
import verifyPhoneSlicer from "./verify-phone/slicer";
import validationAddressSlicer from "./validation-address/slicer";
import removePhoneSlicer from "./remove-phone/slicer";
import setPrimaryPhoneSlicer from "./primary-phone/slicer";
import paymentGuideSlicer from "./deposit-transfer/slicer";
import PlacementAnnouncementSlicer from "./placement-announcement/slicer";
import missionPollingSlicer from "./mission-polling/slicer";
import investorPersonalityProfileSlicer from "./investor-personality/slicer";
import portfolioSummaryBarSlice from "./portfolio-bar-chart/slicer";
const reducers = combineReducers({
  [adsSlicer.name]: adsSlicer.reducer,
  [analysisSlicer.name]: analysisSlicer.reducer,
  [announcementSlicer.name]: announcementSlicer.reducer,
  [authSlicer.name]: authSlicer.reducer,
  [apiKeysSlicer.name]: apiKeysSlicer.reducer,
  [balancesSlicer.name]: balancesSlicer.reducer,
  [balancesMemberSlice.name]: balancesMemberSlice.reducer,
  [balancesStockSlicer.name]: balancesStockSlicer.reducer,
  [banksSlicer.name]: banksSlicer.reducer,
  [bankUserSlicer.name]: bankUserSlicer.reducer,
  [bankTransferSlicer.name]: bankTransferSlicer.reducer,
  [bankSourcesSlicer.name]: bankSourcesSlicer.reducer,
  [blogSlicer.name]: blogSlicer.reducer,
  [blogDetailSlicer.name]: blogDetailSlicer.reducer,
  [blogRedirectionSlicer.name]: blogRedirectionSlicer.reducer,
  [buySellVolumeSlicer.name]: buySellVolumeSlicer.reducer,
  [careerDetailSlicer.name]: careerDetailSlicer.reducer,
  [careersSlicer.name]: careersSlicer.reducer,
  [chartSlicer.name]: chartSlicer.reducer,
  [coinWalletBookSlicer.name]: coinWalletBookSlicer.reducer,
  [coinsSlicer.name]: coinsSlicer.reducer,
  [competitionLeaderboardSlicer.name]: competitionLeaderboardSlicer.reducer,
  [coinInfoSlicer.name]: coinInfoSlicer.reducer,
  [coinInformationSlicer.name]: coinInformationSlicer.reducer,
  [coinInsightsSlice.name]: coinInsightsSlice.reducer,
  [coinNetworkSlicer.name]: coinNetworkSlicer.reducer,
  [competitionSlicer.name]: competitionSlicer.reducer,
  [contentOverlaySlice.name]: contentOverlaySlice.reducer,
  [countryCodes.name]: countryCodes.reducer,
  [currencySlicer.name]: currencySlicer.reducer,
  [eWalletsSlicer.name]: eWalletsSlicer.reducer,
  [ewalletUserSlicer.name]: ewalletUserSlicer.reducer,
  [errorSlicer.name]: errorSlicer.reducer,
  [familyStatusInformationSlice.name]: familyStatusInformationSlice.reducer,
  [familyOwnerDataSlice.name]: familyOwnerDataSlice.reducer,
  [familyMemberDataSlice.name]: familyMemberDataSlice.reducer,
  [faqTradeSlicer.name]: faqTradeSlicer.reducer,
  [feeWdSlicer.name]: feeWdSlicer.reducer,
  [forgetPasswordSlice.name]: forgetPasswordSlice.reducer,
  [lightningCoinFees.name]: lightningCoinFees.reducer,
  [lostAuthSlicer.name]: lostAuthSlicer.reducer,
  [marketTransactionsSlicer.name]: marketTransactionsSlicer.reducer,
  [depthChartSlicer.name]: depthChartSlicer.reducer,
  [emailConfirmationSlice.name]: emailConfirmationSlice.reducer,
  [campusSlicer.name]: campusSlicer.reducer,
  [globalLoadingSlice.name]: globalLoadingSlice.reducer,
  [googleUriSlicer.name]: googleUriSlicer.reducer,
  [feeSendCoinSlicer.name]: feeSendCoinSlicer.reducer,
  [kycStatusSlice.name]: kycStatusSlice.reducer,
  [marketsSlicer.name]: marketsSlicer.reducer,
  [marketStockSlicer.name]: marketStockSlicer.reducer,
  [missionPollingSlicer.name]: missionPollingSlicer.reducer,
  [missionReferrerConfigSlicer.name]: missionReferrerConfigSlicer.reducer,
  [navigationSlicer.name]: navigationSlicer.reducer,
  [notificationsSlicer.name]: notificationsSlicer.reducer,
  [onPageSeoSlicer.name]: onPageSeoSlicer.reducer,
  [orderDetailSlicer.name]: orderDetailSlicer.reducer,
  [orderHistorySlicer.name]: orderHistorySlicer.reducer,
  [otcChartSlicer.name]: otcChartSlicer.reducer,
  [otcFeedsSlicer.name]: otcFeedsSlicer.reducer,
  [otcHistorySlicer.name]: otcHistorySlicer.reducer,
  [simpleOrderSlicer.name]: simpleOrderSlicer.reducer,
  [simulationChartCryptoSlice.name]: simulationChartCryptoSlice.reducer,
  [simulationChartStockSlice.name]: simulationChartStockSlice.reducer,
  [otcSettingsSlicer.name]: otcSettingsSlicer.reducer,
  [orderbookSlicer.name]: orderbookSlicer.reducer,
  [ourBanksSlicer.name]: ourBanksSlicer.reducer,
  [otpVerifySlicer.name]: otpVerifySlicer.reducer,
  [pendingOrdersSlicer.name]: pendingOrdersSlicer.reducer,
  [personalityTestQuestionSlicer.name]: personalityTestQuestionSlicer.reducer,
  [portfolioCoinPieChartSlice.name]: portfolioCoinPieChartSlice.reducer,
  [portfolioCumulativeChartSlice.name]: portfolioCumulativeChartSlice.reducer,
  [portfolioSummaryBreakdownSlice.name]: portfolioSummaryBreakdownSlice.reducer,
  [portfolioSummaryChartSlice.name]: portfolioSummaryChartSlice.reducer,
  [portfolioSummarySlice.name]: portfolioSummarySlice.reducer,
  [portfolioPNLSummarySlice.name]: portfolioPNLSummarySlice.reducer,
  [portfolioPNLCumulativeChartSlice.name]: portfolioPNLCumulativeChartSlice.reducer,
  [portfolioPNLCalendar.name]: portfolioPNLCalendar.reducer,
  [portfolioPNLPeriodicalChartSlice.name]: portfolioPNLPeriodicalChartSlice.reducer,
  [portfolioEquitySummarySlice.name]: portfolioEquitySummarySlice.reducer,
  [portfolioEquitySlice.name]: portfolioEquitySlice.reducer,
  [portfolioAssetSummarySlice.name]: portfolioAssetSummarySlice.reducer,
  [portfolioAssetChartSlice.name]: portfolioAssetChartSlice.reducer,
  [portfolioPNLHistorySlice.name]: portfolioPNLHistorySlice.reducer,
  [portfolioPNLHistoryFilterSlice.name]: portfolioPNLHistoryFilterSlice.reducer,
  [pricesSlicer.name]: pricesSlicer.reducer,
  [profileSlicer.name]: profileSlicer.reducer,
  [plCoinListSlicer.name]: plCoinListSlicer.reducer,
  [plCalendarBarSlicer.name]: plCalendarBarSlicer.reducer,
  [plBreakdownSlicer.name]: plBreakdownSlicer.reducer,
  [receiveCoinSlicer.name]: receiveCoinSlicer.reducer,
  [recoveryPhoneSlice.name]: recoveryPhoneSlice.reducer,
  [resetPasswordSlicer.name]: resetPasswordSlicer.reducer,
  [referralBalanceSlicer.name]: referralBalanceSlicer.reducer,
  [referralDownlineSlicer.name]: referralDownlineSlicer.reducer,
  [referralCommissionSlicer.name]: referralCommissionSlicer.reducer,
  [referralIdentitySlicer.name]: referralIdentitySlicer.reducer,
  [sendCoinSlicer.name]: sendCoinSlicer.reducer,
  [sendSmsSlicer.name]: sendSmsSlicer.reducer,
  [rekapSlicer.name]: rekapSlicer.reducer,
  [removeAccountSlicer.name]: removeAccountSlicer.reducer,
  [settingsSlicer.name]: settingsSlicer.reducer,
  [simpleTradePreferences.name]: simpleTradePreferences.reducer,
  [simpleTradeFormSlicer.name]: simpleTradeFormSlicer.reducer,
  [stakingBalancesSlicer.name]: stakingBalancesSlicer.reducer,
  [stakingBalancesMemberSlice.name]: stakingBalancesMemberSlice.reducer,
  [stakingComingsoonSlicer.name]: stakingComingsoonSlicer.reducer,
  [stakingProductsSlicer.name]: stakingProductsSlicer.reducer,
  [sendOtpEmailSlicer.name]: sendOtpEmailSlicer.reducer,
  [stockChartSlicer.name]: stockChartSlicer.reducer,
  [advanceTradeFormSlicer.name]: advanceTradeFormSlicer.reducer,
  [taxReportSlice.name]: taxReportSlice.reducer,
  [transactionsSlicer.name]: transactionsSlicer.reducer,
  [userAgentSlicer.name]: userAgentSlicer.reducer,
  [userModeSlicer.name]: userModeSlicer.reducer,
  [userSettingsSlice.name]: userSettingsSlice.reducer,
  [tradePreferences.name]: tradePreferences.reducer,
  [userPreferencesSlicer.name]: userPreferencesSlicer.reducer,
  [virtualAccountsSlicer.name]: virtualAccountsSlicer.reducer,
  [walletBooksSlicer.name]: walletBooksSlicer.reducer,
  [walletDetailSlicer.name]: walletDetailSlicer.reducer,
  [walletPreferencesSlicer.name]: walletPreferencesSlicer.reducer,
  [feeApi.reducerPath]: feeApi.reducer,
  [helpPostsApi.reducerPath]: helpPostsApi.reducer,
  [helpDetailPostApi.reducerPath]: helpDetailPostApi.reducer,
  [helpRedirectionApi.reducerPath]: helpRedirectionApi.reducer,
  [walletHistorySlicer.name]: walletHistorySlicer.reducer,
  [walletUserSlicer.name]: walletUserSlicer.reducer,
  [watchlistSlicer.name]: watchlistSlicer.reducer,
  [wdInternalTfSlicer.name]: wdInternalTfSlicer.reducer,
  [sendCoinFeeSlicer.name]: sendCoinFeeSlicer.reducer,
  [phoneNumberSlicer.name]: phoneNumberSlicer.reducer,
  [statusAuthSlicer.name]: statusAuthSlicer.reducer,
  [historyIdrSlicer.name]: historyIdrSlicer.reducer,
  [historyAssetsSlicer.name]: historyAssetsSlicer.reducer,
  [historyBalanceIdr.name]: historyBalanceIdr.reducer,
  [cancelInternalTfSlicer.name]: cancelInternalTfSlicer.reducer,
  [detailtradeSlicer.name]: detailtradeSlicer.reducer,
  [cancelSendCoinSlicer.name]: cancelSendCoinSlicer.reducer,
  [disableAuthSlicer.name]: disableAuthSlicer.reducer,
  [generateTwoFaSlicer.name]: generateTwoFaSlicer.reducer,
  [submitTwoFaSlicer.name]: submitTwoFaSlicer.reducer,
  [changePasswordSlicer.name]: changePasswordSlicer.reducer,
  [verifyPhoneSlicer.name]: verifyPhoneSlicer.reducer,
  [validationAddressSlicer.name]: validationAddressSlicer.reducer,
  [removePhoneSlicer.name]: removePhoneSlicer.reducer,
  [setPrimaryPhoneSlicer.name]: setPrimaryPhoneSlicer.reducer,
  [paymentGuideSlicer.name]: paymentGuideSlicer.reducer,
  [PlacementAnnouncementSlicer.name]: PlacementAnnouncementSlicer.reducer,
  [investorPersonalityProfileSlicer.name]: investorPersonalityProfileSlicer.reducer,
  [portfolioSummaryBarSlice.name]: portfolioSummaryBarSlice.reducer
});
export default reducers;