import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPNlCalendarAction } from "./actions";
const portfolioPNLCalendar = createSlice({
  name: "pnlCalendar",
  initialState: {
    isLoading: false,
    pnlCalendar: [] as PortfolioPNLCalendarModel[]
  },
  reducers: {},
  extraReducers: {
    [fetchPNlCalendarAction.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchPNlCalendarAction.fulfilled.type]: (state, action: PayloadAction<PortfolioPNLCalendarAPI[]>) => {
      state.pnlCalendar = action.payload.map(item => ({
        amount: item.a,
        percentage: item.p,
        time: item.t
      }));
      state.isLoading = false;
    },
    [fetchPNlCalendarAction.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default portfolioPNLCalendar;