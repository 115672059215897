import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchWalletBooks } from "./actions";
const walletBooksSlicer = createSlice({
  name: "walletBooks",
  initialState: {
    walletBooks: null as WalletBookModel[] | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchWalletBooks.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchWalletBooks.fulfilled.type]: (state, action: PayloadAction<WalletBookAPI[]>) => {
      state.walletBooks = action.payload.map(walletbook => ({
        id: Number(walletbook.id),
        address: walletbook.address,
        walletName: walletbook.walletname
      }));
      state.isLoading = false;
    }
  }
});
export default walletBooksSlicer;