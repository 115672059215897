import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCoinInformation } from "./actions";
const coinInformationSlicer = createSlice({
  name: "coinInformation",
  initialState: {
    coinInformation: null as CoinInformationModel | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchCoinInformation.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchCoinInformation.fulfilled.type]: (state, action: PayloadAction<CoinInformationApi>) => {
      const coinCode = action.payload.coin_code;
      state.coinInformation = {
        ...state.coinInformation,
        [coinCode]: {
          id: action.payload.id,
          coinId: action.payload.coin_id,
          coinCode: action.payload.coin_code,
          coinName: action.payload.coin_name,
          detail: action.payload.detail,
          circulatingSupply: action.payload.circulating_supply,
          maximumSupply: action.payload.maximum_supply,
          supplyUpdated: action.payload.supply_updated_at,
          totalSupply: action.payload.total_supply
        }
      };
      state.isLoading = false;
    },
    [fetchCoinInformation.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default coinInformationSlicer;