/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { fetchNotifications, fetchNotificationsPagination, fetchTotalNotifications, offset } from "@redux/notifications/action";
import { TotalSocket, updateNotif } from "@redux/notifications/slicer";
import useAuth from "./useAuth";
const useNotifications = () => {
  const {
    notifications,
    isLoading
  } = useAppSelector(state => state.notifications);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const checkHasMore = (length: number) => {
    if (length < offset) {
      return false;
    }
    return true;
  };
  const onCloseModal = async () => {
    setPage(1);
  };
  const onNext = async () => {
    setPage(page + 1);
    const res = await dispatch(fetchNotificationsPagination(page)).unwrap();
    setHasMore(checkHasMore(res.length));
  };
  const fetch = async () => {
    const res = await dispatch(fetchNotifications()).unwrap();
    setHasMore(checkHasMore(res.length));
  };
  return {
    notifications,
    fetchNotifications: fetch,
    isLoading,
    hasMore,
    onNext,
    onCloseModal
  };
};
export const useTotalNotifications = () => {
  const {
    total
  } = useAppSelector(state => state.notifications);
  const dispatch = useAppDispatch();
  const {
    auth
  } = useAuth();
  const fetch = () => {
    if (auth.isLoggedIn) {
      dispatch(fetchTotalNotifications());
    }
  };
  useEffect(() => {
    fetch();
    (async () => {
      const {
        default: Socket
      } = await import("@lib/socket");
      Socket.subscribe(`notif:${Socket.createToken(auth.token || "")}`, (updateData: TotalSocket) => {
        dispatch(updateNotif(updateData));
      });
    })();
  }, [dispatch]);
  return {
    total,
    fetchNotifications: fetch
  };
};
export default useNotifications;