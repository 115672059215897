import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPhoneNumber } from "./action";
const phoneNumberSlicer = createSlice({
  name: "phonenumber",
  initialState: {
    phoneNumber: null as PhoneNumberModel[] | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchPhoneNumber.pending.type]: state => {
      state.isLoading = true;
      state.phoneNumber = null;
    },
    [fetchPhoneNumber.fulfilled.type]: (state, action: PayloadAction<PhoneNumberApi[]>) => {
      state.phoneNumber = action.payload.map(data => ({
        id: data.id,
        phone: data.phone_number,
        isPrimary: data.is_primary,
        status: data.status
      }));
      state.isLoading = false;
    },
    [fetchPhoneNumber.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default phoneNumberSlicer;