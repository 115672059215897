import { memo, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Container from "@components/v4/Container";
import { Icons } from "@reku-web/ui";
import useScroll from "@hooks/useScroll";
import CollapseMenu from "./CollapseMenu";
import MenuCard, { MenuCardProps } from "./MenuCard";
import StockIllustration from "../images/StockIllustration";
import CryptoIllustration from "../images/CryptoIllustration";
interface InvestmentMenuProps {
  fixedNavbar: boolean;
  active?: boolean;
  setExpanded: (expanded: boolean) => void;
}
const InvestmentMenu: React.FC<InvestmentMenuProps> = ({
  fixedNavbar,
  active,
  setExpanded
}) => {
  const {
    t
  } = useTranslation("components");
  const [open, setOpen] = useState(false);
  const {
    scrollVertical
  } = useScroll();
  useEffect(() => {
    if (fixedNavbar) {
      setOpen(false);
    }
  }, [scrollVertical, fixedNavbar]);
  useEffect(() => {
    setExpanded(open);
  }, [open]);
  const menu: MenuCardProps[] = [{
    variant: "teal",
    list: [{
      href: "/crypto",
      icon: "CryptoTeal",
      label: "investment.crypto.title",
      description: "investment.crypto.description",
      dataTestId: "menuBar_assetCryptoMenuBtn",
      links: [{
        href: "/markets",
        label: "market",
        dataTestId: "menuBar_cryptoMarketMenuBtn"
      }, {
        href: "/trade/BTC-IDR",
        label: "trade",
        dataTestId: "menuBar_cryptoTradeMenuBtn"
      }]
    }],
    suffix: <CryptoIllustration className='scale-[1.11] mr-4 fill-background-subtle-teal dark:fill-dark-background-subtle-teal text-[#AFE2E4] dark:text-[#087443]' />
  }, {
    variant: "purple",
    list: [{
      href: "/saham-as",
      icon: "StockPurple",
      label: "investment.stock.title",
      description: "investment.stock.description",
      dataTestId: "menuBar_usStockMenuBtn",
      links: [{
        href: "/markets/saham-as",
        label: "market",
        dataTestId: "menuBar_usStockMarketMenuBtn"
      }]
    }],
    suffix: <StockIllustration className='scale-[1.11] mr-4 fill-background-subtle-purple dark:fill-dark-background-subtle-purple text-[#DBCDF4] dark:text-[#562E9E]' />
  }];
  return <CollapseMenu open={open} onChange={setOpen} dataTestId='menuBar_InvestmentMenuBtn' overlay={<Container className='py-6'>
                    <div className='flex gap-8'>
                        {menu.map(({
        title,
        list,
        variant,
        suffix
      }) => <MenuCard className='!flex-1' navClassName='!flex-1' title={title} list={list} variant={variant} suffix={suffix} onClick={() => setOpen(false)} />)}
                    </div>
                </Container>}>
            <span className={classNames("reku-nav-item flex gap-[2px] items-center text-md font-bold text-text-neutral-main dark:text-dark-text-neutral-main hover:text-actions-primary-active dark:hover:text-dark-actions-primary-active", {
      "!text-actions-primary-active dark:!text-dark-actions-primary-active": active || open
    })}>
                {t("navbar.navs.investment.title")}
                <Icons icon='ChevronDown' width={20} height={20} className={classNames("transition-transform duration-300 rotate-0", {
        "!rotate-180": open
      })} />
            </span>
        </CollapseMenu>;
};
export default memo(InvestmentMenu);