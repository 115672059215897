import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TransactionPayload } from "@type/payload/transactions";

// eslint-disable-next-line @typescript-eslint/default-param-last
export const transactions = createAsyncThunk<TransactionsAPI, TransactionPayload>("transactions/transactions", async (payload, {
  rejectWithValue
}) => {
  try {
    const response = await baseFetchApiV3<TransactionsAPI>({
      url: "/transactions",
      method: "POST",
      data: payload
    });
    return response;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});