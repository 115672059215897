import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchEquityAction } from "./actions";
import { generateRandomColor } from "@utils/colors";
const portfolioEquitySlice = createSlice({
  name: "equity",
  initialState: {
    equity: null as PortfolioEquityModel[] | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchEquityAction.pending.type]: state => {
      state.equity = null;
      state.isLoading = true;
    },
    [fetchEquityAction.fulfilled.type]: (state, action: PayloadAction<PortfolioEquityAPI[]>) => {
      state.equity = action.payload.map(equity => ({
        code: equity.code,
        name: equity.name,
        logo: equity.logo,
        qty: equity.qty,
        idrQty: equity.idr_qty,
        usdQty: equity.usd_qty,
        avgPrice: equity.avg_price,
        unrealizedIdr: equity.unrealized_idr,
        unrealizedUsd: equity.unrealized_usd,
        unrealizedPercentage: equity.unrealized_perc,
        investDate: equity.invest_date,
        allocation: equity.allocation,
        emoji: equity.emoji,
        backgroundColor: equity.background_color,
        colorLight: equity.color_light || generateRandomColor(),
        colorDark: equity.color_dark,
        pocketId: equity.pocket_id,
        breakdown: equity.breakdown?.map(breakdown => ({
          code: breakdown.code,
          name: breakdown.name,
          qty: breakdown.qty,
          idrQty: breakdown.idr_qty,
          usdQty: breakdown.usd_qty,
          avgPrice: breakdown.avg_price,
          unrealizedIdr: breakdown.unrealized_idr,
          unrealizedUsd: breakdown.unrealized_usd,
          unrealizedPercentage: breakdown.unrealized_perc,
          investDate: breakdown.invest_date,
          emoji: breakdown.emoji,
          backgroundColor: breakdown.background_color,
          logo: breakdown.logo,
          pocketId: breakdown.pocket_id
        })) ?? null
      }));
      state.isLoading = false;
    }
  }
});
export default portfolioEquitySlice;