import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPlBreakdown } from "./actions";
const plBreakdownSlicer = createSlice({
  name: "plBreakdown",
  initialState: {
    plBreakdown: null as PlBreakdownModel | null,
    plBreakdownLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchPlBreakdown.pending.type]: state => {
      state.plBreakdownLoading = true;
      state.plBreakdown = null;
    },
    [fetchPlBreakdown.fulfilled.type]: (state, action: PayloadAction<PlBreakdownAPI>) => {
      state.plBreakdownLoading = false;
      state.plBreakdown = {
        unrealizedAmount: action.payload.unrealized_amount,
        realized: {
          assetReward: action.payload.realized.asset_reward,
          assetSales: action.payload.realized.asset_sales,
          stakingReward: action.payload.realized.staking_reward,
          idrReward: action.payload.realized.idr_reward,
          total: action.payload.realized.total
        }
      };
    },
    [fetchPlBreakdown.rejected.type]: state => {
      state.plBreakdownLoading = false;
      state.plBreakdown = null;
    }
  }
});
export default plBreakdownSlicer;