/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { isBrowser } from "@utils/browser";
export const fetchCareers = createAsyncThunk<CareersAPI[]>("careers/fetchCareers", async () => {
  if (isBrowser) {
    const response = await axios.get("/api/careers");
    return response.data;
  }
  const {
    getCareers
  } = await import("@pages/api/careers");
  const careers = await getCareers();
  return careers;
});