import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAssetChartAction } from "./actions";
export const emptyAssetDetail = {
  timestamp: 0,
  amount: 0,
  cashAmount: 0,
  unrealizedAmount: 0,
  unrealizedPercentage: 0
};
const portfolioAssetChartSlice = createSlice({
  name: "assetChart",
  initialState: {
    assetChart: null as PortfolioAssetChartModel[] | null,
    assetDetail: null as PortfolioAssetChartModel | null,
    isLoading: true
  },
  reducers: {
    setHoverData: (state, action: PayloadAction<PortfolioAssetChartModel | null>) => {
      state.assetDetail = action.payload;
    }
  },
  extraReducers: {
    [fetchAssetChartAction.pending.type]: state => {
      state.assetChart = null;
      state.assetDetail = null;
      state.isLoading = true;
    },
    [fetchAssetChartAction.fulfilled.type]: (state, action: PayloadAction<PortfolioAssetChartAPI[]>) => {
      state.assetChart = action.payload.map(chart => ({
        timestamp: chart.t,
        totalAmount: chart.ta,
        assetAmount: chart.a,
        cashAmount: chart.c,
        unrealizedAmount: chart.ua,
        unrealizedAssetPercentage: chart.uap,
        unrealizedTotal: chart.u,
        unrealizedPercentage: chart.up
      }));
      const lastPayload = action.payload[action.payload.length - 1];
      if (lastPayload) {
        state.assetDetail = {
          timestamp: lastPayload.t,
          totalAmount: lastPayload.ta,
          assetAmount: lastPayload.a,
          cashAmount: lastPayload.c,
          unrealizedAmount: lastPayload.ua,
          unrealizedAssetPercentage: lastPayload.uap,
          unrealizedTotal: lastPayload.u,
          unrealizedPercentage: lastPayload.up
        };
      }
      state.isLoading = false;
    }
  }
});
export default portfolioAssetChartSlice;
export const {
  setHoverData
} = portfolioAssetChartSlice.actions;