import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchBlogRedirection } from "./actions";
const blogRedirectionSlicer = createSlice({
  name: "blogRedirection",
  initialState: {
    blogRedirection: null as BlogRedirectionAPI | null,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchBlogRedirection.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchBlogRedirection.fulfilled.type]: (state, action: PayloadAction<BlogRedirectionAPI>) => {
      state.blogRedirection = action.payload;
      state.isLoading = false;
    }
  }
});
export default blogRedirectionSlicer;