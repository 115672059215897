import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPlCoinList } from "./actions";
const plCoinListSlicer = createSlice({
  name: "plCoinList",
  initialState: {
    plCoinList: null as PlCoinListModel[] | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchPlCoinList.pending.type]: state => {
      state.isLoading = true;
      state.plCoinList = null;
    },
    [fetchPlCoinList.fulfilled.type]: (state, action: PayloadAction<PlCoinListApi[]>) => {
      state.isLoading = false;
      state.plCoinList = action.payload.map(item => ({
        coinColor: item.coin_color,
        coinCode: item.coin_code,
        coinName: item.coin_name,
        amount: item.amount,
        amountCoin: item.amount_coin,
        avgPrice: item.avg_price,
        plAmount: item.pl_amount,
        plPerc: item.pl_perc,
        alloc: item.alloc,
        date: item.date
      }));
    },
    [fetchPlCoinList.rejected.type]: state => {
      state.isLoading = false;
      state.plCoinList = null;
    }
  }
});
export default plCoinListSlicer;