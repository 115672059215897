import React, { memo, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import OutsideClickHandler from "react-outside-click-handler";
import { Transition, TransitionStatus } from "react-transition-group";
type Placement = "bottomLeft" | "bottomRight";
interface DropdownRect {
  top: number;
  left?: number;
  right?: number;
  placement: Placement;
}
const marginAnimate = (animationState: TransitionStatus) => {
  switch (animationState) {
    case "entering":
      return "-10px";
    case "entered":
      return "0px";
    case "exiting":
      return "-10px";
    case "exited":
      return "-10px";
    default:
      return "-10px";
  }
};
const opacityAnimate = (animationState: TransitionStatus) => {
  switch (animationState) {
    case "entering":
      return 0;
    case "entered":
      return 1;
    case "exiting":
      return 0;
    case "exited":
      return 0;
    default:
      return 0;
  }
};
interface DropdownMenuProps extends React.PropsWithChildren {
  open?: boolean;
  onChange?: (open: boolean) => void;
  width?: number;
  placement?: Placement;
  overlay: React.ReactNode;
  top?: number;
  right?: number;
  dataTestId?: string;
}
const DropdownMenu: React.FC<DropdownMenuProps> = ({
  open: openProp,
  onChange,
  width,
  placement,
  children,
  overlay,
  top = 0,
  right = 0,
  dataTestId
}) => {
  const router = useRouter();
  const [open, setOpen] = useState(openProp);
  const [rect, setRect] = useState<DropdownRect>();
  const elementRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handlePosition = () => {
    const parentRect = elementRef.current?.getBoundingClientRect();
    let newRect: DropdownRect = {
      top: top + parentRect.height + 16,
      placement: placement ?? "bottomLeft"
    };
    switch (placement) {
      case "bottomLeft":
        newRect = {
          ...newRect,
          left: 0
        };
        break;
      case "bottomRight":
        newRect = {
          ...newRect,
          right
        };
        break;
      default:
        break;
    }
    setRect(newRect);
  };
  useEffect(() => {
    handlePosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);
  const handleClose = () => {
    setOpen(false);
    onChange?.(false);
  };
  const handleToggle = () => {
    setOpen(!open);
    onChange?.(!open);
  };
  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);
  return <div className='relative reku-new-theme'>
            <OutsideClickHandler onOutsideClick={() => handleClose()}>
                <div className='relative' ref={elementRef} onClick={handleToggle} role='button' data-testid={dataTestId}>
                    {children}
                </div>
                <Transition in={open} timeout={200} unmountOnExit>
                    {state => <div className={"absolute transition-all duration-500 z-[99]"} style={{
          top: `${rect?.top || 0}px`,
          left: typeof rect?.left !== "undefined" ? `${rect.left}px` : "unset",
          right: typeof rect?.right !== "undefined" ? `${rect.right}px` : "unset",
          marginTop: marginAnimate(state),
          opacity: opacityAnimate(state)
        }}>
                            <div className={`relative bg-background-card-main dark:bg-dark-background-card-main border border-outline-neutral-main dark:border-dark-outline-neutral-main rounded-md shadow-header dark:shadow-dark-header`} style={{
            width: width ? `${width}px` : "max-content"
          }}>
                                {overlay}
                            </div>
                        </div>}
                </Transition>
            </OutsideClickHandler>
        </div>;
};
DropdownMenu.defaultProps = {
  open: false,
  onChange: () => {},
  width: 300,
  placement: "bottomLeft",
  top: 0,
  right: 0,
  dataTestId: "reku-dropdown"
};
export default memo(DropdownMenu);