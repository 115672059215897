import { createSlice } from "@reduxjs/toolkit";
import { fetchLightningCoinFees } from "./actions";
const lightningCoinFees = createSlice({
  name: "lightningCoinFees",
  initialState: {
    lightningCoinFees: null as LightningCoinFeeModel[] | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLightningCoinFees.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchLightningCoinFees.fulfilled, (state, action) => {
      state.lightningCoinFees = action.payload;
      state.isLoading = false;
    });
  }
});
export default lightningCoinFees;