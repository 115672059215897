/* eslint-disable import/prefer-default-export */
import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchMissionPolling = createAsyncThunk<MissionPollingAPI>("missionPolling/fetchMissionPolling", async () => {
  const response = await baseFetchApiV3<SuccessAPI<MissionPollingAPI>>({
    url: "/mission/polling/first-action",
    method: "GET"
  });
  if (!response.data) {
    throw new Error(response?.message);
  }
  return response.data;
});