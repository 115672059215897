import { memo, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import classNames from "classnames";
import Link from "@components/Link";
import Container from "@components/v4/Container";
import { REKU_RECAP } from "@const/moengage-analytic-event";
import { Icons } from "@reku-web/ui";
import useScroll from "@hooks/useScroll";
import CollapseMenu from "./CollapseMenu";
import MenuCard, { MenuCardProps } from "./MenuCard";
interface LearningHubMenuProps {
  fixedNavbar: boolean;
  active?: boolean;
  setExpanded: (expanded: boolean) => void;
}
const LearningHubMenu: React.FC<LearningHubMenuProps> = ({
  fixedNavbar,
  active,
  setExpanded
}) => {
  const {
    t
  } = useTranslation("components");
  const [open, setOpen] = useState(false);
  const {
    scrollVertical
  } = useScroll();
  useEffect(() => {
    if (fixedNavbar) {
      setOpen(false);
    }
  }, [scrollVertical, fixedNavbar]);
  useEffect(() => {
    setExpanded(open);
  }, [open]);
  const menu: MenuCardProps = {
    title: "learning_hub.read_learn",
    variant: "teal",
    list: [{
      href: "/information",
      icon: "AnnotationInfo",
      label: "learning_hub.info.title",
      description: "learning_hub.info.description",
      dataTestId: "menuBar_rekuInfoMenuBtn"
    }, {
      href: "/analysis",
      icon: "BarLineChart",
      label: "learning_hub.analysis.title",
      description: "learning_hub.analysis.description",
      dataTestId: "menuBar_analysisMenuBtn"
    }, {
      href: "/campus",
      icon: "GraduationHat",
      label: "learning_hub.campus.title",
      description: "learning_hub.campus.description",
      dataTestId: "menuBar_rekuCampusMenuBtn"
    }]
  };
  const extraLinks = [{
    label: "help_center",
    link: "/help"
  }, {
    label: "trading_rules",
    link: "/trading-rule"
  }, {
    label: "faq",
    link: "/help"
  }, {
    label: "fees",
    link: "/fees"
  }, {
    label: "about_us",
    link: "/about"
  }];
  return <CollapseMenu open={open} onChange={setOpen} dataTestId='menuBar_learningHubMenuBtn' overlay={<Container className='py-6'>
                    <div className='flex gap-[64px]'>
                        <div className='flex-1'>
                            {<MenuCard title={menu.title} list={menu.list} variant='teal' onClick={() => setOpen(false)} />}
                        </div>
                        <div className='grid grid-cols-2 gap-4 w-[292px] h-fit'>
                            {extraLinks.map(({
          label,
          link
        }) => <Link href={link} className='!w-fit text-lg font-bold text-text-neutral-strong dark:text-dark-text-neutral-strong hover:text-actions-primary-hover dark:hover:text-dark-actions-primary-hover'>
                                    {t(`navbar.navs.${label}`)}
                                </Link>)}
                        </div>
                    </div>
                </Container>}>
            <span className={classNames("reku-nav-item flex gap-[2px] items-center text-md font-bold text-text-neutral-main dark:text-dark-text-neutral-main hover:text-actions-primary-active dark:hover:text-dark-actions-primary-active", {
      "!text-actions-primary-active dark:!text-dark-actions-primary-active": active || open
    })}>
                {t("navbar.navs.learning_hub.title")}
                <Icons icon='ChevronDown' width={20} height={20} className={classNames("transition-transform duration-300 rotate-0", {
        "!rotate-180": open
      })} />
            </span>
        </CollapseMenu>;
};
export default memo(LearningHubMenu);