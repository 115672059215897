/* eslint-disable import/prefer-default-export */
export const APP_VERSION = "3.0.4";
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const API_V3_URL = process.env.NEXT_PUBLIC_API_V3_URL;
export const API_V1_URL = process.env.NEXT_PUBLIC_API_V1_URL;
export const PUBLIC_URL = process.env.NEXT_PUBLIC_URL || "";
export const PUBLIC_ASSETS_URL = `${PUBLIC_URL}/next/`;
export const BRAND_LOGO_URL = `${PUBLIC_ASSETS_URL}assets/images/logo/reku.png`;
export const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
export const COOKIE_PREFIX = process.env.NEXT_PUBLIC_COOKIE_PREFIX;
export const HELP_API_URL = "https://help.reku.id/wp-json/wp/v2/";
export const BLOG_API_URL = "https://api-blog.reku.id/wp-json/wp/v2/";
export const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
export const FIREBASE_DATABASE_URL = process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL;
export const PRODUCT_API_URL = "https://api-beta.rekeningku.net/v2/";
export const WS_URL = process.env.NEXT_PUBLIC_WS_URL;
export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
export const APPLE_CLIENT_ID = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID;
export const APPLE_REDIRECT_URI = process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI;
export const MOENGAGE_APP_ID = process.env.NEXT_PUBLIC_MOENGAGE_APP_ID;
export const MOENGAGE_DEBUG = process.env.NEXT_PUBLIC_MOENGAGE_DEBUG;
export const ENABLE_SSR = process.env.NEXT_PUBLIC_ENABLE_SSR ? Boolean(Number(process.env.NEXT_PUBLIC_ENABLE_SSR)) : true;
export const MOBILE_WEB_URL = process.env.NEXT_PUBLIC_MOBILE_WEB_URL;
export const GENERATE_SITEMAP = process.env.NEXT_GENERATE_SITEMAP;
export const SHARED_KEY = "567598ed-b4ee-448b-8699-a849863ef5b2";
export const KYC_REMINDER = process.env.NEXT_PUBLIC_KYC_REMINDER ? Boolean(Number(process.env.NEXT_PUBLIC_KYC_REMINDER)) : false;
export const ROOT_DOMAIN_HOST = process.env.NEXT_PUBLIC_ROOT_HOST;
export const TRANSPARANSI_DOMAIN_HOST = process.env.NEXT_PUBLIC_TRANSPARANSI_HOST;