import { Icons } from "@reku-web/ui";
import { useTheme } from "@emotion/react";
import { UserStatus } from "@type/model/profile";
interface UserStatusIconProps {
  /**
   * The information regarding user status is status 0 for Unverified, status 8 for Waiting Verification, otherwise it will be considered Verified User.
   */
  status: UserStatus;
}
const UserStatusIcon: React.FC<UserStatusIconProps> = ({
  status
}: UserStatusIconProps) => {
  const theme = useTheme();
  const lightMode = theme.mode === "light";
  if (status === UserStatus.Unverified || status === UserStatus.Active) {
    return <Icons width={20} height={20} icon={lightMode ? "UnverifiedLight" : "UnverifiedDark"} />;
  }
  if (status === UserStatus.Pending) {
    return <Icons width={20} height={20} icon={lightMode ? "WaitingLight" : "WaitingDark"} />;
  }
  return <Icons width={20} height={20} icon='CheckVerified' className='reku-new-theme text-icon-success dark:text-dark-icon-success' />;
};
export default UserStatusIcon;