import { useState } from "react";
import { useRouter } from "next/router";
import { LanguageSwitch as LanguageSwitchComponent } from "@components/v4/Switch";
import useLang from "@hooks/useLang";
const LanguageSwitch = () => {
  const router = useRouter();
  const slug = (router.query?.slug || "") as string;
  const [checked, setChecked] = useState(router.locale === "id");
  const {
    updateLang
  } = useLang();
  const handleChange = () => {
    setChecked(!checked);
    const lang = checked ? "en" : "id";
    updateLang({
      lang
    });
    let newPath = router.asPath;
    const isMultilangArticle = slug.endsWith("-id") || slug.endsWith("-en");
    const helpPage = router.pathname === "/help/[slug]";
    if (isMultilangArticle && helpPage) {
      newPath = `/help/${slug.slice(0, -2)}${lang}`;
    }
    setTimeout(() => {
      router.replace(newPath, newPath, {
        locale: lang
      });
    }, 300);
  };
  return <div className='flex items-center order-1'>
            <LanguageSwitchComponent size='md' active={checked} onChange={handleChange} />
        </div>;
};
export default LanguageSwitch;