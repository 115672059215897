import { resetError, setError } from "@redux/error/slicer";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
const useError = () => {
  const error = useAppSelector(state => state.error);
  const dispatch = useAppDispatch();
  const setErrorData = (payload: IError) => dispatch(setError(payload));
  const resetErrorData = () => dispatch(resetError());
  return {
    error,
    setError: setErrorData,
    resetError: resetErrorData
  };
};
export default useError;