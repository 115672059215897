/* eslint-disable simple-import-sort/imports */
import { configureStore } from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import { persistReducer, persistStore, createMigrate, Persistor } from "redux-persist";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import { nextReduxCookieMiddleware, wrapMakeStore } from "next-redux-cookie-wrapper";
import { isBrowser } from "@utils/browser";
import localStorage from "@utils/localStorage";
import isDevelopment from "@utils/isDevelopment";
import middlewares from "./middlewares";
import reducers from "./reducers";
import storage from "./storage";
import userPreferencesSlicer from "./user-preferences/slicer";
import migrations from "./persist-migrations";
import { logout } from "./auth/action";
import resetPasswordSlicer from "./reset-password/slicer";
import recoveryPhoneSlice from "./recovery-phone/slicer";
import watchlistSlicer from "./watchlist/slicer";
import userModeSlicer from "./user-mode/slicer";
import { updateUserMode } from "./user-mode/actions";
import navigationSlicer from "./navigation/slicer";
import storeRegistry from "./registry";
import profileSlicer from "./profile/slicer";
import referralIdentitySlicer from "./referral-identity/slicer";
import missionPollingSlicer from "./mission-polling/slicer";
const combinedMiddleware = [...middlewares];
if (isBrowser) {
  combinedMiddleware.push(createStateSyncMiddleware({
    whitelist: [userPreferencesSlicer.actions.changeUserPreference.type, logout.fulfilled.type, updateUserMode.fulfilled.type, missionPollingSlicer.actions.hideMissionPolling.type, missionPollingSlicer.actions.setTimeoutId.type]
  }));
}
const makeConfiguredStore = <T extends typeof reducers,>(reducer: T) => configureStore({
  reducer,
  devTools: isDevelopment,
  middleware: getDefaultMiddlewares => getDefaultMiddlewares({
    serializableCheck: process.env.NODE_ENV === "test" ? false : undefined
  }).concat(combinedMiddleware).prepend(nextReduxCookieMiddleware({
    subtrees: [`${watchlistSlicer.name}.watchlist`, `${watchlistSlicer.name}.stockWatchlist`, `${profileSlicer.name}.profile.email`, `${profileSlicer.name}.profile.userId`]
  }))
});
interface IPersistStore extends ReturnType<typeof makeConfiguredStore> {
  __persistor?: Persistor;
}
export const makeStore = wrapMakeStore((): IPersistStore => {
  if (isBrowser) {
    localStorage.removeItem("persist:next");
    localStorage.removeItem("persist:root");
    const persistConfig = {
      key: "rekrek",
      version: 2,
      storage,
      migrate: createMigrate(migrations, {
        debug: false
      }),
      whitelist: [userPreferencesSlicer.name, resetPasswordSlicer.name, recoveryPhoneSlice.name, userModeSlicer.name, navigationSlicer.name, referralIdentitySlicer.name, missionPollingSlicer.name]
    };
    const persistedReducer = persistReducer(persistConfig, reducers);
    const store: IPersistStore = makeConfiguredStore(persistedReducer as any);

    // eslint-disable-next-line no-underscore-dangle
    store.__persistor = persistStore(store);
    initMessageListener(store);
    storeRegistry.register(store);
    return store;
  }
  const store = makeConfiguredStore(reducers);
  storeRegistry.register(store);
  return store;
});
export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
export const wrapper = createWrapper<AppStore>(makeStore);