import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchMissionReferrerConfig } from "./actions";
const missionReferrerConfigSlicer = createSlice({
  name: "missionReferrerConfig",
  initialState: {
    missionReferrerConfig: null as MissionReferrerConfigAPI | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchMissionReferrerConfig.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchMissionReferrerConfig.fulfilled.type]: (state, action: PayloadAction<MissionReferrerConfigAPI>) => {
      state.isLoading = false;
      state.missionReferrerConfig = action.payload;
    },
    [fetchMissionReferrerConfig.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default missionReferrerConfigSlicer;