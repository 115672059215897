import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface StockChartArgs {
  symbol: string;
  frame?: number; // in minute
  start?: number;
  end?: number;
  m?: string;
}
export const fetchStockChart = createAsyncThunk<(number | string)[][], StockChartArgs>("otcStock/fetchStockChart", async ({
  frame = 1440,
  symbol,
  end,
  start,
  m
}) => {
  const response = await baseFetchApiV3({
    url: `/us-stock/chart`,
    method: "GET",
    params: {
      f: frame,
      s: symbol,
      end,
      start,
      m
    }
  });
  return response.sort();
});