const AppStoreIconWhite = () => <svg width='84' height='24' viewBox='0 0 84 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M82.8314 21.2322C82.8314 22.5112 81.771 23.5472 80.4594 23.5472H2.83774C1.52682 23.5472 0.462738 22.5112 0.462738 21.2322V2.77084C0.462738 1.49245 1.52682 0.45282 2.83774 0.45282H80.4588C81.771 0.45282 82.8307 1.49245 82.8307 2.77084L82.8314 21.2322Z' fill='none' />
        <path d='M80.2091 0.480751C81.6375 0.480751 82.7997 1.611 82.7997 3V21C82.7997 22.389 81.6375 23.5193 80.2091 23.5193H3.08497C1.65663 23.5193 0.494366 22.389 0.494366 21V3C0.494366 1.611 1.65663 0.480751 3.08497 0.480751H80.2091ZM80.2091 1.90735e-06H3.08497C1.38901 1.90735e-06 0 1.35075 0 3V21C0 22.6493 1.38901 24 3.08497 24H80.2091C81.9051 24 83.2941 22.6493 83.2941 21V3C83.2941 1.35075 81.9051 1.90735e-06 80.2091 1.90735e-06Z' fill='none' />
        <path d='M18.5888 11.8704C18.5709 9.93662 20.217 8.99582 20.2923 8.95202C19.36 7.63022 17.915 7.44962 17.4072 7.43522C16.1936 7.31102 15.0164 8.14142 14.3982 8.14142C13.7676 8.14142 12.8156 7.44722 11.7895 7.46762C10.4691 7.48742 9.23392 8.23082 8.55646 9.38522C7.15835 11.739 8.20107 15.198 9.54057 17.1006C10.2106 18.0324 10.9936 19.0728 12.0184 19.0362C13.021 18.996 13.3955 18.4146 14.6055 18.4146C15.8043 18.4146 16.156 19.0362 17.2012 19.0128C18.2772 18.996 18.9546 18.0768 19.6013 17.1366C20.3756 16.0686 20.6866 15.0168 20.6989 14.9628C20.6736 14.9544 18.6091 14.1882 18.5888 11.8704Z' fill='white' />
        <path d='M16.6144 6.18362C17.1536 5.52782 17.5226 4.63562 17.4202 3.73022C16.6397 3.76382 15.6636 4.25522 15.1015 4.89662C14.6042 5.46182 14.16 6.38822 14.2748 7.25942C15.1515 7.32302 16.0517 6.82922 16.6144 6.18362Z' fill='white' />
        <path d='M30.2635 6.0054C30.2635 6.7116 30.0457 7.2432 29.6108 7.6002C29.2079 7.92961 28.6353 8.09461 27.8937 8.09461C27.5259 8.09461 27.2113 8.07901 26.9478 8.04781V4.1892C27.2915 4.1352 27.6617 4.1076 28.0615 4.1076C28.7679 4.1076 29.3004 4.257 29.6595 4.5558C30.0618 4.8936 30.2635 5.3766 30.2635 6.0054ZM29.5818 6.0228C29.5818 5.565 29.4571 5.214 29.2079 4.9692C28.9586 4.725 28.5946 4.6026 28.1152 4.6026C27.9116 4.6026 27.7382 4.6158 27.5944 4.6434V7.57681C27.674 7.58881 27.8196 7.5942 28.0313 7.5942C28.5261 7.5942 28.908 7.4604 29.177 7.19281C29.446 6.9252 29.5818 6.53521 29.5818 6.0228Z' fill='white' />
        <path d='M33.8785 6.62217C33.8785 7.05717 33.7508 7.41357 33.4954 7.69317C33.2276 7.98057 32.8728 8.12397 32.4298 8.12397C32.0028 8.12397 31.6629 7.98657 31.4093 7.71057C31.1563 7.43517 31.0298 7.08777 31.0298 6.66897C31.0298 6.23097 31.16 5.87157 31.4216 5.59257C31.6832 5.31357 32.0349 5.17377 32.4779 5.17377C32.9049 5.17377 33.2479 5.31117 33.5077 5.58657C33.7545 5.85417 33.8785 6.19977 33.8785 6.62217ZM33.2078 6.64257C33.2078 6.38157 33.1498 6.15777 33.0345 5.97117C32.8987 5.74557 32.7056 5.63277 32.4545 5.63277C32.1947 5.63277 31.9973 5.74557 31.8616 5.97117C31.7456 6.15777 31.6882 6.38517 31.6882 6.65397C31.6882 6.91497 31.7462 7.13877 31.8616 7.32537C32.0016 7.55097 32.1966 7.66377 32.4483 7.66377C32.6951 7.66377 32.8888 7.54917 33.0283 7.31937C33.148 7.12917 33.2078 6.90357 33.2078 6.64257Z' fill='white' />
        <path d='M38.7256 5.23138L37.8155 8.05978H37.2232L36.8462 6.83158C36.7506 6.52498 36.6729 6.22018 36.6124 5.91778H36.6007C36.5445 6.22858 36.4668 6.53278 36.3668 6.83158L35.9664 8.05978H35.3673L34.5115 5.23138H35.176L35.5049 6.57598C35.5845 6.89398 35.6499 7.19698 35.7023 7.48378H35.7141C35.7622 7.24738 35.8418 6.94618 35.9541 6.58198L36.3668 5.23198H36.8937L37.2892 6.55318C37.3849 6.87538 37.4626 7.18558 37.5225 7.48438H37.5404C37.5842 7.19338 37.6502 6.88318 37.7378 6.55318L38.0907 5.23198H38.7256V5.23138Z' fill='white' />
        <path d='M42.0777 8.0598H41.4311V6.4398C41.4311 5.9406 41.2362 5.691 40.845 5.691C40.6531 5.691 40.4982 5.7594 40.3779 5.8968C40.2588 6.0342 40.1984 6.1962 40.1984 6.3816V8.0592H39.5518V6.0396C39.5518 5.7912 39.5437 5.5218 39.5283 5.2302H40.0966L40.1268 5.6724H40.1447C40.22 5.535 40.3323 5.4216 40.4797 5.331C40.6549 5.2254 40.8511 5.172 41.0659 5.172C41.3373 5.172 41.5632 5.2572 41.7427 5.4282C41.9661 5.6376 42.0777 5.9502 42.0777 6.3654V8.0598Z' fill='white' />
        <path d='M43.8608 8.05979H43.2148V3.93359H43.8608V8.05979Z' fill='white' />
        <path d='M47.6677 6.62217C47.6677 7.05717 47.54 7.41357 47.2845 7.69317C47.0168 7.98057 46.6614 8.12397 46.219 8.12397C45.7914 8.12397 45.4514 7.98657 45.1985 7.71057C44.9455 7.43517 44.819 7.08777 44.819 6.66897C44.819 6.23097 44.9492 5.87157 45.2108 5.59257C45.4724 5.31357 45.8241 5.17377 46.2665 5.17377C46.6941 5.17377 47.0365 5.31117 47.2969 5.58657C47.5437 5.85417 47.6677 6.19977 47.6677 6.62217ZM46.9964 6.64257C46.9964 6.38157 46.9384 6.15777 46.823 5.97117C46.6879 5.74557 46.4942 5.63277 46.2437 5.63277C45.9833 5.63277 45.7859 5.74557 45.6507 5.97117C45.5347 6.15777 45.4774 6.38517 45.4774 6.65397C45.4774 6.91497 45.5354 7.13877 45.6507 7.32537C45.7908 7.55097 45.9858 7.66377 46.2375 7.66377C46.4843 7.66377 46.6774 7.54917 46.8169 7.31937C46.9372 7.12917 46.9964 6.90357 46.9964 6.64257Z' fill='white' />
        <path d='M50.7971 8.05977H50.2165L50.1684 7.73397H50.1505C49.9518 7.99377 49.6686 8.12397 49.3009 8.12397C49.0263 8.12397 48.8042 8.03817 48.637 7.86777C48.4852 7.71297 48.4093 7.52037 48.4093 7.29177C48.4093 6.94617 48.5574 6.68277 48.8554 6.50037C49.1528 6.31797 49.5711 6.22857 50.1098 6.23277V6.17997C50.1098 5.80737 49.9086 5.62137 49.5057 5.62137C49.2188 5.62137 48.9659 5.69157 48.7474 5.83077L48.616 5.41797C48.8863 5.25537 49.2201 5.17377 49.6137 5.17377C50.3738 5.17377 50.7551 5.56377 50.7551 6.34377V7.38537C50.7551 7.66797 50.7693 7.89297 50.7971 8.05977ZM50.1258 7.08777V6.65157C49.4126 6.63957 49.0559 6.82977 49.0559 7.22157C49.0559 7.36917 49.0967 7.47957 49.18 7.55337C49.2632 7.62717 49.3694 7.66377 49.4959 7.66377C49.6378 7.66377 49.7704 7.61997 49.8913 7.53297C50.0129 7.44537 50.0875 7.33437 50.1153 7.19817C50.1221 7.16757 50.1258 7.13037 50.1258 7.08777Z' fill='white' />
        <path d='M54.4713 8.05979H53.8975L53.8673 7.60559H53.8494C53.6661 7.95119 53.3539 8.12399 52.9152 8.12399C52.5648 8.12399 52.2729 7.99019 52.0416 7.72259C51.8102 7.45499 51.6948 7.10759 51.6948 6.68099C51.6948 6.22319 51.8201 5.85239 52.0718 5.56919C52.3155 5.30519 52.6141 5.17319 52.9695 5.17319C53.3601 5.17319 53.6334 5.30099 53.7889 5.55719H53.8012V3.93359H54.4485V7.29779C54.4485 7.57319 54.4559 7.82699 54.4713 8.05979ZM53.8012 6.86699V6.39539C53.8012 6.31379 53.7951 6.24779 53.7833 6.19739C53.7469 6.04619 53.6686 5.91899 53.5495 5.81639C53.4292 5.71379 53.2842 5.66219 53.117 5.66219C52.8757 5.66219 52.6869 5.75519 52.5481 5.94179C52.4105 6.12839 52.3408 6.36659 52.3408 6.65759C52.3408 6.93719 52.4068 7.16399 52.5395 7.33859C52.6795 7.52459 52.8683 7.61759 53.1046 7.61759C53.3169 7.61759 53.4866 7.54019 53.6155 7.38479C53.7402 7.24139 53.8012 7.06859 53.8012 6.86699Z' fill='white' />
        <path d='M60.0014 6.62217C60.0014 7.05717 59.8737 7.41357 59.6182 7.69317C59.3505 7.98057 58.9963 8.12397 58.5527 8.12397C58.1264 8.12397 57.7864 7.98657 57.5322 7.71057C57.2792 7.43517 57.1527 7.08777 57.1527 6.66897C57.1527 6.23097 57.2829 5.87157 57.5445 5.59257C57.8061 5.31357 58.1578 5.17377 58.6014 5.17377C59.0278 5.17377 59.3715 5.31117 59.6306 5.58657C59.8774 5.85417 60.0014 6.19977 60.0014 6.62217ZM59.3313 6.64257C59.3313 6.38157 59.2733 6.15777 59.158 5.97117C59.0216 5.74557 58.8291 5.63277 58.5774 5.63277C58.3182 5.63277 58.1208 5.74557 57.9844 5.97117C57.8685 6.15777 57.8111 6.38517 57.8111 6.65397C57.8111 6.91497 57.8691 7.13877 57.9844 7.32537C58.1245 7.55097 58.3195 7.66377 58.5712 7.66377C58.818 7.66377 59.0124 7.54917 59.1518 7.31937C59.2709 7.12917 59.3313 6.90357 59.3313 6.64257Z' fill='white' />
        <path d='M63.4781 8.0598H62.8322V6.4398C62.8322 5.9406 62.6372 5.691 62.2454 5.691C62.0535 5.691 61.8986 5.7594 61.7789 5.8968C61.6593 6.0342 61.5994 6.1962 61.5994 6.3816V8.0592H60.9522V6.0396C60.9522 5.7912 60.9448 5.5218 60.9294 5.2302H61.497L61.5272 5.6724H61.5451C61.621 5.535 61.7333 5.4216 61.8801 5.331C62.056 5.2254 62.2516 5.172 62.4669 5.172C62.7378 5.172 62.9636 5.2572 63.1431 5.4282C63.3671 5.6376 63.4781 5.9502 63.4781 6.3654V8.0598Z' fill='white' />
        <path d='M67.8298 5.70238H67.1178V7.07638C67.1178 7.42558 67.2443 7.60018 67.4948 7.60018C67.6108 7.60018 67.707 7.59058 67.7829 7.57078L67.7996 8.04778C67.6718 8.09458 67.504 8.11798 67.2973 8.11798C67.0419 8.11798 66.8432 8.04238 66.6995 7.89118C66.5551 7.73998 66.4835 7.48558 66.4835 7.12858V5.70238H66.0584V5.23138H66.4835V4.71298L67.1172 4.52698V5.23078H67.8292V5.70238H67.8298Z' fill='white' />
        <path d='M71.2529 8.05979H70.6057V6.45179C70.6057 5.94479 70.4107 5.69099 70.0201 5.69099C69.7203 5.69099 69.5154 5.83799 69.4031 6.13199C69.384 6.19379 69.3729 6.26939 69.3729 6.35819V8.05919H68.7269V3.93359H69.3729V5.63819H69.3852C69.5888 5.32799 69.8807 5.17319 70.2589 5.17319C70.5267 5.17319 70.7482 5.25839 70.924 5.42939C71.1431 5.64239 71.2529 5.95919 71.2529 6.37799V8.05979Z' fill='white' />
        <path d='M74.7839 6.5118C74.7839 6.6246 74.7753 6.7194 74.7599 6.7968H72.8207C72.8293 7.0764 72.9219 7.2894 73.1014 7.437C73.2655 7.569 73.4771 7.635 73.7363 7.635C74.0232 7.635 74.2848 7.5906 74.5205 7.5012L74.6217 7.938C74.3459 8.0544 74.0213 8.1126 73.6456 8.1126C73.1952 8.1126 72.8404 7.9836 72.5837 7.7256C72.3258 7.4676 72.1981 7.1214 72.1981 6.687C72.1981 6.2604 72.3172 5.9052 72.5572 5.622C72.8077 5.3196 73.1464 5.1684 73.574 5.1684C73.9923 5.1684 74.3101 5.3196 74.5248 5.622C74.6982 5.862 74.7839 6.159 74.7839 6.5118ZM74.1669 6.3492C74.1719 6.1626 74.1293 6.0018 74.0417 5.8662C73.9294 5.6916 73.7585 5.604 73.5271 5.604C73.3161 5.604 73.144 5.6892 73.0125 5.8602C72.9052 5.9964 72.8416 6.159 72.8207 6.3492H74.1669Z' fill='white' />
        <path d='M33.0986 18.9024H31.6974L30.9299 16.557H28.262L27.5309 18.9024H26.1667L28.8099 10.9176H30.4425L33.0986 18.9024ZM30.6985 15.573L30.0044 13.488C29.931 13.275 29.7934 12.7734 29.5904 11.9838H29.5657C29.4849 12.3234 29.3547 12.825 29.1758 13.488L28.494 15.573H30.6985Z' fill='white' />
        <path d='M39.896 15.9528C39.896 16.932 39.6239 17.706 39.0798 18.2742C38.5923 18.78 37.9871 19.0326 37.2646 19.0326C36.4847 19.0326 35.9244 18.7602 35.5832 18.2154H35.5586V21.2484H34.2431V15.0402C34.2431 14.4246 34.2265 13.7928 34.1944 13.1448H35.3513L35.4247 14.0574H35.4494C35.888 13.3698 36.5538 13.0266 37.4472 13.0266C38.1456 13.0266 38.7287 13.2948 39.1951 13.8318C39.6628 14.3694 39.896 15.0762 39.896 15.9528ZM38.5559 15.9996C38.5559 15.4392 38.4264 14.9772 38.166 14.6136C37.8816 14.2344 37.4996 14.0448 37.0208 14.0448C36.6963 14.0448 36.4014 14.1504 36.1379 14.3586C35.8739 14.5686 35.7011 14.8428 35.6203 15.1824C35.5795 15.3408 35.5592 15.4704 35.5592 15.5724V16.5324C35.5592 16.9512 35.6912 17.3046 35.9553 17.5932C36.2194 17.8818 36.5624 18.0258 36.9844 18.0258C37.4799 18.0258 37.8655 17.8398 38.1413 17.469C38.4177 17.0976 38.5559 16.608 38.5559 15.9996Z' fill='white' />
        <path d='M46.7058 15.9528C46.7058 16.932 46.4337 17.706 45.8889 18.2742C45.4021 18.78 44.7968 19.0326 44.0743 19.0326C43.2944 19.0326 42.7342 18.7602 42.3936 18.2154H42.3689V21.2484H41.0535V15.0402C41.0535 14.4246 41.0368 13.7928 41.0048 13.1448H42.1616L42.235 14.0574H42.2597C42.6978 13.3698 43.3635 13.0266 44.2576 13.0266C44.9554 13.0266 45.5384 13.2948 46.0061 13.8318C46.4719 14.3694 46.7058 15.0762 46.7058 15.9528ZM45.3657 15.9996C45.3657 15.4392 45.2355 14.9772 44.9751 14.6136C44.6907 14.2344 44.31 14.0448 43.8306 14.0448C43.5054 14.0448 43.2111 14.1504 42.9471 14.3586C42.683 14.5686 42.5108 14.8428 42.43 15.1824C42.3899 15.3408 42.3689 15.4704 42.3689 15.5724V16.5324C42.3689 16.9512 42.501 17.3046 42.7638 17.5932C43.0279 17.8812 43.3709 18.0258 43.7942 18.0258C44.2896 18.0258 44.6753 17.8398 44.9511 17.469C45.2275 17.0976 45.3657 16.608 45.3657 15.9996Z' fill='white' />
        <path d='M54.3195 16.6632C54.3195 17.3424 54.077 17.895 53.5902 18.3216C53.0553 18.7878 52.3106 19.0206 51.3536 19.0206C50.4701 19.0206 49.7618 18.855 49.2256 18.5232L49.5304 17.457C50.1079 17.7966 50.7415 17.967 51.432 17.967C51.9274 17.967 52.313 17.8578 52.5901 17.6406C52.8658 17.4234 53.0034 17.1318 53.0034 16.7682C53.0034 16.4442 52.8899 16.1712 52.6622 15.9498C52.4358 15.7284 52.0576 15.5226 51.5294 15.3324C50.0918 14.811 49.3737 14.0472 49.3737 13.0428C49.3737 12.3864 49.6254 11.8482 50.1295 11.4294C50.6317 11.01 51.3018 10.8006 52.1396 10.8006C52.8868 10.8006 53.5075 10.9272 54.003 11.1798L53.6741 12.2226C53.2114 11.9778 52.6882 11.8554 52.1026 11.8554C51.6399 11.8554 51.2783 11.9664 51.0192 12.1872C50.8002 12.3846 50.6903 12.6252 50.6903 12.9102C50.6903 13.2258 50.8156 13.4868 51.0673 13.692C51.2863 13.8816 51.6843 14.0868 52.2618 14.3082C52.9683 14.5848 53.4872 14.9082 53.821 15.279C54.1535 15.6486 54.3195 16.1112 54.3195 16.6632Z' fill='white' />
        <path d='M58.6687 14.1048H57.2187V16.9002C57.2187 17.6112 57.4742 17.9664 57.9863 17.9664C58.2213 17.9664 58.4163 17.9466 58.5706 17.907L58.607 18.8784C58.3478 18.9726 58.0066 19.02 57.584 19.02C57.0645 19.02 56.6585 18.8658 56.3654 18.558C56.0736 18.2496 55.9267 17.7324 55.9267 17.0058V14.1036H55.063V13.1436H55.9267V12.0894L57.2187 11.7102V13.1436H58.6687V14.1048Z' fill='white' />
        <path d='M65.2106 15.9762C65.2106 16.8612 64.9503 17.5878 64.4308 18.156C63.886 18.741 63.1628 19.0326 62.2614 19.0326C61.3927 19.0326 60.701 18.7524 60.1852 18.192C59.6694 17.6316 59.4115 16.9242 59.4115 16.0716C59.4115 15.1794 59.6768 14.4486 60.2093 13.8804C60.7405 13.3116 61.4575 13.0272 62.3589 13.0272C63.2276 13.0272 63.9267 13.3074 64.4542 13.8684C64.9589 14.4126 65.2106 15.1152 65.2106 15.9762ZM63.8459 16.0176C63.8459 15.4866 63.7292 15.0312 63.4929 14.6514C63.2171 14.1918 62.8229 13.9626 62.312 13.9626C61.7832 13.9626 61.3816 14.1924 61.1058 14.6514C60.8695 15.0318 60.7529 15.4944 60.7529 16.0416C60.7529 16.5726 60.8695 17.028 61.1058 17.4072C61.3902 17.8668 61.7876 18.096 62.3003 18.096C62.8025 18.096 63.1968 17.862 63.4812 17.3952C63.7237 17.0082 63.8459 16.548 63.8459 16.0176Z' fill='white' />
        <path d='M69.4864 14.2698C69.3562 14.2464 69.2174 14.2344 69.0718 14.2344C68.609 14.2344 68.2512 14.4042 67.9995 14.7444C67.7804 15.0444 67.6706 15.4236 67.6706 15.8814V18.9024H66.3558L66.3681 14.958C66.3681 14.2944 66.3515 13.6902 66.3188 13.1454H67.4645L67.5126 14.247H67.549C67.6879 13.8684 67.9069 13.5636 68.2068 13.335C68.4998 13.1292 68.8164 13.0266 69.1576 13.0266C69.2791 13.0266 69.3889 13.035 69.4864 13.05V14.2698Z' fill='white' />
        <path d='M75.3694 15.7512C75.3694 15.9804 75.354 16.1736 75.3213 16.3314H71.375C71.3905 16.9002 71.5811 17.3352 71.9476 17.6352C72.2802 17.9034 72.7102 18.0378 73.2384 18.0378C73.8226 18.0378 74.3557 17.9472 74.8351 17.7654L75.0412 18.6534C74.481 18.891 73.8196 19.0092 73.0563 19.0092C72.1383 19.0092 71.4176 18.7464 70.8932 18.2214C70.37 17.6964 70.1077 16.9914 70.1077 16.107C70.1077 15.2388 70.3514 14.5158 70.8395 13.9392C71.3504 13.3236 72.0408 13.0158 72.9095 13.0158C73.7628 13.0158 74.4088 13.3236 74.8475 13.9392C75.1948 14.4282 75.3694 15.033 75.3694 15.7512ZM74.1151 15.4194C74.1237 15.0402 74.038 14.7126 73.8597 14.436C73.632 14.0802 73.2822 13.9026 72.8114 13.9026C72.3813 13.9026 72.0315 14.076 71.7644 14.424C71.5453 14.7006 71.4151 15.0324 71.375 15.4188H74.1151V15.4194Z' fill='white' />
    </svg>;
export default AppStoreIconWhite;