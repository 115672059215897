import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface PeriodicalChartArgs {
  period: "P24H" | "P1W" | "P1M" | "P3M" | "P1Y" | "ALL";
  type?: "us_stock" | "crypto";
}
export const fetchPNLPeriodicalChartAction = createAsyncThunk<PortfolioPNLPeriodicalChartAPI[], PeriodicalChartArgs>("portfolio/fetchPNLPeriodicalChartAction", async ({
  period,
  type
}) => {
  const response = await baseFetchApiV3<BaseResponseAPIV3<PortfolioPNLPeriodicalChartAPI[]>>({
    url: "/wallet/portfolio/pnl/periodical",
    params: {
      period,
      type
    }
  });
  return response.data !== null ? response.data : [];
});