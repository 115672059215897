import { Theme } from "@emotion/react";
import { blue, green, red, secondary, yellow } from "./color";
import themeCommon from "./common";
const darkTheme: Theme = {
  ...themeCommon,
  mode: "dark",
  colors: {
    text: {
      base: "var(--colors-text-base)",
      muted: "var(--colors-text-muted)"
    },
    backgroundColor: "var(--colors-backgroundColor)",
    notificationBarColor: "var(--colors-notificationBarColor)",
    borderColor: "var(--colors-borderColor)",
    semantic: {
      primary: blue,
      secondary,
      success: green,
      warning: yellow,
      danger: red
    }
  },
  components: {
    cta: {
      backgroundColor: "var(--components-cta-backgroundColor)"
    },
    mobileStoreButton: {
      backgroundColor: "var(--components-mobile-store-button-backgroundColor)",
      backgroundColorHover: "var(--components-mobile-store-button-backgroundColorHover)",
      textColor: "var(--components-mobile-store-button-textColor)"
    },
    navbar: {
      logoColor: "var(--components-navbar-logoColor)",
      textColor: "var(--components-navbar-textColor)",
      textColorHover: "var(--components-navbar-textColorHover)"
    },
    skeleton: {
      baseColor: "var(--components-skeleton-baseColor)",
      highlightColor: "var(--components-skeleton-highlightColor)"
    },
    footer: {
      backgroundColor: "var(--components-footer-backgroundColor)"
    }
  },
  pages: {
    otc: {
      backgroundColor: "var(--pages-otc-backgroundColor)"
    }
  }
};
export default darkTheme;