import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchBankSources } from "./actions";
const bankSourcesSlicer = createSlice({
  name: "bankSources",
  initialState: {
    bankSources: null as BankSourcesModel | null,
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchBankSources.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchBankSources.fulfilled.type]: (state, action: PayloadAction<BankSourceAPI>) => {
      state.bankSources = action.payload.sources;
      state.isLoading = false;
    }
  }
});
export default bankSourcesSlicer;