import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface AssetChartArgs {
  period: "P24H" | "P1W" | "P1M" | "P1Y" | "ALL";
}
export const fetchAssetChartAction = createAsyncThunk<PortfolioAssetChartAPI, AssetChartArgs>("portfolio/fetchAssetChart", async ({
  period
}) => {
  const response = await baseFetchApiV3<BaseResponseAPIV3<PortfolioAssetChartAPI>>({
    url: "/wallet/portfolio/asset/chart",
    params: {
      period
    }
  });
  return response.data || [];
});