import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface PortfolioPNLSummaryArgs {
  type: "us_stock" | "crypto" | "all";
}
export const fetchPNLSummaryAction = createAsyncThunk<PortfolioSummaryBreakdownAPI, PortfolioPNLSummaryArgs>("portfolio/fetchPNLSummary", async ({
  type
}) => {
  const response = await baseFetchApiV3({
    url: "/wallet/portfolio/pnl/summary",
    params: {
      type: type === "all" ? undefined : type
    }
  });
  return response.data;
});