import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useComponentHydrated } from "react-hydration-provider";
import { useMediaQuery } from "react-responsive";
import Link from "@components/Link";
import { Else, If, Then, When } from "@components/If";
import Collapse from "@components/Collapse";
import ConditionalWrapper from "@components/ConditionalWrapper";
import UserStatusIcon from "@components/UserStatusIcon";
import PersonalityAvatar from "@components/v4/PersonalityAvatar";
import CTAPersonality from "@components/CTAPersonality";
import MobileStoreButton from "@components/v2/MobileStoreButton";
import Avatar from "@components/v4/Avatar";
import useAuth, { useLogout } from "@hooks/useAuth";
import useProfile from "@hooks/useProfile";
import { Icons, IconsProps, Button } from "@reku-web/ui";
import MenuCard from "./MenuCard";
import { TRANSPARANSI_DOMAIN_HOST } from "@config/config";
interface CollapseMenuProps {
  isOpen: boolean;
}
const CollapseMenu: React.FC<React.PropsWithChildren<CollapseMenuProps>> = ({
  isOpen,
  children
}) => <div className={classNames("fixed top-[48px] xl:top-[72px] left-0 z-10", "w-full h-[calc(100dvh-48px)] xl:h-[calc(100dvh-72px)] p-4", "bg-background-card-main dark:bg-dark-background-card-main transition-all duration-300 ease-in-out", "overflow-y-auto reku-scrollbar", "flex flex-col", {
  "!h-[0px] py-0": !isOpen
})}>
        {children}
    </div>;
interface NavItemProps {
  isActive?: boolean;
  onClick?: () => void;
  className?: string;
}
const NavItem: React.FC<React.PropsWithChildren<NavItemProps>> = ({
  isActive,
  className,
  children,
  onClick
}) => <div role='button' className={classNames("w-full h-[28px]", "flex items-center justify-between", "text-md font-bold text-actions-grey-active dark:text-dark-actions-grey-active", "cursor-pointer", {
  "!text-actions-primary-active dark:!text-dark-actions-primary-active": isActive
}, className)} onClick={onClick}>
        {children}
    </div>;
type Links = Array<{
  href: string;
  label: string;
  dataTestId?: string;
}>;
interface NavChildrenProps {
  href: string;
  icon: IconsProps;
  label: string;
  description: string;
  dataTestId?: string;
  links?: Links;
}
interface ChildsProps {
  title?: string;
  list: Array<NavChildrenProps>;
  variant: "teal" | "purple";
  extraLink?: Links;
}
interface NavProps {
  href?: string;
  label: string;
  childs?: Array<ChildsProps>;
  dataTestId?: string;
  getActive?: (currentPathname: string) => boolean;
}
interface NavChildrenItemsProps {
  items: Array<ChildsProps>;
  isExpanded: boolean;
  onClick?: () => void;
}
const NavChildrenItems: React.FC<NavChildrenItemsProps> = ({
  items,
  isExpanded,
  onClick
}) => {
  const {
    t
  } = useTranslation("components");
  return <Collapse isExpanded={isExpanded}>
            <div className='space-y-2'>
                {items.map(({
        list,
        extraLink,
        title,
        variant
      }) => <>
                        <MenuCard className='!min-h-[unset]' title={title} list={list} variant={variant} onClick={onClick} />
                        <When condition={extraLink}>
                            {extraLink && <div className='flex flex-col gap-4'>
                                    {extraLink.map(({
              label,
              href
            }) => <Link href={href} className='!w-fit text-md font-bold text-text-neutral-strong dark:text-dark-text-neutral-strong hover:text-actions-primary-hover dark:hover:text-dark-actions-primary-hover'>
                                            {t(`navbar.navs.${label}`)}
                                        </Link>)}
                                </div>}
                        </When>
                    </>)}
            </div>
        </Collapse>;
};
NavChildrenItems.defaultProps = {
  onClick: () => {}
};
interface NavItemsProps extends Omit<NavProps, "getActive"> {
  isActive?: boolean;
  onClick?: () => void;
}
const NavItems: React.FC<NavItemsProps> = ({
  href,
  label,
  childs,
  isActive,
  dataTestId,
  onClick
}) => {
  const {
    t
  } = useTranslation("components");
  const [isExpanded, setExpanded] = useState(false);
  const handleExpand = () => {
    if (!childs) {
      return;
    }
    setExpanded(!isExpanded);
  };
  return <>
            <ConditionalWrapper condition={!!href} wrapper={children => <Link href={String(href)} onClick={onClick} dataTestId={dataTestId}>
                        {children}
                    </Link>}>
                <NavItem onClick={handleExpand} isActive={isActive || isExpanded} data-testid={!href ? dataTestId : undefined}>
                    {t(`navbar.navs.${label}`)}
                    <When condition={childs}>
                        <Icons icon='ChevronDown' className={classNames("w-5 h-5 text-actions-grey-active dark:text-dark-actions-grey-active transition-transform duration-300", {
            "transform rotate-180": isExpanded,
            "!text-actions-primary-active dark:!text-dark-actions-primary-active": isExpanded
          })} />
                    </When>
                </NavItem>
            </ConditionalWrapper>
            <When condition={childs}>
                {childs && <NavChildrenItems items={childs} isExpanded={isExpanded} onClick={onClick} />}
            </When>
        </>;
};
NavItems.defaultProps = {
  isActive: false,
  onClick: () => {}
};
interface ProfileProps {
  onCTAClick: () => void;
}
const Profile: React.FC<ProfileProps> = ({
  onCTAClick
}) => {
  const {
    profile
  } = useProfile();
  return <div className='flex gap-4'>
            <If condition={profile.riskLevelType}>
                <Then>
                    <PersonalityAvatar width={72} height={72} riskLevelType={Number(profile.riskLevelType)} />
                </Then>
                <Else>
                    <Avatar name={profile.fullName} size='md' />
                </Else>
            </If>
            <div className='flex flex-[1] flex-col gap-1'>
                <div className='flex items-center gap-2'>
                    <span className='title-3 text-text-neutral-strong dark:text-dark-text-neutral-strong line-clamp-1'>
                        {profile.fullName}
                    </span>
                    <When condition={profile.fullName}>
                        <UserStatusIcon status={profile.status} />
                    </When>
                </div>
                <div className='flex items-center gap-2'>
                    <span className='text-body-sm text-text-neutral-main dark:text-dark-text-neutral-main line-clamp-1'>
                        {profile.email}
                    </span>
                    <When condition={!profile.fullName}>
                        <UserStatusIcon status={profile.status} />
                    </When>
                </div>
                <CTAPersonality onClick={onCTAClick} riskLevelName={profile.riskLevelName} />
            </div>

            <Link href='/settings'>
                <Icons icon='Settings1' width={24} height={24} className='text-actions-grey-active dark:text-dark-actions-grey-active' />
            </Link>
        </div>;
};
const navs: Array<NavProps> = [{
  label: "investment.title",
  dataTestId: "menuBar_investmentMenuBtn",
  childs: [{
    variant: "teal",
    list: [{
      href: "/crypto",
      icon: "CryptoTeal",
      label: "investment.crypto.title",
      description: "investment.crypto.description",
      dataTestId: "menuBar_assetCryptoMenuBtn",
      links: [{
        href: "/markets",
        label: "market",
        dataTestId: "menuBar_cryptoMarketMenuBtn"
      }, {
        href: "/trade/BTC-IDR",
        label: "trade",
        dataTestId: "menuBar_cryptoTradeMenuBtn"
      }]
    }]
  }, {
    variant: "purple",
    list: [{
      href: "/saham-as",
      icon: "StockPurple",
      label: "investment.stock.title",
      description: "investment.stock.description",
      dataTestId: "menuBar_usStockMenuBtn",
      links: [{
        href: "/markets/saham-as",
        label: "market",
        dataTestId: "menuBar_usStockMarketMenuBtn"
      }]
    }]
  }],
  getActive: (currentPathname: string) => currentPathname.includes("/investment")
}, {
  href: "/trade/BTC-IDR",
  label: "trade_crypto",
  dataTestId: "menuBar_tradeMenuBtn",
  getActive: (currentPathname: string) => currentPathname.includes("/trade")
}, {
  label: "explore.title",
  dataTestId: "menuBar_exploreMenuBtn",
  childs: [{
    title: "explore.for_crypto",
    variant: "teal",
    list: [{
      href: "staking",
      icon: "CoinStaked3",
      label: "explore.staking.title",
      description: "explore.staking.description",
      dataTestId: "menuBar_stakingMenuBtn"
    }, {
      href: "otc",
      icon: "LineChartUp",
      label: "explore.otc.title",
      description: "explore.otc.description",
      dataTestId: "menuBar_otcMenuBtn"
    }]
  }, {
    title: "explore.for_stock",
    variant: "purple",
    list: [{
      href: "packs",
      icon: "CoinStaked3",
      label: "explore.sam.title",
      description: "explore.sam.description",
      dataTestId: "menuBar_samMenuBtn"
    }, {
      href: "insight",
      icon: "LineChartUp",
      label: "explore.insight.title",
      description: "explore.insight.description",
      dataTestId: "menuBar_insightMenuBtn"
    }]
  }],
  getActive: (currentPathname: string) => {
    const regex = /\/(staking|otc|sam|insight)/;
    return regex.test(currentPathname);
  }
}, {
  href: "/wallet",
  label: "wallet",
  dataTestId: "menuBar_walletMenuBtn",
  getActive: (currentPathname: string) => currentPathname.includes("/wallet")
}, {
  label: "learning_hub.title",
  dataTestId: "menuBar_learningHubMenuBtn",
  childs: [{
    title: "learning_hub.read_learn",
    variant: "teal",
    list: [{
      href: "/information",
      icon: "AnnotationInfo",
      label: "learning_hub.info.title",
      description: "learning_hub.info.description",
      dataTestId: "menuBar_rekuInfoMenuBtn"
    }, {
      href: "/analysis",
      icon: "BarLineChart",
      label: "learning_hub.analysis.title",
      description: "learning_hub.analysis.description",
      dataTestId: "menuBar_analysisMenuBtn"
    }, {
      href: "/campus",
      icon: "GraduationHat",
      label: "learning_hub.campus.title",
      description: "learning_hub.campus.description",
      dataTestId: "menuBar_rekuCampusMenuBtn"
    }],
    extraLink: [{
      label: "help_center",
      href: "/help"
    }, {
      label: "faq",
      href: "/help"
    }, {
      label: "trading_rules",
      href: "/trading-rule"
    }, {
      label: "fees",
      href: "/fees"
    }, {
      label: "about_us",
      href: "/about"
    }]
  }],
  getActive: (currentPathname: string) => {
    const regex = /\/(information|rekap|analysis|campus)/;
    return regex.test(currentPathname);
  }
}, {
  label: "regulation_security.title",
  dataTestId: "menuBar_RegulationSecurityMenuBtn",
  childs: [{
    variant: "teal",
    list: [{
      href: `https://${TRANSPARANSI_DOMAIN_HOST}/`,
      icon: "CoinsHand",
      label: "regulation_security.crypto.title",
      description: "regulation_security.crypto.description",
      dataTestId: "menuBar_TransparencyMenuBtn"
    }]
  }, {
    variant: "purple",
    list: [{
      href: `https://${TRANSPARANSI_DOMAIN_HOST}/saham-as`,
      icon: "Security",
      label: "regulation_security.us-stock.title",
      description: "regulation_security.us-stock.description",
      dataTestId: "menuBar_SecurityStockMenuBtn"
    }]
  }]
}];
interface HamburgerMenuProps extends React.PropsWithChildren {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}
const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
  expanded: isOpen,
  setExpanded: setOpen
}) => {
  const {
    t
  } = useTranslation("components");
  const router = useRouter();
  const hydrated = useComponentHydrated();
  const {
    auth
  } = useAuth();
  const {
    logout
  } = useLogout();
  const isMobile = useMediaQuery({
    maxWidth: 1279
  });
  useEffect(() => {
    if (isOpen && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen, isMobile]);
  const handleToggle = () => setOpen(!isOpen);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    handleClose();
  }, [router.asPath]);
  const renderedNavs = navs.map(({
    href,
    label,
    childs,
    dataTestId,
    getActive
  }) => <NavItems key={label} href={href} label={label} childs={childs} isActive={getActive?.(router.asPath)} dataTestId={dataTestId} onClick={handleClose} />);
  return <div className='h-full flex xl:hidden items-center justify-center order-2 xl:order-1'>
            <button type='button' className='reku-nav-item h-6 w-6 text-icon-neutral-main dark:text-dark-icon-neutral-main cursor-pointer' onClick={handleToggle}>
                <Icons icon={!isOpen ? "HamburgerButton" : "XClose"} width={24} height={24} dataTestId={!isOpen ? "menuBar_hamburgerMenuBtn" : "menuBar_closeMenuBtn"} />
            </button>
            <CollapseMenu isOpen={isOpen}>
                <div className='flex flex-col gap-4'>
                    <When condition={hydrated && auth.isLoggedIn}>
                        <Profile onCTAClick={handleClose} />
                    </When>
                    <div className='flex flex-col gap-2'>{renderedNavs}</div>
                </div>
                <div className='flex flex-col w-full'>
                    <When condition={hydrated}>
                        <div className='w-full py-4 flex gap-4'>
                            <If condition={auth.isLoggedIn}>
                                <Then>
                                    <Button variant='primaryOutline' size='lg' block data-testid='welcome_logoutBtn' className='h-12 grid place-content-center' onClick={() => logout()}>
                                        {t("navbar.navs.logout")}
                                    </Button>
                                </Then>
                                <Else>
                                    <Link href='/login' className='!w-full' dataTestId='welcome_signinBtn'>
                                        <Button variant='primaryOutline' size='lg' block className='h-12 grid place-content-center'>
                                            {t("navbar.navs.sign_in")}
                                        </Button>
                                    </Link>
                                    <Link href='/register' className='!w-full' dataTestId='welcome_registerBtn'>
                                        <Button variant='primary' size='lg' block className='h-12 grid place-content-center'>
                                            {t("navbar.navs.sign_up")}
                                        </Button>
                                    </Link>
                                </Else>
                            </If>
                        </div>
                    </When>
                    <div className='w-full h-[1px] bg-outline-neutral-subtle dark:bg-dark-outline-neutral-subtle' />
                    <div className=' w-full py-4 flex flex-col items-center gap-4'>
                        <span className='text-md font-semibold text-text-neutral-subtle dark:text-dark-text-neutral-subtle'>
                            {t("navbar.navs.download_apps")}
                        </span>
                        <div className='flex gap-4'>
                            <MobileStoreButton store='android' className='w-auto flex-1 py-[6px] px-2' height={40} />
                            <MobileStoreButton store='ios' className='w-auto flex-1' height={40} />
                        </div>
                    </div>
                </div>
            </CollapseMenu>
        </div>;
};
export default HamburgerMenu;